<section id="page-title-section">
  <div class="flex-row-start flex-row-align-center">
    <!-- Material icon or material symbol, or image -->
    <mat-icon *ngIf="icon" fontSet="material-icons-outlined" class="page-icon">{{ icon }}</mat-icon>
    <span *ngIf="symbol" class="page-icon material-symbols-outlined">{{ symbol }}</span>
    <img *ngIf="iconImage" class="page-icon-image" [src]="'assets/icons/' + iconImage" />

    <!-- Title and optional subtitle -->
    <div
      class="page-title"
      [class.nav-overview-group]="iconImage == 'icon_overview'"
      [class.nav-reports-group]="iconImage == 'icon_reports'"
      [class.nav-sources-group]="iconImage == 'icon_sources'"
      [class.nav-exports-group]="iconImage == 'icon_exports'">
      {{ title }}
    </div>

    <span *ngIf="subtitle" class="text-secondary">:</span>
    <span *ngIf="subtitle" class="page-subtitle text-secondary">{{ subtitle }}</span>

    <!-- Info icon with hover, not used currently 
    <mat-icon *ngIf="infoIsVisible && info" fontSet="material-icons" class="page-info text-secondary"
              [matTooltip]="info"
              [matTooltipClass]="'explanation-tooltip'"
              [matTooltipPosition]="'right'">info
    </mat-icon>
    -->
  </div>
</section>
