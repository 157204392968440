import {AdminDocumentReference} from '../document-reference';
import {PublicOrganizationData} from '../organization';
import {PublicUserData, UserRole} from '../user';

export enum NotificationType {
  Generic = 'generic',
  Invitation = 'invitation',
  InvitationAcceptance = 'invitationAcceptance',
  InvitationDismissal = 'invitationDismissal',
  Removal = 'removal',
  DataStudioTemplateError = 'lookerStudioTemplateError',
  AccountTokenExpired = 'accountTokenExpired',
  SqlConnectionInvalid = 'sqlConnectionInvalid',
}

export enum NotificationAction {
  Accept = 'accept',
  Decline = 'decline',
  View = 'view',
  ViewAndDismiss = 'viewAndDismiss',
}

export interface GenericNotification extends Notification {
  type: NotificationType.Generic;
  message: string;
  subType?: string; // Allow custom actions
  linkUrl?: string;
  linkTitle?: string;
}

export interface InvitationNotification extends Notification {
  type: NotificationType.Invitation;
  userData?: PublicUserData;
  permission: UserRole;
  organizationData?: PublicOrganizationData;
}

export interface RemovalNotification extends Notification {
  type: NotificationType.Removal;
  userData?: PublicUserData;
  organizationData?: PublicOrganizationData;
}

export interface LookerStudioTemplateErrorNotification extends Notification {
  type: NotificationType.DataStudioTemplateError;
  connector: {
    title: string;
    type: string;
  };
  page: number;
  errors: number;
}

export interface AccountTokenExpiredNotification extends Notification {
  type: NotificationType.AccountTokenExpired;
  name: string;
  provider: {
    type: string;
    title: string;
  };
  accountId?: string;
}

export interface SqlConnectionInvalidNotification extends Notification {
  type: NotificationType.SqlConnectionInvalid;
  host: string;
  database: string;
}

export interface Notification {
  type: string;
  to: AdminDocumentReference;
  hasRead?: boolean;
  status: undefined | NotificationAction;
}
