<ng-container *transloco="let t; read: 'app.nav.side'">
  <div class="nav-content">
    <!-- Side nav menu -->
    <div>
      <ng-container *ngFor="let menuGroup of menuGroups; index as i">
        <!-- Group -->
        <div
          class="nav-item nav-item-group nav-item-group-routed"
          [ngClass]="{
            'nav-overview nav-overview-group': menuGroup.groupTitleKey === 'overview',
            'nav-reports nav-reports-group': menuGroup.groupTitleKey === 'reports',
            'nav-sources nav-sources-group': menuGroup.groupTitleKey === 'sources',
            'nav-exports nav-exports-group': menuGroup.groupTitleKey === 'exports',
            'nav-tools nav-tools-group': menuGroup.groupTitleKey === 'tools'
          }"
          [routerLink]="
            menuGroup.groupRouteNav !== ''
              ? ['resources', 'navigation', menuGroup.groupRouteNav]
              : ['organizations', organizationId, menuGroup.groupRoute]
          "
          routerLinkActive="color-background-nav-active">
          <mat-icon *ngIf="menuGroup.groupIcon" fontSet="material-icons-outlined" class="nav-icon-group">{{
            menuGroup.groupIcon
          }}</mat-icon>
          <img
            *ngIf="menuGroup.groupIconImage"
            class="nav-icon-image-group"
            [src]="'assets/icons/' + menuGroup.groupIconImage" />

          <span class="nav-item-group-title">{{ t(menuGroup.groupTitleKey) }}</span>
        </div>

        <!-- Group children -->
        <ng-container *ngFor="let groupItem of menuGroup.groupItems">
          <div
            *ngIf="!groupItem.isAdmin || (groupItem.isAdmin && isAdmin)"
            class="nav-item-routed"
            [ngClass]="{
              'nav-overview': menuGroup.groupTitleKey === 'overview',
              'nav-reports': menuGroup.groupTitleKey === 'reports',
              'nav-sources': menuGroup.groupTitleKey === 'sources',
              'nav-exports': menuGroup.groupTitleKey === 'exports',
              'nav-tools': menuGroup.groupTitleKey === 'tools'
            }"
            [routerLink]="getRoute(menuGroup, groupItem)"
            routerLinkActive="color-background-nav-active">
            <div class="nav-item nav-item-child" routerLinkActive="nav-item-child-current-route">
              <!-- Material icon or symbol, or image -->
              <img
                *ngIf="groupItem.iconImage"
                [src]="'assets/icons/' + groupItem.iconImage"
                class="nav-icon-image grayscale"
                [class.nav-enterprise]="groupItem.isEnterprise && !isPlanEnterprise" />
              <span
                *ngIf="groupItem.symbol"
                class="nav-icon-image grayscale material-symbols-outlined"
                [class.nav-enterprise]="groupItem.isEnterprise && !isPlanEnterprise"
                >{{ groupItem.symbol }}</span
              >
              <mat-icon
                *ngIf="groupItem.icon"
                fontSet="material-icons-outlined"
                class="nav-icon color-gray"
                [class.nav-enterprise]="groupItem.isEnterprise && !isPlanEnterprise"
                >{{ groupItem.icon }}</mat-icon
              >
              <span [class.nav-enterprise]="groupItem.isEnterprise && !isPlanEnterprise">{{
                t(groupItem.titleKey)
              }}</span>
            </div>
          </div>
        </ng-container>

        <!-- Group divider -->
        <mat-divider
          *ngIf="
            i < menuGroups.length - 1 &&
            (!menuGroup.groupRouteIsOrganization || (menuGroup.groupRouteIsOrganization && organizationId))
          "></mat-divider>
      </ng-container>
    </div>

    <!-- Social media links, hidden for now -->
    <div id="socials-container" *ngIf="false">
      <div id="socials">
        <a *ngFor="let link of socials" [href]="link.href" class="social {{ link.icon }}">
          <img *ngIf="link.icon == 'facebook-f'" class="social-icon" [src]="'assets/icons/facebook.png'" />
          <img *ngIf="link.icon == 'twitter'" class="social-icon" [src]="'assets/icons/twitter.png'" />
          <img *ngIf="link.icon == 'linkedin-in'" class="social-icon" [src]="'assets/icons/linkedin.png'" />
        </a>
      </div>
    </div>
  </div>
</ng-container>
