<ng-container *transloco="let t; read: 'app.page.header'">
  <!-- Page-specific extras positioned relatively in the page title -->
  <div class="layout-title-container">
    <div
      class="layout-title"
      [class.layout-title-sm]="page == 'api'"
      [class.layout-title-md]="page == 'reportsGoogleSheets' || page == 'reportsExcel'"
      [class.layout-title-lg]="
        page == 'sql' || page == 'exportsFtp' || page == 'exportsBigQuery' || page == 'exportsMySQL'
      ">
      <!-- Spreadsheets integration -->
      <app-integration
        *ngIf="page == 'reportsGoogleSheets'"
        [page]="page"
        type="sheets"
        layout="title"></app-integration>
      <app-integration *ngIf="page == 'reportsExcel'" [page]="page" type="sheets" layout="title"></app-integration>

      <!-- Enterprise plan requirement -->
      <div
        *ngIf="
          !isPlanEnterprise &&
          (page == 'sql' ||
            page == 'api' ||
            page == 'exportsFtp' ||
            page == 'exportsBigQuery' ||
            page == 'exportsMySQL')
        "
        class="flex-row-start flex-row-align-center text-secondary">
        <mat-icon fontSet="material-icons-outlined" class="color-gray">info</mat-icon>
        <span>{{ 'organizations.organization.billing.enterprise.' + page | transloco }}</span>
        <a [routerLink]="['/organizations', organizationId, 'billing', 'plans', 'all']" class="hyperlink">Upgrade</a>
      </div>
    </div>
  </div>

  <!-- Page header -->
  <mat-card appearance="outlined" id="page-header">
    <mat-card-content>
      <div class="flex-row-between flex-row-start">
        <!-- Image -->
        <img class="header-image" [src]="'assets/images/' + image" />

        <!-- Text -->
        <div class="header-text">
          <div class="header-title">{{ title }}</div>
          <div class="header-description text-secondary">{{ description }}</div>

          <!-- Page-specific extras (Google Sheets)
          <div *ngIf="page == 'reportsGoogleSheets' && extras" class="header-description text-secondary">
            <ng-container *transloco="let t; read: 'app.page.header.reportsGoogleSheets'">
              <span>
                {{ t('link1') }}
                <a [href]="extras['linkAddon']" class="hyperlink" target="_blank">{{ t('link2') }}</a>
                {{ t('link3') }}
              </span>
            </ng-container>
          </div>-->

          <!-- Page-specific extras (Excel)
          <div *ngIf="page == 'reportsExcel' && extras" class="header-description text-secondary">
            <ng-container *transloco="let t; read: 'app.page.header.reportsExcel'">
              <span>
                {{ t('link1') }}
                <a [href]="extras['linkAddon']" class="hyperlink" target="_blank">{{ t('link2') }}</a>
                {{ t('link3') }}
              </span>
            </ng-container>
          </div> -->

          <!-- Page-specific extras (API)
          <div *ngIf="page == 'api' && extras" class="header-description text-secondary">
            <ng-container *transloco="let t; read: 'app.page.header.api'">
              <span>
                {{ t('link1') }}
                <a [href]="extras['linkAPI']" class="hyperlink" target="_blank">{{ t('link2') }}</a>
              </span>
            </ng-container>
          </div>-->
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Page-specfic extras positioned below the page header -->
  <ng-container *ngIf="page == 'reportsGoogleSheets' || page == 'reportsExcel'">
    <app-integration *ngIf="page == 'reportsGoogleSheets'" [page]="page" type="sheets" layout="below"></app-integration>
    <app-integration *ngIf="page == 'reportsExcel'" [page]="page" type="sheets" layout="below"></app-integration>
  </ng-container>
</ng-container>
