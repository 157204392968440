import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LogsStateModel} from '@store/logs/logs.state';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss'],
})
export class LogsDialogComponent {
  @Select(state => state.logs) logs$: Observable<LogsStateModel>;

  constructor(private dialogRef: MatDialogRef<LogsDialogComponent>) {}
}
