import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackfillMenuComponent} from './backfill-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [BackfillMenuComponent],
  exports: [BackfillMenuComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  providers: [MatDatepickerModule],
})
export class BackfillMenuModule {}
