import {NotificationAction, NotificationModel} from '@pma/shared/types/notification';

export class StreamNotifications {
  static readonly type = '[Notifications] Stream Notifications For List';
}

export class StreamBannerNotifications {
  static readonly type = '[Notifications] Stream Notifications For Banner';
}

export class GetNotificationsAll {
  static readonly type = '[Notifications] Get All';

  constructor(public userId: string, public organizationId: string) {}
}

export class GetNotificationsByType {
  static readonly type = '[Notifications] Get By Type';

  constructor(public userId: string, public organizationId: string, public type: string) {}
}

export class GetNotificationsByTypeForUser {
  static readonly type = '[Notifications] Get By Type For User';

  constructor(public userId: string, public types: string[]) {}
}

export class GetNotificationsByTypeForOrganization {
  static readonly type = '[Notifications] Get By Type For Organization';

  constructor(public organizationId: string, public types: string[]) {}
}

export class PatchStreamedNotifications {
  static readonly type = '[Notifications] Patch Synced Data';

  constructor(public notifications: NotificationModel[]) {}
}

export class PatchStreamedBannerNotifications {
  static readonly type = '[Notifications] Patch Banner Notifications';

  constructor(public notifications: NotificationModel[]) {}
}

export class MarkNotificationsAsRead {
  static readonly type = '[Notifications] Mark Notifications a read';
}

export class PatchNotificationStatus {
  static readonly type = '[Notifications] Patch Status';

  constructor(public notification: NotificationModel, public action: NotificationAction) {}
}
