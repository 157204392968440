<ng-container>
  <mat-toolbar [class.hidden]="isLoginRoute" *transloco="let t; read: 'app.nav'">
    <mat-toolbar-row>
      <div class="toolbar-container">
        <div class="toolbar-left">
          <!-- Toggle sidenav button (only on small screen) -->
          <button
            mat-icon-button
            class="sidenav-button"
            [class.sidenav-open]="isSidenavOpen"
            *ngIf="!isLoginRoute"
            (click)="toggleSidenav.emit()">
            <mat-icon class="nav-button-offset" fontSet="material-icons">menu</mat-icon>
          </button>

          <!-- Logo -->
          <img
            *ngIf="!isDarkMode"
            [routerLink]="['/']"
            alt="Logo"
            id="logo"
            src="assets/images/logo-horizontal-cb.svg" />
          <img
            *ngIf="isDarkMode"
            [routerLink]="['/']"
            alt="Logo"
            id="logo"
            src="assets/images/logo-horizontal-cw.svg" />
        </div>

        <div class="toolbar-right">
          <!--  Toggle User / Admin Mode -->
          <div *ngIf="isAdmin || isAdminMode" class="nav-button dark-mode-toggle">
            <button
              mat-icon-button
              (click)="toggleAdmin()"
              [matTooltip]="isAdmin ? t('userMode') : t('adminMode')"
              [matTooltipPosition]="'below'"
              [matTooltipClass]="'nav-tooltip'">
              <mat-icon *ngIf="isAdmin">admin_panel_settings</mat-icon>
              <mat-icon *ngIf="!isAdmin">person</mat-icon>
            </button>
          </div>

          <!-- Dark mode toggle (only on larger screens) -->
          <div
            *ngIf="screenSize == 'md' || screenSize == 'lg' || screenSize == 'xl'"
            class="nav-button dark-mode-toggle">
            <button
              mat-icon-button
              (click)="toggleTheme.emit()"
              [matTooltip]="isDarkMode ? t('modeLight') : t('modeDark')"
              [matTooltipPosition]="'below'"
              [matTooltipClass]="'nav-tooltip'">
              <mat-icon *ngIf="isDarkMode">light_mode</mat-icon>
              <mat-icon *ngIf="!isDarkMode">nightlight</mat-icon>
            </button>
          </div>

          <!-- Support (only on larger screens) -->
          <a
            mat-icon-button
            *ngIf="screenSize == 'md' || screenSize == 'lg' || screenSize == 'xl'"
            class="nav-button"
            href="https://support.powermyanalytics.com/portal/en/home"
            target="_blank"
            fontSet="material-icons"
            [matTooltip]="t('support')"
            [matTooltipPosition]="'below'"
            [matTooltipClass]="'nav-tooltip'">
            <mat-icon>help</mat-icon>
          </a>

          <!-- Toolbar items that require user auth -->
          <div class="nav-space"></div>
          <app-auth-nav-items [screenSize]="screenSize"></app-auth-nav-items>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-container>
