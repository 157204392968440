import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {BaseService} from '@core/services/base.service';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PricingService extends BaseService {
  constructor(private db: AngularFireDatabase) {
    super();
  }

  getPricingInfo() {
    return this.db.object('pricing').valueChanges().pipe(first()).toPromise();
  }
}
