import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigItem} from '@pma/shared/types/connector';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {debounceTime} from 'rxjs/operators';
@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss'],
})
export class ConfigFormComponent implements OnInit {
  @Input() fields: ConfigItem[];
  @Input() form: FormGroup;
  @Input() integration: string = null;
  @Input() isStyleFill = false;
  @Output() submitForm = new EventEmitter<void>();
  filteredOptions: any[];
  accounts: string[] = []; // Selected accounts
  searchControl = new FormControl();
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.filteredOptions = this.fields.find(field => field.type === 'SELECT_SINGLE')?.options || [];
    this.setupSearchFilter();
    // Remove existing form controls
    const controlNames = Object.keys(this.form.controls);
    controlNames.forEach(controlName => this.form.removeControl(controlName));
    // console.log('Fields:', this.fields);

    // Add appropriate form controls
    let allFieldsPopulated = true;
    this.fields.filter(this.isField.bind(this)).forEach(field => {
      const defaultValue = field.type === 'CHECKBOX' ? field.default || false : field.type === 'TEXTINPUT' ? '' : null;
      const validators =
        field.type !== 'INFO_LIST' && field.optional
          ? [this.noWhitespaceValidator]
          : [Validators.required, this.noWhitespaceValidator];
      const formField = new FormControl(defaultValue, validators);
      this.form.registerControl(field.name, formField);

      // Auto-populate field values if they are provided as query parameters
      if (field.name in this.route.snapshot.queryParams) {
        formField.setValue(this.route.snapshot.queryParams[field.name]);
      } else {
        allFieldsPopulated = false;
      }
    });

    this.initializeSqlIntegration();

    // Submit form immediately if all fields are auto-populated
    if (allFieldsPopulated) {
      this.submitForm.emit();
    }
  }

  setupSearchFilter(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(searchValue => {
      if (searchValue) {
        this.filteredOptions = this.filterOptions(searchValue);
      } else {
        this.filteredOptions = this.fields.find(field => field.type === 'SELECT_SINGLE')?.options || [];
      }
    });
  }

  private filterOptions(searchValue: any): any[] {
    const searchLowerCase = searchValue.toLowerCase();
    const options = this.fields.find(field => field.type === 'SELECT_SINGLE')?.options || [];

    // Filter the options based on the search value
    const filtered = options.filter(option => option.label.toLowerCase().includes(searchLowerCase));
    return filtered;
  }

  onSearch(event: Event): void {
    const searchValue = (event.target as HTMLInputElement).value;
    if (searchValue) {
      this.filteredOptions = this.filterOptions(searchValue);
    } else {
      this.filteredOptions = this.fields.find(field => field.type === 'SELECT_SINGLE')?.options || [];
    }
  }

  isField(field) {
    if (field.type === 'INFO' || field.type === 'INFO_LIST') {
      return false;
    }

    // Handle selection of subaccounts for SQL integration, using only the first non-info/non-currency field
    if (this.integration && this.integration === 'sql') {
      const fieldsExcludingInfo = this.fields.filter(f => !['INFO', 'INFO_LIST'].includes(f.type));
      if (field.name === fieldsExcludingInfo[0].name && field.name !== 'currency') {
        return true;
      } else {
        return false;
      }
    }

    return (
      !this.integration ||
      !field.integrations ||
      !(this.integration in field.integrations) ||
      field.integrations[this.integration]
    );
  }

  public noWhitespaceValidator(control: FormControl) {
    if (typeof control.value !== 'string') {
      return null;
    }
    const value = control.value || '';
    const isWhitespace = value.trim().length !== value.length;
    return isWhitespace ? {whitespace: true} : null;
  }

  getFirstFormControl() {
    const controlNames = Object.keys(this.form.controls);
    const firstControlName = controlNames.length > 0 ? controlNames[0] : null;
    return firstControlName ? this.form.get(firstControlName) : null;
  }

  initializeSqlIntegration() {
    // Track selected accounts for SQL integration to allow toggling selection of all accounts
    const formControlAccount = this.getFirstFormControl();
    if (formControlAccount) {
      formControlAccount.valueChanges
        .pipe(
          filter(Boolean),
          distinctUntilChanged(),
          tap((accounts: string[]) => {
            this.accounts = accounts;
          })
        )
        .subscribe();
    }
  }

  getSelectTriggerLabel(options: any[]) {
    // Get label for first selected account
    if (this.accounts.length === 0) {
      return '';
    }

    const option = options.find(o => o.value === this.accounts[0]);
    if (option) {
      return option.label;
    } else {
      return '';
    }
  }

  toggleSelectAll(field: any) {
    // Toggle selection of all accounts
    if (this.accounts.filter(o => o !== 'toggle').length > 0) {
      this.accounts = [];
    } else {
      this.accounts = field.options.map(o => o.value);
    }

    const formControlAccount = this.getFirstFormControl();
    if (formControlAccount) {
      formControlAccount.setValue(this.accounts);
    }
  }
}
