import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {BackfillJobItem} from '@store/connectors/connectors.state';
import {groupBy} from 'lodash';
import {readableName} from '@pma/shared/utils/snake-to-camel/readable-name';
import {toShortISO} from '@pma/shared/utils/date/date';
import {dayTime} from '@pma/shared/utils/date/date';

interface BackfillJobGroup extends BackfillJobItem {
  status: string;
  jobsCount: number;
  jobsCountFinished: number;
  jobsCountFinishedWithError: number;
  jobTypesFinished: string;
  jobTypesNotFinished: string;
  jobTypesFailed: string;
  accountTimezone: string;
  accountTimezoneAbbreviation: string;
}

@Component({
  selector: 'app-usage-history-table',
  templateUrl: './usage-history-table.component.html',
  styleUrls: ['./usage-history-table.component.scss'],
})
export class UsageHistoryTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['date', 'start', 'end', 'finishDate', 'status', 'progress'];
  dataSource: MatTableDataSource<BackfillJobItem>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @Input() data: BackfillJobItem[];
  @Input() accountTimezone: string;
  @Input() accountTimezoneAbbreviation: string;
  @Input() isAdmin: boolean;

  dataGroupedByDate: BackfillJobGroup[] = [];

  ngOnInit() {
    const groupByDate = groupBy(this.data, 'date');
    // console.log('Data:', this.data);
    // console.log('Data grouped:', groupByDate);

    // Group jobs by creation date. Jobs not finished within 24 hours after they start are considered finished and failed
    const now = new Date();
    this.dataGroupedByDate = [];
    Object.entries(groupByDate).forEach(([date, jobs]: [string, BackfillJobItem[]]) => {
      const jobsFinished = jobs.filter(
        j => j.finishDate !== null || (j.finishDate == null && now.getTime() - new Date(date).getTime() > dayTime)
      );

      const jobsFinishedWithError = jobs.filter(
        j =>
          (j.finishDate !== null && j.status === 'failed') ||
          (j.finishDate == null && now.getTime() - new Date(date).getTime() > dayTime)
      );

      const jobsNotFinished = jobs.filter(
        j => j.finishDate === null && now.getTime() - new Date(date).getTime() <= dayTime
      );

      const jobLastFinished = jobs.find(j => j.finishDate !== null);

      // Set earliest start-date and latest end-date of jobs in the group
      // For the end date of the group exclude jobs for date-invariant tables.  Jobs for date-invariant tables will have start === end && start-day === job-create-day
      const startMin = jobs.reduce((min, j) => (j.start < min ? j.start : min), jobs[0].start);
      let endMax = jobs[0].end;
      const jobsExcludingDateInvariant = jobs.filter(
        j => j.start !== j.end || j.end.substr(0, 10) !== j.date.substr(0, 10)
      );
      if (jobsExcludingDateInvariant.length > 0) {
        endMax = jobsExcludingDateInvariant.reduce(
          (max, j) => (j.end > max ? j.end : max),
          jobsExcludingDateInvariant[0].end
        );
      }

      let status = 'notFinished';
      if (jobsFinished.length === jobs.length) {
        // All jobs in the group completed, but some may have failed
        if (jobsFinishedWithError.length > 0) {
          status = 'finishedWithErrors';
        } else {
          status = 'finished';
        }
      }

      this.dataGroupedByDate.push({
        date,
        accountTimezone: this.accountTimezone,
        accountTimezoneAbbreviation: this.accountTimezoneAbbreviation,
        start: toShortISO(startMin),
        end: toShortISO(endMax),
        finishDate: jobLastFinished ? jobLastFinished.finishDate : null,
        status,
        jobsCount: jobs.length,
        jobsCountFinished: jobsFinished.length,
        jobsCountFinishedWithError: jobsFinishedWithError.length,
        jobTypesFinished: 'Finished: ' + jobsFinished.map(j => readableName(j.subType)).join(', '),
        jobTypesNotFinished:
          jobsNotFinished.length === 0
            ? ''
            : 'Not finished: ' + jobsNotFinished.map(j => readableName(j.subType)).join(', '),
        jobTypesFailed:
          'Failed (' +
          jobsFinishedWithError.length.toString() +
          '): ' +
          jobsFinishedWithError.map(j => readableName(j.subType)).join(', '),
      } as BackfillJobGroup);

      this.dataSource = new MatTableDataSource(this.dataGroupedByDate);
    });
    // console.log('Data grouped with stats:', this.dataGroupedByDate);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
