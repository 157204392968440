import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatMenu} from '@angular/material/menu';
import {Select, Store} from '@ngxs/store';
import {BaseComponent} from '@shared/components/base/base.component';
import {MarkNotificationsAsRead, PatchNotificationStatus} from '@store/notifications/notifications.actions';
import {NotificationsState, NotificationsStateModel} from '@store/notifications/notifications.state';
import {Observable} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {NotificationAction, NotificationModel, NotificationType} from '@pma/shared/types/notification';
import {Dictionary} from '@pma/shared/interfaces/dictionary.interface';
import {flatten} from '@pma/shared/utils/flatten/flatten';
import {Router} from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Select(NotificationsState.countUnread) unreadCount$: Observable<number>;
  @Select(state => state.notifications) notificationsState$: Observable<NotificationsStateModel>;
  @Select(state => state.organizations.selectedId) organizationId$: Observable<string>;

  @ViewChild(MatMenu) menu;

  organizationId: string;

  constructor(private store: Store, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.organizationId$
      .pipe(
        tap(organizationId => {
          this.organizationId = organizationId;
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.onCloseMenu();
  }

  async dispatch(notification: NotificationModel, action: NotificationAction) {
    this.store.dispatch(new PatchNotificationStatus(notification, action));

    if (notification.type === NotificationType.AccountTokenExpired) {
      if (action === NotificationAction.View) {
        const organizationId = this.store.snapshot().organizations.selectedId;
        await this.router.navigate(['organizations', organizationId, 'sources']);
      }
    }
  }

  flatten(notification: NotificationModel): Dictionary {
    return flatten(notification);
  }

  private onCloseMenu() {
    this.menu.close
      .pipe(
        tap(() => this.store.dispatch(MarkNotificationsAsRead)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }
}
