import {DocumentReference} from '@angular/fire/firestore';

export class ShowLogsDialog {
  static readonly type = '[Logs] Show Logs Dialog';

  constructor(public ref: DocumentReference) {}
}

export class StreamLogs {
  static readonly type = '[Logs] Stream Logs';

  constructor(public entityPath: string) {}
}

export class ResetLogs {
  static readonly type = '[Logs] Reset Logs';

  constructor() {}
}
