import {Component, Inject, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BackfillJobItem} from '@store/connectors/connectors.state';
import {UserModel} from '@pma/shared/types/user';
import {getTimezoneAbbreviation} from '@pma/shared/utils/date/date';

export interface HistoryDialogData {
  accountTimezone: string;
}

@Component({
  selector: 'app-usage-history-dialog',
  templateUrl: './usage-history-dialog.component.html',
  styleUrls: ['./usage-history-dialog.component.scss'],
})
export class UsageHistoryDialogComponent implements OnInit {
  @Select(state => state.connectors.backfillHistory) backfillHistory$: Observable<BackfillJobItem[]>;
  @Select(state => state.auth.user) user$: Observable<UserModel>;

  isAdmin = false;
  accountTimezone: string;
  accountTimezoneAbbreviation: string; // Needed for Angular date pipe

  constructor(
    private dialogRef: MatDialogRef<UsageHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: HistoryDialogData
  ) {
    this.accountTimezone = data.accountTimezone;
    this.accountTimezoneAbbreviation = getTimezoneAbbreviation(this.accountTimezone);
    console.log('Timezone abbrevation:', this.accountTimezone, this.accountTimezoneAbbreviation);
  }

  ngOnInit() {
    this.user$
      .pipe(
        filter(Boolean),
        map((user: UserModel) => Boolean(user.isAdmin)),
        tap(isAdmin => {
          this.isAdmin = isAdmin;
        })
      )
      .subscribe();
  }
}
