import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {ExportDocument, ExportModel} from '@pma/shared/types/exports';

@Pipe({
  name: 'export',
})
export class ExportPipe extends DocumentPipe<ExportDocument, ExportModel> implements PipeTransform {
  transform(doc: ExportDocument, docId: string): ExportModel {
    const newDoc = super.transform(doc);
    newDoc.id = docId;
    newDoc.organization = doc.organization.id;
    newDoc.destinationAccount = doc.destinationAccount.id;
    newDoc.report = doc.report.id;

    return newDoc;
  }
}
