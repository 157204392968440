import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {environment} from '../../../../environments/environment';
import {ConnectorsProviderPipe} from '@shared/pipes/connectors-provider/connectors-provider.pipe';
import {first, map} from 'rxjs/operators';
import {ConnectorProvider, ConnectorProviderModel} from '@pma/shared/types/connector-provider';
import {ConfigItem} from '@pma/shared/types/connector';
import {Observable, of} from 'rxjs';
import {OrganizationsService} from '@core/services/organizations/organizations.service';
import {deleteField} from '@angular/fire/firestore';
import {Dictionary} from '@pma/shared/interfaces/dictionary.interface';
import {toShortISO} from '@pma/shared/utils/date/date';

import {OAUTH_TYPES} from '@pma/shared/interfaces/oauth';
import {Store} from '@ngxs/store';
import {FetchProviderCredentialsFields, OpenOAuthWindow} from '@core/store/providers/providers.actions';
import {ProviderCredentialsDialogComponent} from '@shared/components/provider-credentials-dialog/provider-credentials-dialog.component';
import {ConfirmDialogComponent} from '@shared/components/confirm-dialog/confirm-dialog.component';
import {TranslocoService} from '@ngneat/transloco';
import {MatDialog} from '@angular/material/dialog';
import {LogsService} from '@core/services/logs/logs.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectorsProvidersService extends BaseService {
  constructor(
    private http: HttpClient,
    private transformer: ConnectorsProviderPipe,
    private organizations: OrganizationsService,
    private store: Store,
    private dialog: MatDialog,
    private transloco: TranslocoService,
    private logs: LogsService
  ) {
    super();
  }

  /**
   * TODO docs
   */
  list(): Observable<ConnectorProviderModel[]> {
    return this.http
      .get(environment.apiEndpoint + '/http-providers')
      .pipe(map((providers: ConnectorProvider[]) => providers.map(this.transformer.transform)));
  }

  /**
   * TODO docs
   */
  getRedirectUri(type: string, organizationId: string, extra?: Dictionary): Observable<{uri: string}> {
    let requestUrl = environment.apiEndpoint + `/connectors-${type}-redirect/?organizationId=${organizationId}`;
    if (extra) {
      requestUrl += '&' + new URLSearchParams(extra);
    }
    return this.http.get(requestUrl, {withCredentials: true}) as Observable<{uri: string}>;
  }

  /**
   * TODO docs
   */
  connectAccount(type: string, organizationId: string, formValue: any) {
    const params = {...formValue, organizationId};
    const requestUrl = environment.apiEndpoint + `/connectors-${type}-token/?` + new URLSearchParams(params);
    return this.http.get(requestUrl, {withCredentials: true}).pipe(first());
  }

  /**
   * TODO docs
   */
  getFields(type: string): Promise<ConfigItem[]> {
    const requestUrl = environment.apiEndpoint + `/connectors-${type}-fields`;
    return this.http.get(requestUrl).pipe(first()).toPromise() as Promise<ConfigItem[]>;
  }

  async removeUsage(
    organizationId: string,
    accountId: string,
    connector: string,
    usageKey: string,
    usageName: string
  ): Promise<void> {
    const pathUsageKey = ['connections', accountId, 'usage', connector, usageKey].join('.');
    const pathUsageName = ['connections', accountId, 'usageNames', connector, usageKey].join('.');

    await this.organizations.update(organizationId, {[pathUsageKey]: deleteField()});
    await this.organizations.update(organizationId, {[pathUsageName]: deleteField()});

    // Remove usage in new format
    const pathUsage = ['usage', connector, accountId, usageKey].join('.');
    await this.organizations.update(organizationId, {[pathUsage]: deleteField()});

    const ref = this.logs.getLogRef('organizations', organizationId);
    const params = {
      type: connector,
      accountId,
      usageNames: [usageName],
      usageKeys: [usageKey],
    };
    await this.logs.log(ref, 'organization.usageRemoved', params);
    // console.log('organization.usageRemoved', params);
  }

  async updateUsageActive(
    organizationId: string,
    accountId: string,
    connector: string,
    usageKey: string,
    usageName: string,
    isActive: boolean
  ): Promise<void> {
    const pathUsageKey = ['usage', connector, accountId, usageKey, 'isActive'].join('.');
    await this.organizations.update(organizationId, {[pathUsageKey]: isActive});

    const ref = this.logs.getLogRef('organizations', organizationId);
    const params = {
      type: connector,
      accountId,
      usageNames: [usageName],
      usageKeys: [usageKey],
      date: toShortISO(new Date()),
      isActive,
    };
    await this.logs.log(ref, 'organization.usageUpdated', params);
    // console.log('organization.usageUpdated', params);
  }

  async addUsage(
    organizationId: string,
    accountId: string,
    connector: string,
    configType: string,
    usageKeys: string[],
    usageNames: string[]
  ): Promise<any> {
    // Add connector usage directly to Firestore organizations document
    // NOTE:  This is not currently used. It is an alternative to using the organization HTTP endpoint 'update-usage' but would require changes to Firestore rules
    const today = toShortISO(new Date());
    const updateObj: Dictionary<string> = {};

    usageKeys.forEach((k, i) => {
      updateObj[`connections.${accountId}.usage.${connector}.${k}`] = today;
      const name = usageNames[i];
      if (name) {
        updateObj[`connections.${accountId}.usageNames.${connector}.${k}`] = name;
      }
    });

    console.log('Connector usage update:', organizationId, updateObj);
    return this.organizations.update(organizationId, updateObj);
  }

  async addAccount(providerType: string) {
    const organizationId = this.store.snapshot().organizations.selectedId;
    const provider = this.store.snapshot().connectorsProviders.entities[providerType];

    const userGrantAction = (extra?: Dictionary) => {
      this.store.dispatch(new OpenOAuthWindow(providerType, organizationId, extra));
    };

    const clientCredentialsAction = (callback?: CallableFunction) => {
      this.store.dispatch(new FetchProviderCredentialsFields(providerType));

      const dialogRef = this.dialog.open(ProviderCredentialsDialogComponent, {
        data: {
          type: providerType,
          callback,
        },
        panelClass: ['main-dialog', 'provider-credentials-dialog'],
      });
    };

    switch (provider.oAuthType) {
      case OAUTH_TYPES.USER_GRANT:
        userGrantAction();
        break;
      case OAUTH_TYPES.CLIENT_CREDENTIALS:
        clientCredentialsAction();
        break;
      case OAUTH_TYPES.TWO_STEP:
        clientCredentialsAction((form: Dictionary) => {
          userGrantAction(form);
        });
        break;
      case OAUTH_TYPES.GRANT_OR_CREDENTIALS:
        const t = 'organizations.organization.providers.dialogs.selectAuth';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: this.transloco.translate(t + '.title'),
            actions: [
              {
                primary: false,
                value: 'USER_GRANT',
                title: this.transloco.translate(t + '.actions.userGrant', {title: providerType}),
                callback: () => {
                  dialogRef.close();
                  userGrantAction();
                },
              },
              {
                primary: false,
                value: 'CLIENT_CREDENTIALS',
                title: this.transloco.translate(t + '.actions.clientCredentials'),
                callback: () => {
                  dialogRef.close();
                  clientCredentialsAction();
                },
              },
            ],
          },
        });

        break;

      default:
        throw new Error('Unknown oAuthType');
    }
  }

  getAccountScopes(
    organizationId: string,
    accountId: string
  ): Promise<Dictionary<{label: string; isAuthorized: boolean}>> {
    const path = `/http-organizations/${organizationId}/scopes?accountId=${accountId}`;
    return this.http
      .get<Dictionary<{label: string; isAuthorized: boolean}>>(environment.apiEndpoint + path)
      .pipe(first())
      .toPromise();
  }
}
