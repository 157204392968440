import {NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, ExtraOptions, RouterModule, Routes} from '@angular/router';
import {
  AngularFireAuthGuard,
  AngularFireAuthGuardModule,
  loggedIn,
  redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';
import {SamlComponent} from './components/saml/saml.component';
import {pipe} from 'rxjs';
import {map, tap} from 'rxjs/operators';

const redirectUnauthorizedToLogin = (route: ActivatedRouteSnapshot) => {
  const path = route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
  const params = route.queryParams;

  return pipe(
    loggedIn,
    tap(isLoggedIn => {
      if (!isLoggedIn) {
        console.log('Saving afterLogin');
        sessionStorage.setItem('afterLogin', JSON.stringify({path, params}));
      }
    }),
    map(isLoggedIn => isLoggedIn || ['/'])
  );
};

const loginRoute = {
  loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
  canActivate: [AngularFireAuthGuard],
  data: {} as any,
};

// After login redirect to 'organizations' route unless a specific route is requested
const afterLogin = sessionStorage.getItem('afterLogin');
if (!afterLogin) {
  loginRoute.data.authGuardPipe = () => redirectLoggedInTo(['organizations']);
}

const routes: Routes = [
  {
    path: 'organizations',
    loadChildren: () => import('./organizations/organizations.module').then(mod => mod.OrganizationsModule),
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: 'resources',
    loadChildren: () => import('./resources/resources.module').then(mod => mod.ResourcesModule),
  },
  {
    path: 'invites/join',
    redirectTo: 'organizations',
  },
  {
    path: 'saml/sso',
    component: SamlComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: '',
    ...loginRoute,
  },
  {
    path: ':type',
    ...loginRoute,
  },
];

const extraOptions: ExtraOptions = {
  enableTracing: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions), AngularFireAuthGuardModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
