import {wait} from '../wait/wait';

export async function retryPromise(callable: CallableFunction | Promise<any>, maxTries = 5, delay = 100) {
  let tries = 0;
  let lastError: Error = new Error('never tried');
  while (tries < maxTries) {
    tries++;
    try {
      let promise = callable;
      if (callable instanceof Function) {
        promise = callable();
      }
      return await promise;
    } catch (e) {
      console.log('Retry error', e);
      lastError = e;
      await wait(delay);
    }
  }
  throw lastError;
}
