import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorsService {
  constructor(private transloco: TranslocoService) {
    //
  }

  translateError(httpError: HttpErrorResponse): string {
    const error = httpError.error;

    let message: string;

    try {
      if (error.message) {
        message = this.transloco.translate(`api.errors.${error.message}`);

        // Clean error message that has no translation
        if (message.indexOf('api.errors.') > -1) {
          message = message.replace('api.errors.', '');
        }
      }

      if (error.entity) {
        message = this.transloco.translate(`api.errors.notFound.${error.entity}`);
      }
    } catch {
      console.error(httpError);

      message = this.transloco.translate('api.errors.internal');
    }

    return message || this.transloco.translate('api.errors.unknown');
  }
}
