import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {ProviderAccountClientDocument, ProviderAccountModel} from '@pma/shared/types/account';

@Pipe({
  name: 'account',
})
export class AccountPipe
  extends DocumentPipe<ProviderAccountClientDocument, ProviderAccountModel>
  implements PipeTransform
{
  transform(doc: ProviderAccountClientDocument, accountId: string): ProviderAccountModel {
    delete doc.token;
    delete doc.organizations;
    return {...super.transform(doc), id: accountId};
  }
}
