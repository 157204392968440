<ng-container *transloco="let t; read: 'app.nav'">
  <ng-container *ngIf="authState$ | async as authState">
    <ng-container *ngIf="authState.isLoggedIn">
      <!-- Notifications (only on larger screens) -->
      <app-notifications *ngIf="screenSize == 'md' || screenSize == 'lg' || screenSize == 'xl'"></app-notifications>
      <div class="nav-space"></div>

      <!-- Hub menu trigger -->
      <button
        mat-button
        class="hubnav-button"
        [class.hubnav-open]="isHubnavOpen"
        [matMenuTriggerFor]="authDetails"
        (menuOpened)="navMenuToggle(true)"
        (menuClosed)="navMenuToggle(false)">
        <span class="hubnav-button-wrapper">
          <span *ngIf="organization$ | async as organization" class="hubnav-organization">{{ organization.name }}</span>
          <mat-icon fontSet="material-icons-outlined" class="hubnav-icon">more_vert</mat-icon>
        </span>
        <!--        <span *ngIf="organization$ | async as organization" class="hubnav-organization">-->
        <!--          -->
        <!--          -->
        <!--        </span>-->
      </button>

      <!-- Hub menu -->
      <mat-menu #authDetails="matMenu" class="auth-menu" xPosition="before">
        <ng-container *ngIf="organization$ | async as organization">
          <!-- Current organization
          <div class="nav-item-hub-current">{{ organization.name }}</div>-->

          <!-- Navigation to pages in organization route -->
          <ng-container *ngFor="let menuItem of menuItems; index as i">
            <div
              mat-menu-item
              class="nav-item-org"
              [routerLink]="['organizations', organization.id, menuItem.route]"
              routerLinkActive="color-background-nav-active">
              <mat-icon fontSet="material-icons" class="nav-icon" routerLinkActive="color-accent-light">{{
                menuItem.icon
              }}</mat-icon>
              <span class="nav-item-route">{{ t(menuItem.titleKey) }}</span>
            </div>
          </ng-container>
        </ng-container>

        <!-- Organization list, allow organization switching -->
        <ng-container *ngIf="organizations$ | async as organizations">
          <ng-container *ngIf="organizations.length > 1 || isAdmin">
            <div mat-menu-item class="nav-divider">{{ t('group.switchHub') }}</div>

            <button
              mat-menu-item
              class="nav-item-hub-switch"
              *ngFor="let organization of organizations"
              (click)="switchOrganization(organization)">
              <span class="color-accent-light">{{ organization.name }}</span>
            </button>
          </ng-container>
        </ng-container>

        <!-- User info -->
        <div mat-menu-item class="nav-divider">{{ t('group.signedInAs') }}</div>
        <div class="nav-item-user">
          <img
            [src]="authState.user.photoURL"
            alt="avatar"
            class="auth-avatar borderless"
            [matTooltip]="authState.user.displayName"
            [matTooltipClass]="'explanation-tooltip'" />
          <div>
            <div class="user-info text-primary">{{ authState.user.displayName }}</div>
            <div class="user-info text-secondary">{{ authState.user.email }}</div>
          </div>
        </div>

        <!-- User sign out -->
        <div mat-menu-item class="nav-item-sign-out highlight-background-transparent" (click)="signOut()">
          {{ t('auth.signOut') }}
        </div>
      </mat-menu>
    </ng-container>
  </ng-container>
</ng-container>
