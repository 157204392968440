<ng-container *transloco="let t; read: 'organizations.organization.billing.details'">
  <!-- Status: Cancelled -->

  <ng-container *ngIf="billingService.isBillingCancelled(billing)">
    <div class="billing-title">{{ t('cancelledTitle') }}</div>
  </ng-container>

  <!-- Status: Trial -->

  <ng-container *ngIf="billing.status == 'trial' && billing['nextBilling'] as billingPeriod">
    <div class="flex-row-between">
      <div>
        <div class="billing-title">{{ t('trialTitle') }}</div>
        <p>
          <span>{{ t('trialText1') }}&nbsp;&nbsp;</span>
          <span *ngIf="billingPeriod.date">{{ billingPeriod.date | date }}</span>
        </p>
        <p *ngIf="isBillingRoute">
          <span>{{ t('trialText2') }}&nbsp;</span>
          <span>{{ t('trialText3') }}</span>
        </p>
      </div>
      <div>
        <!--<img alt="Explain billing" src="assets/images/super-girl.png" width="100">-->
      </div>
    </div>
  </ng-container>

  <!-- Status: Live, Paused, Expired, Unpaid, Dunning -->

  <ng-container
    *ngIf="
      (billing.status == 'live' ||
        billing.status == 'paused' ||
        billingService.isBillingExpired(billing) ||
        billingService.isBillingUnpaid(billing)) &&
      billing['nextBilling'] as billingPeriod
    ">
    <div class="flex-row-between">
      <!-- Left side -->
      <div>
        <!-- Status: Live -->
        <div *ngIf="billing.status == 'live' && isBillingRoute" class="billing-title">{{ t('titlePayment') }}</div>

        <!-- Status: Paused -->
        <ng-container *ngIf="billing.status == 'paused'">
          <div class="billing-title">{{ t('pausedTitle') }}</div>
        </ng-container>

        <!-- Status: Expired -->
        <ng-container *ngIf="billingService.isBillingExpired(billing)">
          <div class="billing-title">{{ t('expiredTitle') }}</div>
          <p id="expired-message">{{ t('trialText2') }} {{ t('trialExpired') }}</p>
        </ng-container>

        <!-- Status: Unpaid, Dunning -->
        <ng-container *ngIf="billingService.isBillingUnpaid(billing)">
          <div class="billing-title">{{ t('unpaidTitle') }}</div>
        </ng-container>

        <!-- Payment: There is no payment date for expired subscriptions -->
        <div *ngIf="billingPeriod.date" id="payment">
          <p class="flex-row-between">
            <span>{{ t('paymentNext') }}:</span>
            <span *ngIf="billingPeriod.date" class="payment-value">{{ billingPeriod.date | date }}</span>
          </p>
          <p class="flex-row-between">
            <span>{{ t('paymentAmount') }}:</span>
            <span *ngIf="billingPeriod.amount" class="payment-value">${{ billingPeriod.amount.toFixed(2) }}</span>
            <span *ngIf="!billingPeriod.amount" class="payment-value">$0.00</span>
          </p>
        </div>
      </div>

      <!-- Right side -->
      <div>
        <ng-container *transloco="let t; read: 'organizations.organization.billing.nav'">
          <!--<img alt="Explain billing" src="assets/images/super-girl.png" width="100">-->
          <br />
          <button
            mat-flat-button
            *ngIf="isBillingRoute"
            class="billing-explain"
            [routerLink]="['/organizations', organizationId, 'billing', 'explain']">
            {{ t('explain') }}
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
