<div>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div>
<h2 id="title">Please wait while we authenticate the request</h2>
<form #SAML="ngForm" (ngSubmit)="onSubmit(SAML, $event)" method="post" [action]="submitURL">
  <input type="text" hidden name="SAMLResponse" placeholder="SAMLResponse" [(ngModel)]="SAMLResponse" />
  <input type="text" hidden name="RelayState" placeholder="relayState" [(ngModel)]="RelayState" />
  <br />
  <br />
  <input type="submit" hidden value="submit" />
</form>
