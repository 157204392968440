import {Component, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseComponent} from '@shared/components/base/base.component';
import {Subject} from 'rxjs';
import {filter, takeUntil, takeWhile, tap} from 'rxjs/operators';

interface ConfirmDialogActions {
  primary: boolean;
  value: string;
  title?: string;
  callback?: () => any;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends BaseComponent implements OnInit {
  @Input() isLoading = false;
  @Input() closeOnFalseyAction = true;
  @Input() title?: string;
  @Input() content?: string;
  @Input() url?: string;
  @Output() action: Subject<boolean> = new Subject();
  @Input() actions?: ConfirmDialogActions[] = [
    {
      primary: false,
      value: 'cancel',
      callback: () => this.action.next(false),
    },
    {
      primary: true,
      value: 'confirm',
      callback: () => this.action.next(true),
    },
  ];

  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) {
    super();

    this.title = (data && data.title) || this.title;
    this.content = (data && data.content) || this.content;
    this.actions = (data && data.actions) || this.actions;
    this.url = (data && data.url) || this.url;
  }

  ngOnInit() {
    this.action
      .pipe(
        takeWhile(() => this.closeOnFalseyAction),
        filter(value => !value),
        tap(() => this.dialogRef.close()),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }
}
