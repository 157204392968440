import {OrganizationsStateModel} from '@store/organizations/organizations.state';
import {UserRole} from '@pma/shared/types/user';
import {
  OrganizationBillingDetails,
  OrganizationBillingStatus,
  OrganizationSQLDatabase,
} from '@pma/shared/types/organization';

export class StreamOrganizations {
  static readonly type = '[Organizations] Stream Organizations Data';
}

export class StreamOrganizationDirect {
  static readonly type = '[Organizations] Stream Organization Direct';

  constructor(public id: string) {}
}

export class RemoveOrganizationDirect {
  static readonly type = '[Organizations] Remove Organization Direct';
}

export class FetchOrganizationsLookup {
  static readonly type = '[Organizations] Fetch Organizations Lookup';

  constructor(public group: string, public isCached: boolean) {}
}

export class PatchStreamedOrganizations {
  static readonly type = '[Organizations] Patch Streamed';

  constructor(public payload: Partial<OrganizationsStateModel>) {}
}

export class SelectOrganization {
  static readonly type = '[Organizations] Select Organization';
  static readonly eventParams = ['selectedId'];

  constructor(public selectedId: string) {}
}

export class CreatedOrganization {
  static readonly type = '[Organizations] Created';

  constructor() {}
}

export class SetOrganizationName {
  static readonly type = '[Organizations] Set Name';
  static readonly eventParams = ['name'];

  constructor(public name: string) {}
}

export class InviteOrganizationMember {
  static readonly type = '[Organizations] Invite Member';
  static readonly eventParams = ['email'];

  constructor(public organizationId: string, public email: string, public role: UserRole) {}
}

export class RemoveOrganizationMember {
  static readonly type = '[Organizations] Remove Member';
  static readonly eventParams = ['emailAddress'];

  constructor(public uid: string, public organizationId: string) {}
}

export class AddOrganizationMember {
  static readonly type = '[Organizations] Add Member';

  constructor(public uid: string, public organizationId: string, public role: UserRole) {}
}

export class ChangeOrganizationMemberRole {
  static readonly type = '[Organizations] Change Role';

  constructor(public uid: string, public role: UserRole, public organizationId: string) {}
}

export class ChangeOrganizationBillingCode {
  static readonly type = '[Organizations] Change Billing Code';

  constructor(public planCode: string) {}
}

export class ChangePlanAndExtendTrial {
  static readonly type = '[Organizations] Change Plan And Extend Trial';

  constructor(public planCode: string) {}
}

export class UpdateOrganizationBillingDetails {
  static readonly type = '[Organizations] Update Organization Billing Details';

  constructor(public details: OrganizationBillingDetails) {}
}

// The invoices list is superceded by the transaction list, and is not used
export class ListOrganizationInvoices {
  static readonly type = '[Organizations] List Invoices';
}

export class GetOrganizationInvoice {
  static readonly type = '[Organizations] Get Invoice';

  constructor(public invoiceId: string) {}
}

export class ListCustomerTransactions {
  static readonly type = '[Organizations] List Customer Transactions';

  constructor(public organizationId: string) {}
}

export class GetCustomerTransaction {
  static readonly type = '[Organizations] Get Customer Transaction';

  constructor(public organizationId: string, public transactionId: string, public transactionType: string) {}
}

export class ListSubscriptionActivities {
  static readonly type = '[Organizations] List Subscription Zoho Activities';

  constructor(public organizationId: string) {}
}

export class ListSubscriptionZohoRequests {
  static readonly type = '[Organizations] List Subscription Zoho Requests';

  constructor(public organizationId: string) {}
}

export class ModifyAddonQuantity {
  static readonly type = '[Organizations] Modify Addon Quantity';

  constructor(public addon: string, public quantity: number) {}
}

export class FetchOrganizationBillingUrl {
  static readonly type = '[Organizations] Fetch billing URL';

  constructor(public organizationId: string) {}
}

export class CancelPlan {
  static readonly type = '[Organizations] Cancel Plan';

  constructor(public organizationId?: string) {}
}

export class PauseTogglePlan {
  static readonly type = '[Organizations] Pause Toggle Plan';

  constructor() {}
}

export class RemovePaymentMethod {
  static readonly type = '[Organizations] Remove Payment Method';

  constructor() {}
}

export class CollectInvoices {
  static readonly type = '[Organizations] Collect Invoices';

  constructor() {}
}

export class ReactivateSubscription {
  static readonly type = '[Organizations] Reactivate Subscription';

  constructor(public organizationId?: string) {}
}

export class SetTrackerGAInitial {
  static readonly type = '[Organizations] Set Tracker: GA:Initial';

  constructor(public clientId: string) {}
}

export class SetTrackerGALatest {
  static readonly type = '[Organizations] Set Tracker: GA:Latest';

  constructor(public clientId: string) {}
}

export class UpdateSQLDatabaseDetails {
  static readonly type = '[Organizations] Update SQL Database Details';

  constructor(public dbDetails: OrganizationSQLDatabase) {}
}

export class RemoveSQLDatabase {
  static readonly type = '[Organizations] Remove SQL Database';
}

export class UpdateUsage {
  static readonly type = '[Organizations] Update Usage';

  constructor(public usage: any) {}
}

export class RemoveUsage {
  static readonly type = '[Organizations] Remove Usage';

  constructor(public usage: any) {}
}

export class SetIntegrationStatus {
  static readonly type = '[Organizations] Set Integration Status';

  constructor(public type: OrganizationBillingStatus, public status: string, public isStartIntegration: boolean) {}
}

export class SetAccountTimezone {
  static readonly type = '[Organizations] Set Account Timezone';

  constructor(public accountId: string, public timezone: string) {}
}

export class StreamApiTokens {
  static readonly type = '[Organizations] Stream Organization API Tokens';
}

export class CreateApiToken {
  static readonly type = '[Organizations] Create Organization API Token';

  constructor(public organizationId: string, public apiToken: string) {}
}

export class DeleteApiToken {
  static readonly type = '[Organizations] Delete Organization API Token';

  constructor(public tokenId: string) {}
}

export class GetOverviewStats {
  static readonly type = '[Organizations] Get Overview Stats';

  constructor(public organizationId: string) {}
}

export class GetUsageHistoric {
  static readonly type = '[Organizations] Get Usage Historic';

  constructor(public organizationId: string, public date: Date) {}
}
