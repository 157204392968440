import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TokenInterceptor} from '@core/interceptors/token.interceptor';
import {ngxsConfig} from '@core/ngxs.config';
import {NgxsInterceptorPluginModule} from '@core/plugins/ngxs-interceptor/ngxs-interceptor.plugin';
import {environment} from '../../environments/environment';
import {TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule} from '@ngneat/transloco';
import {TranslocoMessageFormatModule} from '@ngneat/transloco-messageformat';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';
import {NotificationsState} from '@store/notifications/notifications.state';
import {customTranslocoMissingHandler, translocoLoader} from '../transloco.loader';
import {LogsState} from '@store/logs/logs.state';
import {LogsService} from '@core/services/logs/logs.service';
import {AppState} from '@store/app/app.state';
import {OrganizationsState} from '@store/organizations/organizations.state';
import {ConnectorsProvidersState} from '@store/providers/providers.state';

import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFireStorageModule, BUCKET} from '@angular/fire/compat/storage';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFirePerformanceModule} from '@angular/fire/compat/performance';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';

import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {getAnalytics, provideAnalytics} from '@angular/fire/analytics';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot(
      [AppState, AuthState, NotificationsState, LogsState, OrganizationsState, ConnectorsProvidersState],
      ngxsConfig
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsInterceptorPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    TranslocoMessageFormatModule.forRoot(),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule.enablePersistence({synchronizeTabs: true}),
    AngularFireAuthGuardModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    // provide modular style for AppCheck, see app.browser/server
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
  ],
  exports: [BrowserModule, TranslocoModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: ['en', 'es'],
        defaultLang: 'en',
        prodMode: environment.production,
      } as TranslocoConfig,
    },
    customTranslocoMissingHandler,
    translocoLoader,
    LogsService,
    {provide: BUCKET, useValue: environment.firebase.storageBucket},
  ],
})
export class CoreModule {}
