<div *transloco="let t; read: 'organizations.organization.usage.table'" class="table-wrapper">
  <table [dataSource]="dataSource" mat-table matSort matSortActive="lastUsed" matSortDirection="desc">
    <!-- Connector Column -->
    <ng-container matColumnDef="connector">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('connector') }}</th>
      <td *matCellDef="let usage" mat-cell>
        <div class="flex-row-start flex-row-align-center">
          <div *ngIf="isSelectionAllowed">
            <mat-checkbox
              (change)="
                selectUsageItem($event.checked, usage.accountId, usage.connector, usage.usageKey, usage.usageName)
              "
              class="usage-checkbox"></mat-checkbox>
          </div>

          <app-picture
            *ngIf="connectors$ | async as connectors"
            [alt]="connectors.entities[usage.connector]?.title"
            [formats]="['svg', 'png']"
            [matTooltip]="connectors.entities[usage.connector]?.title"
            [matTooltipClass]="'explanation-tooltip'"
            [srcset]="connectors.entities[usage.connector]?.logoPath"></app-picture>
        </div>
      </td>
    </ng-container>

    <!-- Account Column -->
    <ng-container matColumnDef="account">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('account') }}</th>
      <td *matCellDef="let usage" mat-cell>
        <app-table-user-cell [userData]="usage.account"></app-table-user-cell>
      </td>
    </ng-container>

    <!-- Profile Column -->
    <ng-container matColumnDef="usageName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('usageName') }}</th>
      <td *matCellDef="let usage" mat-cell>{{ usage.usageName }}</td>
    </ng-container>

    <!-- Time Column -->
    <ng-container matColumnDef="lastUsed">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('lastUsed') }}</th>
      <td *matCellDef="let usage" mat-cell>{{ usage.lastUsed | date }}</td>
    </ng-container>

    <!-- Actions column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>{{ t('actions') }}</th>
      <td *matCellDef="let usage" mat-cell>
        <!-- Backfill import, when account token is valid -->
        <ng-container *ngIf="isBackfillAllowed && usage.account.isTokenValid !== false">
          <button
            mat-icon-button
            [matMenuTriggerFor]="backfillMenu"
            [matTooltip]="t('actions.backfillConnection')"
            [matTooltipClass]="'explanation-tooltip'">
            <mat-icon class="button-icon">cloud_upload</mat-icon>
          </button>
          <mat-menu #backfillMenu="matMenu" xPosition="before">
            <app-backfill-menu
              [accountId]="usage.accountId"
              [connector]="usage.connector"
              [usageKey]="usage.usageKey"></app-backfill-menu>
          </mat-menu>
        </ng-container>

        <!-- Remove Button -->
        <button
          mat-icon-button
          (click)="removeUsage(usage)"
          [matTooltip]="t('actions.remove')"
          [matTooltipClass]="'explanation-tooltip'">
          <mat-icon fontSet="material-icons-outlined" class="color-warn">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" id="table-columns" mat-header-row></tr>
    <tr *matRowDef="let log; columns: displayedColumns" mat-row></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
</div>
