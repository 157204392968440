import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {Auth, idToken} from '@angular/fire/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: Auth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return idToken(this.auth).pipe(
      tap(token => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }),
      switchMap(() => next.handle(request))
    );
  }
}
