<picture [class.loading]="this.loading">
  <ng-container *ngIf="!external">
    <source *ngFor="let format of formats" [srcset]="srcset + '.' + format" [type]="getMimeType(format)" />
  </ng-container>
  <img
    (load)="presentImage()"
    [alt]="alt"
    [src]="src || srcset + '.' + formats[formats.length - 1]"
    [style]="encapsulate == 'width' ? {width: '100%'} : {height: '100%'}"
    [class.isBorder]="isBorder" />
</picture>
