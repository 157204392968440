import {Injectable} from '@angular/core';
import {ConnectorsProvidersService} from '@core/services/conntectors-providers/connectors-providers.service';
import {Action, NgxsOnInit, State, StateContext} from '@ngxs/store';
import {
  AddAccountUsage,
  AddProviderCredentialsAccount,
  UpdateAccountUsageActive,
  FetchProviderCredentialsFields,
  FetchProviders,
  OpenConnectorInLookerStudio,
  OpenOAuthWindow,
  RemoveAccountUsage,
  GetAccountScopes,
} from '@store/providers/providers.actions';
import {keyBy} from 'lodash';
import {Dictionary} from '@pma/shared/interfaces/dictionary.interface';
import {ConnectorProviderModel} from '@pma/shared/types/connector-provider';
import {ConfigItem} from '@pma/shared/types/connector';
import {DisplayError, StartLoading, StopLoading} from '@store/app/app.actions';
import {HttpErrorsService} from '@core/services/http-errors/http-errors.service';
import {interval, Observable, of, throwError} from 'rxjs';
import {catchError, delay, filter, finalize, first, switchMap, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {retryPromise} from '@pma/shared/utils/retry/retry';

export interface ProvidersStateModel {
  isInitialized: boolean;
  collection: ConnectorProviderModel[];
  entities: Dictionary<ConnectorProviderModel>;
  credentialsFields: ConfigItem[];
  accountScopes: Dictionary<{label: string; isAuthorized: boolean}>;
}

const initialState: ProvidersStateModel = {
  isInitialized: false,
  collection: [],
  entities: {},
  credentialsFields: [],
  accountScopes: null,
};

@Injectable()
@State<ProvidersStateModel>({
  name: 'connectorsProviders',
  defaults: initialState,
})
export class ConnectorsProvidersState implements NgxsOnInit {
  constructor(private service: ConnectorsProvidersService, private httpErrorsService: HttpErrorsService) {
    //
  }

  /**
   * On initializing ConnectorsProvidersState
   */
  ngxsOnInit({dispatch}: StateContext<ProvidersStateModel>) {
    dispatch(FetchProviders);
  }

  /**
   * Get a list of available connectors
   */
  @Action(FetchProviders)
  async fetch({patchState, dispatch}: StateContext<ProvidersStateModel>) {
    try {
      const collection = await retryPromise(() => this.service.list().pipe(first()).toPromise());
      return patchState({
        isInitialized: true,
        entities: keyBy<ConnectorProviderModel>(collection, 'type'),
        collection,
      });
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }
  }

  /**
   * Add an account to a provider
   */
  @Action(OpenOAuthWindow)
  openOAuthWindow(
    {getState, patchState, dispatch}: StateContext<ProvidersStateModel>,
    {providerType, organizationId, extra}: OpenOAuthWindow
  ) {
    dispatch(StartLoading);

    return this.service.getRedirectUri(providerType, organizationId, extra).pipe(
      first(),
      switchMap(({uri}) => {
        // Open popup window
        const height = 500;
        const width = 500;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const w = window.open(
          uri,
          'oauth',
          'width=' + width + ', height=' + height + ', top=' + top + ', left=' + left
        );

        if (!w || w.closed || typeof w.closed === 'undefined') {
          // POPUP BLOCKED
          throw new Error(
            'Your browser have blocked the authentication popup window. For instructions, go to https://support.google.com/chrome/answer/95472'
          );
        }

        return interval(100).pipe(
          filter(() => w && w.closed),
          first(),
          tap(() => dispatch(StopLoading))
        );
      }),
      catchError(error => dispatch([new DisplayError(error), StopLoading]))
    );
  }

  /**
   * Add an account to a provider
   */
  @Action(AddProviderCredentialsAccount)
  addAccountByCredentials(
    {patchState, dispatch}: StateContext<ProvidersStateModel>,
    {providerType, organizationId, formValue}: AddProviderCredentialsAccount
  ) {
    dispatch(new StartLoading());

    return this.service.connectAccount(providerType, organizationId, formValue).pipe(
      delay(5000),
      finalize(() => dispatch(new StopLoading())),
      catchError(e => {
        dispatch(new DisplayError(e));
        return throwError(e);
      })
    );
  }

  /**
   * Get credential fields for the provider
   */
  @Action(FetchProviderCredentialsFields)
  async fetchFields(
    {patchState, dispatch}: StateContext<ProvidersStateModel>,
    {providerType}: FetchProviderCredentialsFields
  ) {
    patchState({credentialsFields: initialState.credentialsFields});

    let fields;
    try {
      fields = await this.service.getFields(providerType);
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }

    return patchState({credentialsFields: fields});
  }

  /**
   * Remove usage for given path
   */
  @Action(RemoveAccountUsage)
  removeUsage(
    {patchState, dispatch}: StateContext<ProvidersStateModel>,
    {organizationId, accountId, connector, usageKey, usageName}: RemoveAccountUsage
  ) {
    return of(this.service.removeUsage(organizationId, accountId, connector, usageKey, usageName));
  }

  /**
   * Block usage for given path
   */
  @Action(UpdateAccountUsageActive)
  updateAccountUsageActive(
    {patchState, dispatch}: StateContext<ProvidersStateModel>,
    {organizationId, accountId, connector, usageKey, usageName, isActive}: UpdateAccountUsageActive
  ) {
    return of(this.service.updateUsageActive(organizationId, accountId, connector, usageKey, usageName, isActive));
  }

  /**
   * Add usage for given path
   */
  @Action(AddAccountUsage)
  addUsage(
    {patchState, dispatch}: StateContext<ProvidersStateModel>,
    {organizationId, accountId, connector, configType, usageKeys, usageNames}: AddAccountUsage
  ) {
    return of(this.service.addUsage(organizationId, accountId, connector, configType, usageKeys, usageNames));
  }

  /**
   * Open looker studio for a given connector
   */
  @Action(OpenConnectorInLookerStudio)
  async OpenConnectorInLookerStudio({}: StateContext<ProvidersStateModel>, {connector}: OpenConnectorInLookerStudio) {
    window.open(connector.integrations.dataStudio);
  }

  @Action(GetAccountScopes)
  async getAccountScopes(
    {patchState, dispatch}: StateContext<ProvidersStateModel>,
    {organizationId, accountId}: GetAccountScopes
  ) {
    patchState({accountScopes: null});

    try {
      const accountScopes = await this.service.getAccountScopes(organizationId, accountId);
      patchState({accountScopes});
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }
  }
}
