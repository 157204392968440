import {Injectable} from '@angular/core';
import {OrganizationsService} from '@core/services/organizations/organizations.service';
import {Action, NgxsOnInit, Select, Selector, State, StateContext, Store} from '@ngxs/store';

import {
  AddOrganizationMember,
  CancelPlan,
  ChangeOrganizationBillingCode,
  ChangeOrganizationMemberRole,
  ChangePlanAndExtendTrial,
  CollectInvoices,
  CreateApiToken,
  DeleteApiToken,
  FetchOrganizationBillingUrl,
  FetchOrganizationsLookup,
  GetCustomerTransaction,
  GetOrganizationInvoice,
  GetOverviewStats,
  GetUsageHistoric,
  InviteOrganizationMember,
  ListCustomerTransactions,
  ListOrganizationInvoices,
  ListSubscriptionActivities,
  ListSubscriptionZohoRequests,
  ModifyAddonQuantity,
  PatchStreamedOrganizations,
  PauseTogglePlan,
  ReactivateSubscription,
  RemoveOrganizationDirect,
  RemoveOrganizationMember,
  RemovePaymentMethod,
  RemoveSQLDatabase,
  RemoveUsage,
  SelectOrganization,
  SetAccountTimezone,
  SetIntegrationStatus,
  SetOrganizationName,
  SetTrackerGAInitial,
  SetTrackerGALatest,
  StreamApiTokens,
  StreamOrganizationDirect,
  StreamOrganizations,
  UpdateOrganizationBillingDetails,
  UpdateSQLDatabaseDetails,
  UpdateUsage,
} from '@store/organizations/organizations.actions';
import {keyBy} from 'lodash';
import {Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Dictionary} from '@pma/shared/interfaces/dictionary.interface';
import {OrganizationLookup, OrganizationModel, OrganizationTokenModel} from '@pma/shared/types/organization';
import {RemoveProviderAccount} from '@store/providers/providers.actions';
import {DisplayError, StartLoading, StopLoading} from '@store/app/app.actions';
import {HttpErrorsService} from '@core/services/http-errors/http-errors.service';
import {
  ZohoCustomerTransaction,
  ZohoInvoice,
  ZohoSubscriptionActivity,
} from '@pma/shared/interfaces/zoho-subscriptions.interface';
import {getFirstKey} from '@pma/shared/utils';
import {AccountsService} from '@core/services/accounts/accounts.service';
import {UserModel} from '@pma/shared/types/user';

export interface OrganizationStats {
  jobsCount: number;
  databaseSize: number;
}

export interface OrganizationsInvoicesStateModel {
  list: ZohoInvoice[];
  isLoadingInvoice: boolean;
  currentInvoiceUrl: string;
}

export interface OrganizationTransactionsStateModel {
  list: ZohoCustomerTransaction[];
  isLoadingInvoice: boolean;
  currentInvoiceUrl: string;
}

export interface OrganizationsStateModel {
  isInitialized: boolean;
  entities: Dictionary<OrganizationModel>;
  entityDirect: OrganizationModel; // Allow admins to act as a specfic organization
  entityLookup: OrganizationLookup[];
  selectedId: string;
  billingUrl: string;
  invoices: OrganizationsInvoicesStateModel;
  transactions: OrganizationTransactionsStateModel;
  tokens: OrganizationTokenModel[];
  stats: OrganizationStats;
  usageHistoric: any[];
  zohoActivities: ZohoSubscriptionActivity[];
  zohoRequests: any[];
}

const initialState: OrganizationsStateModel = {
  isInitialized: false,
  entities: undefined,
  entityDirect: undefined,
  entityLookup: undefined,
  selectedId: undefined,
  billingUrl: undefined,
  invoices: {
    list: undefined,
    isLoadingInvoice: false,
    currentInvoiceUrl: undefined,
  },
  transactions: {
    list: undefined,
    isLoadingInvoice: false,
    currentInvoiceUrl: undefined,
  },
  tokens: undefined,
  stats: {jobsCount: 0, databaseSize: 0} as OrganizationStats,
  usageHistoric: undefined,
  zohoActivities: undefined,
  zohoRequests: undefined,
};

@Injectable()
@State<OrganizationsStateModel>({
  name: 'organizations',
  defaults: initialState,
})
export class OrganizationsState implements NgxsOnInit {
  static organizationSelectedChanged = new Subject<void>();
  @Select(OrganizationsState.selected) organization$: Observable<OrganizationModel>;

  constructor(
    private service: OrganizationsService,
    private accountsService: AccountsService,
    private store: Store,
    private httpErrorsService: HttpErrorsService
  ) {
    (window as any).setLatestGATracker = clientId => store.dispatch(new SetTrackerGALatest(clientId));
    (window as any).setInitialGATracker = clientId => store.dispatch(new SetTrackerGAInitial(clientId));
  }

  /**
   * Get the selected organization entity
   */
  @Selector()
  static selected(state: OrganizationsStateModel) {
    if (state && state.entityDirect) {
      return state.entityDirect;
    } else {
      return state && state.entities ? state.entities[state.selectedId] : undefined;
    }
  }

  /**
   * Get the organization stats
   */
  @Selector()
  static stats(state: OrganizationsStateModel) {
    return state.stats;
  }

  /**
   * Get organizations as an array
   */
  @Selector()
  static list(state: OrganizationsStateModel) {
    const organizations = state.entities ? Object.values(state.entities) : [];

    // Sort alphabetically with inactive organizations last
    organizations.sort((a, b) => {
      if (!a.billing.isActive && b.billing.isActive) {
        return 1;
      } else if (a.billing.isActive && !b.billing.isActive) {
        return -1;
      } else if (!a.name || !b.name) {
        return Number(Boolean(a.name)) - Number(Boolean(b.name));
      } else {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
      }
    });

    return organizations;
  }

  /**
   * On initializing OrganizationsState
   * @param dispatch - dispatch action
   */
  ngxsOnInit({dispatch}: StateContext<OrganizationsStateModel>) {
    dispatch(StreamOrganizations);

    this.listenGATrackers(dispatch);
  }

  listenGATrackers(dispatch) {
    this.organization$
      .pipe(
        filter(Boolean),
        distinctUntilChanged((prev: OrganizationModel, curr: OrganizationModel) => prev && prev.id === curr.id),
        tap((organization: OrganizationModel) => {
          // @ts-ignore
          if ((window as any).ga) {
            const [ga] = (window as any).ga.getAll();
            if (!ga) {
              return;
            }
            const currentClientId = ga.get('clientId');
            if (!organization.trackers || !organization.trackers.ga) {
              dispatch([new SetTrackerGAInitial(currentClientId), new SetTrackerGALatest(currentClientId)]);
            } else {
              if (!organization.trackers.ga.initialClientId) {
                dispatch(new SetTrackerGAInitial(currentClientId));
              }
              if (organization.trackers.ga.latestClientId !== currentClientId) {
                dispatch(new SetTrackerGALatest(currentClientId));
              }
            }
          }
        })
      )
      .subscribe();
  }

  /**
   * Listen to authentication state and updates AuthState accordingly
   */
  @Action(StreamOrganizations)
  stream({dispatch, getState}: StateContext<OrganizationsStateModel>) {
    return this.service.organizations$.pipe(
      map(organizations => keyBy(organizations, OrganizationsService.primaryKey)),
      tap(entities => {
        dispatch(
          new PatchStreamedOrganizations({
            isInitialized: true,
            entities,
          })
        );

        // A new user does not belong to any organization for a moment
        if (Object.keys(entities).length === 0) {
          console.log('User does not belong to any hub');
        }

        // If an organization was selected (via the URL) but the user does not belong to that organization, or if no organization is selected, then select
        // the first organization the user belongs to or the user's default organization
        const {selectedId} = getState();

        const user: UserModel = this.store.snapshot().auth.user;

        const selectedNotMyOrganization = selectedId && !Object.keys(entities).includes(selectedId);
        const notSelectedOrganization = !selectedId && entities && Object.keys(entities).length > 0;

        if (selectedNotMyOrganization && user.isAdmin) {
          dispatch(new SelectOrganization(selectedId));
        } else if (selectedNotMyOrganization || notSelectedOrganization) {
          let organizationId = getFirstKey(entities);

          if (
            user &&
            user.settings &&
            user.settings.defaultOrganization &&
            user.settings.defaultOrganization in entities
          ) {
            organizationId = user.settings.defaultOrganization;
          }

          if (organizationId) {
            // console.log('Select organization in StreamOrganizations:', organizationId);
            dispatch(new SelectOrganization(organizationId));
          }
        }
      }),
      catchError(e => dispatch(new DisplayError(e)))
    );
  }

  /**
   * Stream the direct organization
   */
  @Action(StreamOrganizationDirect)
  streamDirect({dispatch, patchState}: StateContext<OrganizationsStateModel>, {id}: StreamOrganizationDirect) {
    return this.service.getOrganizationDirect(id).pipe(
      tap(organization => patchState({entityDirect: organization})),
      catchError(e => dispatch(new DisplayError(e))),
      takeUntil(OrganizationsState.organizationSelectedChanged)
    );
  }

  /**
   * Stream organization API tokens
   */
  @Action(StreamApiTokens)
  streamApiTokens({dispatch, patchState}: StateContext<OrganizationsStateModel>) {
    return this.organization$.pipe(
      map((organization: OrganizationModel) => (organization ? organization.id : null)),
      distinctUntilChanged(),
      tap(() => patchState({tokens: undefined})),
      filter(Boolean),
      switchMap((organizationId: string) => this.service.getApiTokens(organizationId)),
      tap(tokens => {
        patchState({tokens});
      }),
      catchError(e => dispatch(new DisplayError(e)))
    );
  }

  @Action(CreateApiToken)
  async createApiToken({dispatch}: StateContext<OrganizationsStateModel>, {organizationId, apiToken}: CreateApiToken) {
    try {
      return await this.service.createApiToken(organizationId, apiToken);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(DeleteApiToken)
  async deleteApiToken({dispatch}: StateContext<OrganizationsStateModel>, {tokenId}: DeleteApiToken) {
    try {
      return await this.service.deleteApiToken(tokenId);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * Remove the direct organization
   */
  @Action(RemoveOrganizationDirect)
  removeOrganizationDirect({dispatch, patchState}: StateContext<OrganizationsStateModel>) {
    patchState({entityDirect: undefined});
  }

  /**
   * Get organization lookup
   */
  @Action(FetchOrganizationsLookup)
  async fetchOrganizationsLookup(
    {getState, dispatch, patchState}: StateContext<OrganizationsStateModel>,
    {group, isCached}: FetchOrganizationsLookup
  ) {
    const lookup = getState().entityLookup;

    try {
      const list = await this.service.getListLookup(group, isCached);
      patchState({entityLookup: list});
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }
  }

  /**
   * Select the current working organization
   * @param patchState - patch state
   * @param selectedId - selected organization id
   */
  @Action(SelectOrganization)
  select({getState, patchState, dispatch}: StateContext<OrganizationsStateModel>, {selectedId}: SelectOrganization) {
    const {entities, entityDirect} = getState();
    // console.log('SelectOrganization:', selectedId, entities, entityDirect ? entityDirect.id : null);

    // The user's organizations may not have been streamed yet
    // If they organizations have not streamed yet, then set the the selectedId now and handle the case where user does not belong to that organization during streaming
    if (!entities || (entities && Object.keys(entities).includes(selectedId))) {
      OrganizationsState.organizationSelectedChanged.next();

      // Get database stats for the organization overview
      dispatch(new GetOverviewStats(selectedId));

      return patchState({
        selectedId,
        billingUrl: undefined,
        invoices: initialState.invoices,
        transactions: initialState.transactions,
        tokens: initialState.tokens,
        stats: initialState.stats,
        usageHistoric: initialState.usageHistoric,
        zohoActivities: initialState.zohoActivities,
        zohoRequests: initialState.zohoRequests,
      });
    } else {
      // Selected an organization directly by an administrator
      const user = this.store.snapshot().auth.user;

      if (user && user.isAdmin && (!entityDirect || entityDirect.id !== selectedId)) {
        console.log('SelectOrganization direct, get entity:', selectedId);
        OrganizationsState.organizationSelectedChanged.next();
        dispatch(new StreamOrganizationDirect(selectedId));

        // Get database stats for the organization overview
        dispatch(new GetOverviewStats(selectedId));

        // Do not set selectedId becuase it is not a key of entities
        return patchState({
          billingUrl: undefined,
          invoices: initialState.invoices,
          transactions: initialState.transactions,
          tokens: initialState.tokens,
          stats: initialState.stats,
          usageHistoric: initialState.usageHistoric,
          zohoActivities: initialState.zohoActivities,
          zohoRequests: initialState.zohoRequests,
        });
      } else {
        return null;
      }
    }
  }

  /**
   * Add / Change the name of the current working organization.
   * @param getState - get current state
   * @param name - the desired organization name
   */
  @Action(SetOrganizationName)
  async setName({getState, dispatch}: StateContext<OrganizationsStateModel>, {name}: SetOrganizationName) {
    try {
      return await this.service.setName(getState().selectedId, name);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * TODO docs
   */
  @Action(InviteOrganizationMember)
  async inviteMember(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId, email, role}: InviteOrganizationMember
  ) {
    let invite;
    try {
      invite = await this.service.inviteMember(organizationId, email, role);
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }

    return of(invite);
  }

  /**
   * TODO docs
   */
  @Action(FetchOrganizationBillingUrl)
  async fetchBillingUrl(
    {getState, patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId}: FetchOrganizationBillingUrl
  ) {
    try {
      const {url} = await this.service.getBillingUrl(organizationId);
      patchState({billingUrl: url});
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }
  }

  /**
   * Remove member from current organization
   */
  @Action(RemoveOrganizationMember)
  async removeMember(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {uid, organizationId}: RemoveOrganizationMember
  ) {
    try {
      return of(await this.service.removeMember(organizationId, uid));
    } catch (e) {
      return dispatch(new DisplayError(e));
    }
  }

  /**
   * Add member to organization
   */
  @Action(AddOrganizationMember)
  async addMember(
    {dispatch}: StateContext<OrganizationsStateModel>,
    {uid, organizationId, role}: AddOrganizationMember
  ) {
    try {
      return of(await this.service.addMember(organizationId, uid, role));
    } catch (e) {
      return dispatch(new DisplayError(e));
    }
  }

  @Action(ChangeOrganizationMemberRole)
  async changeMemberRole(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {uid, role, organizationId}: ChangeOrganizationMemberRole
  ) {
    try {
      return await this.service.changeRole(organizationId, uid, role);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(ChangeOrganizationBillingCode)
  async changeOrganizationBillingCode(
    {getState, patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {planCode}: ChangeOrganizationBillingCode
  ) {
    // Get price
    const plans = this.store.snapshot().app.pricing.plans;
    let plan = plans.find(p => p.monthlyCode === planCode);
    let price;
    if (!plan) {
      plan = plans.find(p => p.yearlyCode === planCode);
      if (plan) {
        price = plan.yearlyPrice;
      }
    } else {
      price = plan.monthlyPrice;
    }

    // Immediately update price for interface
    const updatePrice = () => {
      // const {selectedId, entities} = getState();
      // const organization = entities[selectedId];
      // organization.billing.nextBilling.amount = price;// TODO its read only
      // patchState({entities});
    };
    updatePrice();

    try {
      const promise = await this.service.changeBillingCode(getState().selectedId, planCode);
      updatePrice();
      return promise;
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(ChangePlanAndExtendTrial)
  async changePlanAndExtendTrial(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {planCode}: ChangePlanAndExtendTrial
  ) {
    try {
      return await this.service.changePlanAndExtendTrial(getState().selectedId, planCode);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(UpdateOrganizationBillingDetails)
  async updateOrganizationBillingDetails(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {details}: UpdateOrganizationBillingDetails
  ) {
    try {
      return await this.service.updateBillingDetails(getState().selectedId, details);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * Patch the current state
   * @param patchState of the current state
   * @param payload of the streamed state
   */
  @Action(PatchStreamedOrganizations)
  patch({patchState}: StateContext<OrganizationsStateModel>, {payload}: PatchStreamedOrganizations) {
    return patchState(payload);
  }

  /**
   * Remove account from provider
   */
  @Action(RemoveProviderAccount)
  async removeConnection(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {accountId, type, name}: RemoveProviderAccount
  ) {
    const organization = OrganizationsState.selected(getState());

    try {
      await this.service.removeConnection(organization.id, accountId, type, name);
      return of(true);
    } catch (e) {
      return dispatch(new DisplayError(e));
    }
  }

  /**
   * List subscription invoices if didn't already
   */
  @Action(ListOrganizationInvoices)
  async listOrganizationInvoices({getState, patchState, dispatch}: StateContext<OrganizationsStateModel>) {
    const {invoices, selectedId} = getState();
    if (invoices.list) {
      return;
    }

    try {
      const list = await this.service.getInvoices(selectedId);
      patchState({invoices: {...invoices, list}});
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * Get subscription invoice
   */
  @Action(GetOrganizationInvoice)
  async getOrganizationInvoice(
    {getState, patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {invoiceId}: GetOrganizationInvoice
  ) {
    const {selectedId, invoices} = getState();
    patchState({invoices: {...invoices, isLoadingInvoice: true, currentInvoiceUrl: undefined}});

    try {
      const currentInvoice = await this.service.getInvoiceUrl(selectedId, invoiceId);
      patchState({invoices: {...invoices, isLoadingInvoice: false, currentInvoiceUrl: currentInvoice}});
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * List customer transactions
   */
  @Action(ListCustomerTransactions)
  async listOrganizationTransactions(
    {getState, patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId}: ListCustomerTransactions
  ) {
    const {transactions} = getState();

    patchState({transactions: {...transactions, currentInvoiceUrl: undefined}});
    if (transactions.list) {
      return;
    }

    try {
      const list = await this.service.getCustomerTransactions(organizationId);
      patchState({transactions: {...transactions, list}});
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * Get customer transaction
   */
  @Action(GetCustomerTransaction)
  async getCustomerTransaction(
    {getState, patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId, transactionId, transactionType}: GetCustomerTransaction
  ) {
    const {transactions} = getState();
    patchState({transactions: {...transactions, isLoadingInvoice: true, currentInvoiceUrl: undefined}});

    try {
      const currentInvoice = await this.service.getTransactionUrl(organizationId, transactionId, transactionType);
      const state = getState(); // Get updated state since the list of transactions may have been patched in the meantime (when a URL directly to a transaction is used)
      patchState({
        transactions: {
          list: state.transactions.list,
          isLoadingInvoice: false,
          currentInvoiceUrl: currentInvoice,
        },
      });
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * List subscription activities
   */
  @Action(ListSubscriptionActivities)
  async listSubscriptionActivities(
    {patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId}: ListSubscriptionActivities
  ) {
    patchState({zohoActivities: undefined});

    try {
      const list = await this.service.getSubscriptionActivities(organizationId);
      patchState({zohoActivities: list});
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * List subscription Zoho requests
   */
  @Action(ListSubscriptionZohoRequests)
  async listSubscriptionZohoRequests(
    {patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId}: ListSubscriptionZohoRequests
  ) {
    patchState({zohoRequests: undefined});

    try {
      const list = await this.service.getSubscriptionZohoRequests(organizationId);
      patchState({zohoRequests: list});
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * Cancel subscription for current organization
   */
  @Action(CancelPlan)
  async cancelPlan({getState, dispatch}: StateContext<OrganizationsStateModel>, {organizationId}) {
    const {selectedId} = getState();

    let cancellation;
    try {
      if (organizationId) {
        // When called from the profile page (outside the organization route)
        cancellation = await this.service.cancelPlan(organizationId);
      } else {
        // When called from the organization members page
        cancellation = await this.service.cancelPlan(selectedId);
      }
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }

    return of(cancellation);
  }

  /**
   * Pause toggle subscription
   */
  @Action(PauseTogglePlan)
  async pauseTogglePlan({getState, dispatch}: StateContext<OrganizationsStateModel>) {
    const {selectedId} = getState();

    let pause;
    try {
      pause = await this.service.pauseTogglePlan(selectedId);
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }

    return of(pause);
  }

  /**
   * Remove existing payment method to allow switch from paypal to card and vice versa
   */
  @Action(RemovePaymentMethod)
  async removePaymentMethod({getState, dispatch}: StateContext<OrganizationsStateModel>) {
    const {selectedId} = getState();

    let payment;
    try {
      payment = await this.service.removePaymentMethod(selectedId);
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }

    return of(payment);
  }

  /**
   * Collect invoices
   */
  @Action(CollectInvoices)
  async collectInvoices({getState, dispatch}: StateContext<OrganizationsStateModel>) {
    const {selectedId} = getState();

    let collect;
    try {
      collect = await this.service.collectInvoices(selectedId);
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }

    return of(collect);
  }

  /**
   * Reactivate a cancelled subscription
   */
  @Action(ReactivateSubscription)
  async reactivateSubscription(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId}: ReactivateSubscription
  ) {
    const {selectedId} = getState();

    let reactivate;
    try {
      if (organizationId) {
        // When called from the profile page (outside the organization route)
        reactivate = await this.service.reactivateSubscription(organizationId);
      } else {
        reactivate = await this.service.reactivateSubscription(selectedId);
      }
    } catch (e) {
      dispatch(new DisplayError(this.httpErrorsService.translateError(e)));
    }

    return of(reactivate);
  }

  /**
   * Remove usage for given path
   */
  @Action(ModifyAddonQuantity)
  async modifyAddon(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {addon, quantity}: ModifyAddonQuantity
  ) {
    const {selectedId, entities} = getState();
    const addons = entities[selectedId].billing.addons;

    const newQuantity = addons && addons[addon] ? addons[addon] + quantity : quantity;

    try {
      await this.service.modifyAddon(selectedId, addon, newQuantity > 0 ? newQuantity : 0);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(SetTrackerGALatest)
  async setTrackerGALatest(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {clientId}: SetTrackerGALatest
  ) {
    const {selectedId} = getState();

    if (!selectedId) {
      return;
    }

    try {
      await this.service.setTracker(selectedId, 'ga', 'latestClientId', clientId);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(SetTrackerGAInitial)
  async setTrackerGAInitial(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {clientId}: SetTrackerGAInitial
  ) {
    const {selectedId} = getState();

    if (!selectedId) {
      return;
    }

    try {
      await this.service.setTracker(selectedId, 'ga', 'initialClientId', clientId);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(UpdateSQLDatabaseDetails)
  async updateSQLDatabase(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {dbDetails}: UpdateSQLDatabaseDetails
  ) {
    const {selectedId} = getState();

    dispatch(StartLoading);
    let update;
    try {
      update = await this.service.updateSQLDatabase(selectedId, dbDetails);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
    dispatch(StopLoading);

    return of(update);
  }

  @Action(RemoveSQLDatabase)
  async removeSQLDatabase({getState, dispatch}: StateContext<OrganizationsStateModel>) {
    const {selectedId} = getState();

    let removal;
    try {
      removal = await this.service.removeSQLDatabase(selectedId);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
    return of(removal);
  }

  @Action(UpdateUsage)
  async updateAccountUsage({getState, dispatch}: StateContext<OrganizationsStateModel>, {usage}: UpdateUsage) {
    const {selectedId} = getState();

    let update;
    try {
      update = await this.service.updateUsage(selectedId, usage);
    } catch (e) {
      dispatch(new DisplayError(e));
    }

    return of(update);
  }

  @Action(RemoveUsage)
  async removeAccountUsage({getState, dispatch}: StateContext<OrganizationsStateModel>, {usage}: RemoveUsage) {
    const {selectedId} = getState();

    let update;
    try {
      update = await this.service.removeUsage(selectedId, usage);
    } catch (e) {
      dispatch(new DisplayError(e));
    }

    return of(update);
  }

  @Action(SetIntegrationStatus)
  async setIntegrationStatus(
    {getState, dispatch}: StateContext<OrganizationsStateModel>,
    {type, status, isStartIntegration}
  ) {
    const {selectedId} = getState();

    try {
      await this.service.setIntegrationStatus(selectedId, type, status, isStartIntegration);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(SetAccountTimezone)
  async setAccountTimezone({dispatch}: StateContext<OrganizationsStateModel>, {accountId, timezone}) {
    try {
      await this.accountsService.setTimezone(accountId, timezone);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(GetOverviewStats)
  async getOverviewStats({dispatch, patchState}: StateContext<OrganizationsStateModel>, {organizationId}) {
    patchState({stats: {jobsCount: 0, databaseSize: 0}});

    try {
      const stats = await this.service.getOverviewStats(organizationId);
      patchState({stats});
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  @Action(GetUsageHistoric)
  async getHistoricUsage(
    {getState, patchState, dispatch}: StateContext<OrganizationsStateModel>,
    {organizationId, date}: GetUsageHistoric
  ) {
    patchState({usageHistoric: undefined});

    try {
      const usageHistoric = await this.service.getHistoricUsage(organizationId, date);
      patchState({usageHistoric});
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }
}
