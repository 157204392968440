export const environment = {
  production: true,
  apiEndpoint: 'https://us-central1-pma-staging-384213.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyAapnQ_YLsghd84olkiJo9SYlF4nO68qyI',
    authDomain: 'pma-staging-384213.firebaseapp.com',
    databaseURL: 'https://pma-staging-384213-default-rtdb.firebaseio.com',
    projectId: 'pma-staging-384213',
    storageBucket: 'pma-staging-384213.appspot.com',
    messagingSenderId: '468576402184',
    appId: '1:468576402184:web:cd1fbc29761f9856ae8abd',
  },
};
