// Website navigation

export interface NavItem {
  titleKey: string;
  textKey: string;
  route: string;
  iconImage?: string;
  icon?: string;
  symbol?: string;
  image: string;
  isAdmin?: boolean;
  isEnterprise?: boolean;
}

export interface NavItemGroup {
  groupTitleKey: string;
  groupIcon?: string;
  groupIconImage?: string;
  groupRoute: string;
  groupRouteNav: string;
  groupRouteIsOrganization: boolean;
  groupItems: NavItem[];
  isAdmin?: boolean;
}

export const menuGroups: NavItemGroup[] = [
  {
    groupTitleKey: 'overview',
    // groupIcon: 'dashboard',
    groupIconImage: 'icon_overview.png',
    groupRoute: 'overview',
    groupRouteNav: '',
    groupRouteIsOrganization: true,
    groupItems: [],
  },
  {
    groupTitleKey: 'reports',
    // groupIcon: 'analytics',
    groupIconImage: 'icon_reports.png',
    groupRoute: 'integrations',
    groupRouteNav: 'reports',
    groupRouteIsOrganization: true,
    groupItems: [
      {
        titleKey: 'reports.lookerStudio',
        route: 'google-looker-studio',
        iconImage: 'google-looker-studio.svg',
        image: 'header-data-studio.svg',
        textKey: 'reportsLookerStudio',
      },
      {
        titleKey: 'reports.googleSheets',
        route: 'google-sheets',
        iconImage: 'google-sheets.png',
        image: 'header-google-sheets.svg',
        textKey: 'reportsGoogleSheets',
      },
      {
        titleKey: 'reports.excel',
        route: 'excel',
        iconImage: 'excel.png',
        image: 'header-excel.svg',
        textKey: 'reportsExcel',
      },
      {
        titleKey: 'reports.dataExplorer',
        route: 'explorer',
        iconImage: '',
        icon: 'explore',
        image: 'header-data-explorer.svg',
        textKey: 'dataExplorer',
      },
    ],
  },
  {
    groupTitleKey: 'sources',
    // groupIcon: 'layers',
    groupIconImage: 'icon_sources.png',
    groupRoute: 'sources',
    groupRouteNav: '',
    groupRouteIsOrganization: true,
    groupItems: [],
  },
  {
    groupTitleKey: 'exports',
    // groupIcon: 'exit_to_app',
    groupIconImage: 'icon_exports.png',
    groupRoute: 'integrations',
    groupRouteNav: 'exports',
    groupRouteIsOrganization: true,
    groupItems: [
      {
        titleKey: 'exports.googleAnalytics',
        route: 'google-analytics',
        iconImage: 'google-analytics.png',
        image: 'header-google-analytics.svg',
        textKey: 'googleAnalytics',
      },
      {
        titleKey: 'exports.sql',
        route: 'sql',
        symbol: 'database',
        image: 'header-sql-warehouse.svg',
        textKey: 'sql',
        isEnterprise: true,
      },
      {
        titleKey: 'exports.bigQuery',
        route: 'exports',
        iconImage: 'bigquery.svg',
        image: 'header-big-query.svg',
        textKey: 'bigQuery',
        isEnterprise: true,
      },
      {
        titleKey: 'exports.ftp',
        route: 'exports',
        iconImage: 'ftp.png',
        image: 'header-ftp.svg',
        textKey: 'ftp',
        isEnterprise: true,
      },
      {
        titleKey: 'exports.mysql',
        route: 'exports',
        symbol: 'database',
        image: 'header-mysql.svg',
        textKey: 'mysql',
        isEnterprise: true,
      },
      {
        titleKey: 'exports.api',
        route: 'api',
        icon: 'api',
        image: 'header-api.svg',
        textKey: 'api',
        isEnterprise: true,
      },
    ],
  },
  {
    groupTitleKey: 'tools',
    groupIcon: 'construction',
    // groupIconImage: 'icon_tools.png',
    groupRoute: 'resources',
    groupRouteNav: 'tools',
    groupRouteIsOrganization: false,
    groupItems: [
      {
        titleKey: 'tools.templates',
        route: 'templates',
        icon: 'analytics',
        image: 'header-templates.svg',
        textKey: 'templates',
      },
      {
        titleKey: 'tools.schemaExplorer',
        route: 'schema',
        icon: 'schema',
        image: 'header-schema-explorer.svg',
        textKey: 'schema',
      },
      {
        titleKey: 'tools.plans',
        route: 'plans',
        icon: 'credit_card',
        image: '',
        textKey: '',
      },
      {
        titleKey: 'tools.downtimeMonitor',
        route: 'status',
        icon: 'health_and_safety',
        image: 'header-downtime.svg',
        textKey: 'downtime',
        isAdmin: true,
      },
      {
        titleKey: 'tools.emails',
        route: 'emails',
        icon: 'email',
        image: '',
        textKey: '',
        isAdmin: true,
      },
    ],
  },
];
