import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {CoreModule} from '@core/core.module';
import {SharedModule} from '@shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './components/footer/footer.component';
import {AuthNavItemsComponent} from './components/nav/auth-nav-items/auth-nav-items.component';
import {NavComponent} from './components/nav/nav.component';
import {NotificationsComponent} from './components/nav/notifications/notifications.component';
import {BannerComponent} from './components/banner/banner.component';
import {StaticNavItemsComponent} from './components/nav/static-nav-items/static-nav-items.component';
import {LogsDialogComponent} from './components/logs-dialog/logs-dialog.component';
import {ProgressComponent} from './components/progress/progress.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {NavSideComponent} from './components/nav-side/nav-side.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {SamlComponent} from './components/saml/saml.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    AuthNavItemsComponent,
    StaticNavItemsComponent,
    NotificationsComponent,
    BannerComponent,
    LogsDialogComponent,
    ProgressComponent,
    NavSideComponent,
    SamlComponent,
  ],
  imports: [
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    SharedModule,
    CoreModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    FormsModule,
  ],
  exports: [AuthNavItemsComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
