import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {
  GenericNotificationDocument,
  NotificationAction,
  NotificationDocument,
  NotificationIcon,
  NotificationModel,
  NotificationType,
} from '@pma/shared/types/notification';

@Pipe({
  name: 'notification',
})
export class NotificationPipe extends DocumentPipe<NotificationDocument, NotificationModel> implements PipeTransform {
  transform(doc: NotificationDocument): NotificationModel {
    if ('organization' in doc) {
      delete doc.organization;
    }
    if ('user' in doc) {
      delete doc.user;
    }
    delete doc.to;

    const transformed = super.transform(doc);

    transformed.actions = {
      [NotificationType.Invitation]: [
        {value: NotificationAction.Decline},
        {value: NotificationAction.Accept, primary: true},
      ],
      [NotificationType.AccountTokenExpired]: [{value: NotificationAction.View, primary: true}],
      [NotificationType.SqlConnectionInvalid]: [{value: NotificationAction.View, primary: true}],
    }[doc.type];

    // Get notification icon
    transformed.icon = this.getNotificationIcon(doc);

    // Handle notifications with custom actions
    if (doc.type === NotificationType.Generic && doc.subType) {
      transformed.icon = this.getNotificationCustomIcon(doc.subType);
      transformed.actions = this.getNotificationCustomActions(doc);
    }

    return transformed;
  }

  getNotificationCustomActions(notification: GenericNotificationDocument): any[] {
    // Get actions for:
    // 1. Custom notifications identified by their subType, where the custom actions are defined here
    // 2. Custom notifications with subType = 'custom', where an external link URL is saved in the database

    switch (notification.subType) {
      case 'trialStarted':
        return [
          {
            value: NotificationAction.View,
            primary: true,
            actionCustom: {
              type: 'url',
              url: 'support.powermyanalytics.com/portal/en/kb/articles/getting-started',
              label: 'Getting Started',
            },
          },
          {
            value: NotificationAction.View,
            primary: true,
            actionCustom: {
              type: 'url',
              url: 'calendly.com/power-my-analytics/onboarding',
              label: 'Free Onboarding',
            },
          },
        ];

      case 'trialEnding':
        return [
          {
            value: NotificationAction.View,
            primary: true,
            actionCustom: {
              type: 'url',
              url: 'forms.powermyanalytics.com/powermyanalytics/form/TrialExtensionRequest/formperma/ulEeg0Pty6i5ZAU3MlFmiq_tVs1NpDZqVoEpZELWGtU',
              label: 'Trial Extension Request',
            },
          },
        ];

      case 'trialEnded':
        return [
          {
            value: NotificationAction.View,
            primary: true,
            actionCustom: {
              type: 'route',
              route: ['organizations', 'billing', 'details'],
              label: 'Add Payment Method',
            },
          },
        ];

      case 'planYearly':
        return [
          {
            value: NotificationAction.View,
            primary: true,
            actionCustom: {
              type: 'route',
              route: ['organizations', 'billing', 'plans', 'all'],
              label: 'Plan Manager',
            },
          },
        ];

      case 'enterpriseRequired':
        return [
          {
            value: NotificationAction.View,
            primary: true,
            actionCustom: {
              type: 'route',
              route: ['organizations', 'billing', 'plans', 'all'],
              label: 'Upgrade Now',
            },
          },
        ];

      case 'custom':
        return [
          {
            value: NotificationAction.View,
            primary: true,
            actionCustom: {
              type: 'url',
              url: notification.linkUrl,
              label: notification.linkTitle,
            },
          },
        ];
    }
  }

  getNotificationIcon(notification: NotificationDocument): NotificationIcon {
    switch (notification.type) {
      case 'invitation':
        return 'membership';
      case 'removal':
        return 'membership';
      case 'accountTokenExpired':
        return 'error';
      case 'sqlConnectionInvalid':
        return 'error';
      case 'lookerStudioTemplateError':
        return 'error';
      case 'generic':
        if (notification.message && notification.message.indexOf('declined') > -1) {
          // "payment has been declined"
          return 'error';
        } else if (notification.message && notification.message.indexOf('has exceeded') > -1) {
          // "organization has exceeded one or more plan limits"
          return 'warning';
        }
        return 'success';

      default:
        return 'success';
    }
  }

  getNotificationCustomIcon(subType: string): NotificationIcon {
    switch (subType) {
      case 'trialStarted':
        return 'success';
      case 'trialEnding':
        return 'warning';
      case 'trialEnded':
        return 'error';
      case 'planYearly':
        return 'announcement';
      default:
        return 'announcement';
    }
  }
}
