import {Component, Input, OnInit} from '@angular/core';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {Select} from '@ngxs/store';
import {OrganizationModel} from '@pma/shared/types/organization';
import {OrganizationsState} from '@store/organizations/organizations.state';
import {UserModel} from '@pma/shared/types/user';
import {menuGroups, NavItemGroup} from '@shared/utils/navigation';

@Component({
  selector: 'app-nav-side',
  templateUrl: './nav-side.component.html',
  styleUrls: ['./nav-side.component.scss'],
})
export class NavSideComponent implements OnInit {
  @Select(OrganizationsState.selected) organization$: Observable<OrganizationModel>;
  @Select(state => state.auth.user) user$: Observable<UserModel>;

  @Input() params: Observable<Params> | undefined;

  organizationId: string;
  isAdmin = false;
  isPlanEnterprise = false;
  menuGroups: NavItemGroup[] = [];

  socials: Array<{icon: string; href: string}> = [
    {icon: 'linkedin-in', href: 'https://www.linkedin.com/company/power-my-analytics/'},
    {icon: 'twitter', href: 'https://twitter.com/ShowMeROI'},
    {icon: 'facebook-f', href: 'https://www.facebook.com/PowerMyAnalytics/'},
  ];

  constructor() {
    this.menuGroups = menuGroups;
  }

  ngOnInit(): void {
    this.organization$
      .pipe(
        filter<OrganizationModel>(Boolean),
        tap((organization: OrganizationModel) => {
          this.organizationId = organization.id;
          this.isPlanEnterprise = organization.billing.planCode.indexOf('ENT') > -1;
        })
      )
      .subscribe();

    this.user$
      .pipe(
        filter(Boolean),
        map((user: UserModel) => Boolean(user.isAdmin)),
        tap(isAdmin => (this.isAdmin = isAdmin))
      )
      .subscribe();
  }

  getRoute(menuGroup: any, groupItem: any): string[] {
    if (menuGroup.groupRouteIsOrganization) {
      if (groupItem.route === 'exports') {
        // Handle exports routes explicitly because they include an extra level in the route
        if (groupItem.textKey === 'bigQuery') {
          return ['organizations', this.organizationId, menuGroup.groupRoute, groupItem.route, 'google_bigquery'];
        } else if (groupItem.textKey === 'ftp') {
          return ['organizations', this.organizationId, menuGroup.groupRoute, groupItem.route, 'ftp'];
        } else if (groupItem.textKey === 'mysql') {
          return ['organizations', this.organizationId, menuGroup.groupRoute, groupItem.route, 'mysql'];
        }
      } else {
        return ['organizations', this.organizationId, menuGroup.groupRoute, groupItem.route];
      }
    } else {
      return [menuGroup.groupRoute, groupItem.route];
    }
  }
}
