<div *transloco="let t; read: 'organizations.organization.usage.history.gaps'">
  <ng-container *ngIf="data.length == 0; else gaps">
    <p>{{ t('noBackfillData') }}</p>
  </ng-container>

  <ng-template #gaps>
    <form [formGroup]="form">
      <div class="backfill-types-header backfill-types">
        <span>{{ t('backfillType') }}</span>
      </div>

      <div id="chart">
        <div id="chart-top">
          <!-- Job types -->
          <div class="backfill-types">
            <div *ngFor="let type of availableTypesReadable" class="backfill-type">
              <span>{{ type }}:</span>
            </div>
          </div>

          <!-- Job type gaps -->
          <div id="scrollable" dir="rtl">
            <div id="chart-body" [style]="chartSize">
              <div
                *ngFor="let datum of displayData"
                class="job"
                [style]="datum.style"
                dir="ltr"
                [matTooltip]="datum.tooltip"
                [matTooltipClass]="'explanation-tooltip-break-newline'"></div>
            </div>
          </div>
        </div>

        <!-- X scale scrollbar -->
        <div id="x-scale" class="flex-row-between flex-row-align-items-center">
          <mat-icon fontSet="material-icons-outlined" class="color-accent">zoom_out</mat-icon>
          <mat-slider min="0.05" max=".95" step="0.1" formControlName="xScale" id="x-slider"
            ><input matSliderThumb
          /></mat-slider>
          <mat-icon fontSet="material-icons-outlined" class="color-accent">zoom_in</mat-icon>
        </div>
      </div>
    </form>
  </ng-template>
</div>
