import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';
import {flatten} from '@pma/shared/utils/flatten/flatten';
import {Dictionary} from '@pma/shared/interfaces/dictionary.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '@shared/components/base/base.component';
import {
  AccountTokenExpiredNotificationModel,
  NotificationAction,
  NotificationModel,
  NotificationType,
} from '@pma/shared/types/notification';
import {OrganizationsState} from '@core/store/organizations/organizations.state';
import {OrganizationModel} from '@pma/shared/types/organization';
import {ProvidersStateModel} from '@core/store/providers/providers.state';
import {PatchNotificationStatus} from '@core/store/notifications/notifications.actions';
import {NotificationsStateModel} from '@core/store/notifications/notifications.state';
import {isEqual} from 'lodash';
import {ConfirmDialogComponent} from '@shared/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent extends BaseComponent implements OnInit {
  @Select(OrganizationsState.selected) organization$: Observable<OrganizationModel>;
  @Select(state => state.connectorsProviders) providersState$: Observable<ProvidersStateModel>;
  @Select(state => state.notifications) notificationsState$: Observable<NotificationsStateModel>;
  banner$: Observable<any>;

  rawNotifications: NotificationModel[] = [];
  bannerNotifications: NotificationModel[] = [];
  bannerNotification: NotificationModel;
  bannerNotificationIndex = 0;

  translationPathRoot = 'app.nav.auth.notifications.';
  didPromptAccountDisconnected = false;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private transloco: TranslocoService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    // Get banner notifications
    // NOTE: Providers state is neeed to add provider title to some notifications
    this.banner$ = combineLatest([this.notificationsState$, this.providersState$, this.organization$]).pipe(
      filter(
        ([notificationState, providersState, organization]: [
          NotificationsStateModel,
          ProvidersStateModel,
          OrganizationModel
        ]) =>
          Boolean(
            notificationState &&
              notificationState.isInitializedBanner &&
              providersState &&
              providersState.isInitialized &&
              organization
          )
      ),
      distinctUntilChanged(
        (
          a: [NotificationsStateModel, ProvidersStateModel, OrganizationModel],
          b: [NotificationsStateModel, ProvidersStateModel, OrganizationModel]
        ) => this.isBannerNotificationsChanged(a[0], b[0])
      ),
      tap(
        ([notificationState, providersState, organization]: [
          NotificationsStateModel,
          ProvidersStateModel,
          OrganizationModel
        ]) => {
          this.rawNotifications = notificationState.collectionBanner;
          // console.log('Banner notifications raw:', this.rawNotifications);

          // Get unique notifications for the banner which are not viewed (dismissed)
          this.bannerNotifications = [];
          this.bannerNotification = null;
          const accountsExpired = new Set<string>();

          this.rawNotifications.forEach(notification => {
            // Exclude viewed (dismissed) notifications
            if (notification.status && notification.status === 'view') {
              return;
            }

            // Include only most recent instance of each AccountTokenExpired notification
            if (
              notification.type === NotificationType.AccountTokenExpired &&
              !accountsExpired.has(notification.accountId)
            ) {
              accountsExpired.add(notification.accountId);
            } else if (notification.type === NotificationType.SqlConnectionInvalid) {
              // skip if connection is valid
              if (organization.sqlDatabase?.status?.isValid) {
                return;
              }
            } else {
              return;
            }

            // Add action to dismiss notification
            const notificationAmended = Object.assign({}, notification);
            notificationAmended.actions = Array.from(notification.actions ?? []);
            notificationAmended.actions.push({value: NotificationAction.ViewAndDismiss, primary: false});

            // Add missing provider title to AccountTokenExpired notification
            if (
              notification.type === NotificationType.AccountTokenExpired &&
              typeof notification.provider === 'string'
            ) {
              const providerType = notification.provider as string;
              const provider = providersState.collection.find(p => p.type === providerType);

              (notificationAmended as AccountTokenExpiredNotificationModel).provider = {
                type: notification.provider,
                title: provider ? provider.title : notification.provider,
              };
            }

            this.bannerNotifications.push(notificationAmended);
          });
          // console.log('Banner notifications prepared:', this.bannerNotifications);

          // Show first notification
          if (this.bannerNotifications.length > 0) {
            this.bannerNotificationIndex = 0;
            this.bannerNotification = this.bannerNotifications[this.bannerNotificationIndex];

            // Prompt user to address disconnected account
            const notificationPrompt = this.bannerNotifications.find(
              n => n.type === NotificationType.AccountTokenExpired
            );
            if (notificationPrompt && !this.didPromptAccountDisconnected) {
              this.didPromptAccountDisconnected = true;

              // Don't prompt when logging into sheets
              if (!this.route.snapshot.queryParams.sheets) {
                this.promptAccountDisconnected(notificationPrompt);
              }
            }
          }
        }
      )
    );
  }

  isBannerNotificationsChanged(a: NotificationsStateModel, b: NotificationsStateModel): boolean {
    return isEqual(a.collectionBanner, b.collectionBanner);
  }

  promptAccountDisconnected(notification: NotificationModel) {
    const actions = [
      {
        primary: false,
        value: 'reconnect',
        callback: () => {
          dialogRef.close();
          this.dismissNotification(notification);
          this.viewNotificationAccountDisconnected(notification as AccountTokenExpiredNotificationModel, true);
        },
      },
      {
        primary: true,
        value: 'dismiss',
        callback: () => {
          dialogRef.close();
          this.dismissNotification(notification);
        },
      },
    ];

    const title = this.transloco.translate(this.translationPathRoot + notification.type + '.title');
    const content = this.transloco.translate(
      this.translationPathRoot + notification.type + '.contents',
      flatten(notification)
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: {title, content, actions}});
  }

  async dispatch(notification: NotificationModel, action: NotificationAction) {
    if (action === NotificationAction.ViewAndDismiss) {
      this.dismissNotification(notification);
    }

    if (action === NotificationAction.View) {
      if (notification.type === NotificationType.AccountTokenExpired) {
        this.viewNotificationAccountDisconnected(notification, false);
      } else if (notification.type === NotificationType.SqlConnectionInvalid) {
        this.viewNotificationSqlInvalid();
      }
    }
  }

  dismissNotification(notification: NotificationModel) {
    // Dimiss notification by setting it (and all similar notifications) as viewed
    this.viewNotificationsSimilar(notification);
  }

  async viewNotificationAccountDisconnected(notification: AccountTokenExpiredNotificationModel, isReconnect: boolean) {
    // Navigate to Sources page and highlight the account
    const organizationId = this.store.snapshot().organizations.selectedId;

    await this.router.navigate(['organizations', organizationId, 'sources'], {
      queryParams: {
        notification: notification.name,
        accountId: notification.accountId ? notification.accountId : null,
        reconnect: isReconnect ? 'yes' : 'no',
        id: Math.floor(Math.random() * 10000), // Include unique query param value in order to refresh the page
      },
    });
  }

  async viewNotificationSqlInvalid() {
    // Navigate to SQL integration page
    const organizationId = this.store.snapshot().organizations.selectedId;
    await this.router.navigate(['organizations', organizationId, 'integrations', 'sql']);
  }

  viewNotificationsSimilar(notification: NotificationModel) {
    // Get similar notifications which are not already viewed and marked them as viewed
    let notificationsSimilar = [];

    if (notification.type === NotificationType.AccountTokenExpired) {
      notificationsSimilar = this.rawNotifications.filter(
        n => n.type === notification.type && n.accountId === notification.accountId && !n.status
      );
    } else if (notification.type === NotificationType.SqlConnectionInvalid) {
      notificationsSimilar = this.rawNotifications.filter(
        n => n.type === notification.type && n.host === notification.host && !n.status
      );
    }
    // console.log('Notifications similar:', notificationsSimilar);

    // Update status of similar notifications to 'view' to dismiss them from banner
    notificationsSimilar.forEach(n => this.store.dispatch(new PatchNotificationStatus(n, NotificationAction.View)));

    // Remove banner notification from the list
    this.bannerNotifications = this.bannerNotifications.filter(n => n.id !== notification.id);
  }

  flatten(notification: NotificationModel): Dictionary {
    const flattened = flatten(notification);

    // Truncate name when it is a string that is too long
    const maxLengthName = 30;
    if (flattened.name && typeof flattened.name === 'string' && flattened.name.length > maxLengthName) {
      flattened.name = flattened.name.substr(0, maxLengthName) + '...';
    }

    return flattened;
  }

  bannerNavigate(isNext: boolean) {
    if (isNext) {
      this.bannerNotificationIndex++;
    } else {
      this.bannerNotificationIndex--;
    }

    this.bannerNotification = this.bannerNotifications[this.bannerNotificationIndex];
  }
}
