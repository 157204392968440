<ng-container *transloco="let t; read: 'organizations.organization.integrations.status'">
  <!-- Things to show in the page title area -->
  <div *ngIf="layout == 'title'" class="flex-row-start flex-row-align-center">
    <!-- Enable/disable integration -->
    <mat-slide-toggle
      (change)="integrationSetStatusSlider($event)"
      [disabled]="!canToggleIntegration"></mat-slide-toggle>

    <div class="flex-row-start flex-row-align-center integration-message text-secondary">
      <span>{{ 'organizations.organization.billing.status.integration.' + integrationStatus | transloco }}</span>

      <ng-container *ngIf="integrationMessagePricing">
        <a [routerLink]="['/organizations', organizationId, 'billing', 'plans', 'all']" class="hyperlink"
          >Pricing info</a
        >
      </ng-container>
    </div>
  </div>

  <!-- Things to show below the page header area -->
  <div
    class="integration-message-container"
    *ngIf="layout == 'below' && (integrationMessage !== '' || (isTrialPeriod && integrationStatus == 'trial'))">
    <div class="flex-row-start flex-row-align-center">
      <mat-icon fontSet="material-icons-outlined">lightbulb</mat-icon>

      <!-- Message -->
      <div class="integration-message text-secondary">
        <!--<span *ngIf="integrationMessage !== ''">{{ integrationMessage }}</span>-->

        <!-- Google Sheets -->
        <div
          *ngIf="type == 'sheets' && page == 'reportsGoogleSheets' && integrationMessage !== ''"
          class="header-description text-secondary">
          <ng-container *transloco="let t; read: 'app.page.header.reportsGoogleSheets'">
            <span>
              {{ t('link1') }}
              <a [href]="linkAddonSheets" target="_blank" class="hyperlink">{{ t('link2') }}</a>
              {{ t('link3') }}
            </span>
          </ng-container>
        </div>

        <!-- Excel -->
        <div
          *ngIf="type == 'sheets' && page == 'reportsExcel' && integrationMessage !== ''"
          class="header-description text-secondary">
          <ng-container *transloco="let t; read: 'app.page.header.reportsExcel'">
            <span>
              {{ t('link1') }}
              <a [href]="linkAddonExcel" target="_blank" class="hyperlink">{{ t('link2') }}</a>
              {{ t('link3') }}
            </span>
          </ng-container>
        </div>

        <ng-container *ngIf="isTrialPeriod && integrationStatus == 'trial'">
          <span>{{ t('trialEnd') }}</span>
          <span>:&nbsp;&nbsp;{{ trialEndDate | date }}&nbsp;&nbsp;</span>
          <span>({{ trialDaysRemaining }} {{ t('trialDaysRemaining') }})</span>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
