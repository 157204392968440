import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent {
  @Input() srcset: string;
  @Input() src: string;
  @Input() encapsulate = 'width';
  @Input() formats: string[] = ['svg', 'webp', 'png'];
  @Input() external = false;
  @Input() showSpinner = false;
  @Input() alt = 'picture';
  @Input() isBorder = false;

  loading = true;

  constructor() {}

  presentImage() {
    this.loading = false;
  }

  getMimeType(format: string): string {
    if (format === 'svg') {
      return 'image/svg+xml';
    }
    return 'image/' + format;
  }
}
