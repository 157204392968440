import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {StreamAuth, ToggleUserMode} from '@store/auth/auth.actions';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit {
  @Input() isSidenavOpen: boolean;
  @Input() isLoginRoute: boolean;
  @Input() isDarkMode: boolean;
  @Input() screenSize: string;
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleTheme = new EventEmitter<void>();
  @Select(state => state.auth.user) user$: Observable<any>;
  isAdmin = false;
  isAdminMode = false;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.user$.subscribe(user => {
      this.isAdmin = user?.isAdmin;
      if (user?.isAdmin === true) {
        this.isAdminMode = true;
      }
    });
  }

  toggleAdmin() {
    if (this.isAdmin === true) {
      this.store.dispatch(new ToggleUserMode());
    }
    if (this.isAdmin === false) {
      this.store.dispatch(new StreamAuth());
    }
  }
}
