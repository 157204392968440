<div *transloco="let t; read: 'app.dialogs.confirm'" [hidden]="isLoading">
  <h2 mat-dialog-title>{{ title || t('title') }}</h2>

  <mat-dialog-content *ngIf="content">
    <p>{{ content }}</p>

    <div *ngIf="url">
      <a href="{{ url }}" class="hyperlink" target="_blank">Click here for more information</a>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="align-end">
    <ng-container *ngFor="let action of actions">
      <button
        mat-flat-button
        (click)="action.callback ? action.callback() : null"
        *ngIf="action.primary"
        class="button-warn">
        {{ action.title || t('actions.' + action.value) }}
      </button>
      <button mat-stroked-button (click)="action.callback ? action.callback() : null" *ngIf="!action.primary">
        {{ action.title || t('actions.' + action.value) }}
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>

<div [hidden]="!isLoading" class="loading">
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div>
