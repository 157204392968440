import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxsModule} from '@ngxs/store';
import {BackfillMenuModule} from '../components/backfill-menu/backfill-menu.module';
import {LogsState} from '@store/logs/logs.state';

import {BaseComponent} from './components/base/base.component';
import {ConfigFormComponent} from './components/config-form/config-form.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {PictureComponent} from './components/picture/picture.component';
import {TableUserCellComponent} from './components/table-user-cell/table-user-cell.component';
import {PromptDialogComponent} from '@shared/components/prompt-dialog/prompt-dialog.component';
import {ScopesDialogComponent} from '@shared/components/scopes-dialog/scopes-dialog.component';
import {ProviderCardComponent} from '@shared/components/provider-card/provider-card.component';
import {ProviderCredentialsDialogComponent} from '@shared/components/provider-credentials-dialog/provider-credentials-dialog.component';
import {SignInWithGoogleComponent} from '../components/nav/auth-nav-items/sign-in-with-google/sign-in-with-google.component';
import {SheetsTableComponent} from './components/sheets/sheets-table.component';
import {IntegrationComponent} from './components/integration/integration.component';
import {UsageTableComponent} from './components/usage-table/usage-table.component';
import {LogsTableComponent} from '../components/logs-dialog/logs-table/logs-table.component';
import {BillingStatusComponent} from './components/billing-status/billing-status.component';
import {IconComponent} from './components/icon/icon.component';

import {AccountPipe} from '@shared/pipes/account/account.pipe';
import {ConnectorPipe} from '@shared/pipes/connector/connector.pipe';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {LogPipe} from '@shared/pipes/logs/log.pipe';
import {OrganizationPipe} from '@shared/pipes/organization/organization.pipe';
import {SheetPipe} from '@shared/pipes/sheet/sheet.pipe';
import {UserPipe} from './pipes/auth/user.pipe';
import {ConnectorsProviderPipe} from './pipes/connectors-provider/connectors-provider.pipe';
import {NotificationPipe} from './pipes/notification/notification.pipe';
import {TemplatePipe} from './pipes/template/template.pipe';
import {ReportPipe} from './pipes/report/report.pipe';
import {ExportPipe} from './pipes/export/export.pipe';

import {UsageHistoryDialogComponent} from './components/usage-history-dialog/usage-history-dialog.component';
import {UsageHistoryTableComponent} from '@shared/components/usage-history-dialog/usage-history-table/usage-history-table.component';
import {UsageHistoryChartComponent} from './components/usage-history-dialog/usage-history-chart/usage-history-chart.component';
import {UsageHistoryGapsComponent} from './components/usage-history-dialog/usage-history-gaps/usage-history-gaps.component';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSliderModule} from '@angular/material/slider';
import {MatTabsModule} from '@angular/material/tabs';

const sharedPipes = [
  UserPipe,
  DocumentPipe,
  OrganizationPipe,
  AccountPipe,
  NotificationPipe,
  TemplatePipe,
  ReportPipe,
  ConnectorPipe,
  ConnectorsProviderPipe,
  LogPipe,
  SheetPipe,
  ExportPipe,
];

const sharedComponents = [
  PictureComponent,
  TableUserCellComponent,
  ProviderCardComponent,
  ProviderCredentialsDialogComponent,
  BaseComponent,
  PageTitleComponent,
  PageHeaderComponent,
  ConfirmDialogComponent,
  PromptDialogComponent,
  ScopesDialogComponent,
  ConfigFormComponent,
  SignInWithGoogleComponent,
  IconComponent,
  SheetsTableComponent,
  IntegrationComponent,
  UsageTableComponent,
  LogsTableComponent,
  BillingStatusComponent,
  UsageHistoryDialogComponent,
  UsageHistoryTableComponent,
  UsageHistoryChartComponent,
  UsageHistoryGapsComponent,
];

@NgModule({
  declarations: [...sharedPipes, ...sharedComponents],
  exports: [
    ...sharedPipes,
    ...sharedComponents,
    MatIconModule,
    MatDialogModule,
    PageTitleComponent,
    PageHeaderComponent,
    ConfigFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    NgxsModule.forFeature([LogsState]),
    FormsModule,
    ReactiveFormsModule,
    BackfillMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatRadioModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSliderModule,
  ],
  providers: [...sharedPipes],
})
export class SharedModule {}
