import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {distinctUntilChanged, filter, first, map, tap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {ResetLogs, StreamLogs} from '@store/logs/logs.actions';
import {LogsDialogComponent} from './components/logs-dialog/logs-dialog.component';
import {Select, Store} from '@ngxs/store';
import {combineLatest, Observable, of} from 'rxjs';
import {PromptDialogComponent} from '@shared/components/prompt-dialog/prompt-dialog.component';
import {ResetError, SetIframe} from '@store/app/app.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RedirectAfterSignIn} from '@store/auth/auth.actions';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDrawerToggleResult, MatSidenav} from '@angular/material/sidenav';
import {OverlayContainer} from '@angular/cdk/overlay';
import {UserModel} from '@pma/shared/types/user';
import {ProgressComponent} from './components/progress/progress.component';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';

// These breakpoint values needs to stay in sync with the related Sass variables in src/styles/_constants.scss
const LARGE_WIDTH_BREAKPOINT = 992;
const MEDIUM_WIDTH_BREAKPOINT = 768;
const SMALL_WIDTH_BREAKPOINT = 576;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'pma';
  version = '2024.03.14';

  @Select(state => state.app.isLoading) isLoading$: Observable<boolean>;
  @Select(state => state.app.isIframe) isIframe$: Observable<boolean>;
  @Select(state => state.app.error) error$: Observable<string>;
  @Select(state => state.auth) auth$: Observable<any>;
  @Select(state => state.auth.user) user$: Observable<UserModel>;
  @Select(state => Boolean(state.auth.isInitialized && !state.auth.isLoggedIn)) isLoggedOut$: Observable<boolean>;

  loaderDialog: MatDialogRef<ProgressComponent>;

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  isSidenavOpen = false;
  isLoginRoute = false;
  params: Observable<Params> | undefined;

  // Observe screen size
  isScreenSmall$: Observable<boolean>;
  isSmallScreen = false;
  screenSize: string;

  breakpointsCss = {
    sm: `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`,
    md: `(max-width: ${MEDIUM_WIDTH_BREAKPOINT}px)`,
    lg: `(max-width: ${LARGE_WIDTH_BREAKPOINT}px)`,
  };

  // Dark or light mode
  isDarkMode = false;
  @HostBinding('class') hostClass = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private store: Store,
    private breakpoints: BreakpointObserver,
    private overlay: OverlayContainer,
    private serviceWorkerUpdate: SwUpdate
  ) {
    (window as any).store = this.store.select(state => state);

    // Observe screen size
    this.isScreenSmall$ = breakpoints.observe(Object.values(this.breakpointsCss)).pipe(
      map(breakpointState => {
        this.screenSize = 'xl';

        for (const breakpointKey of Object.keys(this.breakpointsCss)) {
          const breakpointCss = this.breakpointsCss[breakpointKey];

          if (breakpointCss in breakpointState.breakpoints && breakpointState.breakpoints[breakpointCss]) {
            this.screenSize = breakpointKey;
            break;
          }
        }

        this.isSmallScreen = this.isSmallerScreen();
        // console.log('Screen size:', this.screenSize, this.isSmallScreen);
        return this.isSmallScreen;
      })
    );
  }

  ngOnInit() {
    this.manageUser();
    this.manageLogsDialog();
    this.manageLoading();
    this.manageAppErrors();
    this.manageAfterLoginRedirect();
    this.manageRouteClass();
    this.manageRouteIframe();

    this.setTheme();
    console.log('Version:', this.version);

    // Combine params from all of the path into a single object.
    this.params = combineLatest(
      this.activatedRoute.pathFromRoot.map(route => route.params),
      Object.assign
    );

    // Detect login page
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        // console.log('Route:', e.url);
        this.isLoginRoute = e.url === '/' || e.url === '/trial';
      }
    });

    // Refresh page if service worker update is available
    if (this.serviceWorkerUpdate.isEnabled) {
      this.serviceWorkerUpdate.versionUpdates
        .pipe(
          filter((evt: VersionReadyEvent) => evt.type === 'VERSION_READY'),
          tap(() => location?.reload())
        )
        .subscribe();
    }
  }

  isSmallerScreen(): boolean {
    // A small screen is anything <= the large breakpoint
    if (this.screenSize === 'sm' || this.screenSize === 'md' || this.screenSize === 'lg') {
      return true;
    }

    return false;
  }

  toggleSidenav(sidenav: MatSidenav): Promise<MatDrawerToggleResult> {
    // console.log('toggleSidenav:', sidenav);
    return sidenav.toggle();
  }

  toggleTheme(): Promise<void> {
    this.isDarkMode = !this.isDarkMode;
    this.setTheme();
    return;
  }

  setTheme() {
    // Apply theme
    if (this.isDarkMode) {
      this.hostClass = 'dark-mode';
    } else {
      this.hostClass = 'light-mode';
    }

    // Apply theme to overlay container
    if (this.isDarkMode) {
      this.overlay.getContainerElement().classList.add('dark-mode');
      this.overlay.getContainerElement().classList.remove('light-mode');
    } else {
      this.overlay.getContainerElement().classList.add('light-mode');
      this.overlay.getContainerElement().classList.remove('dark-mode');
    }
  }

  sidenavOpenChanged(isOpen: boolean) {
    // console.log('sidenavOpenChanged', isOpen);
    this.isSidenavOpen = isOpen;
  }

  manageUser() {
    this.user$
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        tap((user: UserModel) => {
          // Set dark mode
          if (user.settings && user.settings.defaultModeDark !== undefined) {
            this.isDarkMode = user.settings.defaultModeDark;

            // Do NOT use dark mode for authorization requests
            if (this.route.snapshot.queryParams.response_type && this.route.snapshot.queryParams.redirect_uri) {
              this.isDarkMode = false;
            }

            this.setTheme();
          }
        })
      )
      .subscribe();
  }

  manageRouteClass() {
    this.router.events.subscribe(val => {
      // this.hostClass = window.location.pathname.replace(/\//, '_');
    });
  }

  manageRouteIframe() {
    // Allow iframing of hub content by removing header and footer
    this.route.queryParams.subscribe(params => {
      if (params.iframe) {
        this.store.dispatch(SetIframe);
      }
    });
  }

  manageLoading() {
    this.isLoading$
      .pipe(
        filter(isLoading => isLoading || Boolean(this.loaderDialog)),
        tap(isLoading => {
          if (isLoading) {
            this.loaderDialog = this.dialog.open(ProgressComponent, {
              panelClass: 'custom-loading-dialog',
            });
          } else if (this.loaderDialog) {
            this.loaderDialog.close();
            delete this.loaderDialog;
          }
        })
      )
      .subscribe();
  }

  manageLogsDialog() {
    this.route.queryParams
      .pipe(
        map(params => params.logs),
        distinctUntilChanged(),
        filter(Boolean),
        tap((logs: string) => this.openLogsDialog(logs))
      )
      .subscribe();
  }

  manageAppErrors() {
    this.error$
      .pipe(
        filter(Boolean),
        distinctUntilChanged((prev, curr) => prev === curr),
        tap((error: string) => {
          this.store.dispatch(new ResetError());

          this.dialog.open(PromptDialogComponent, {
            panelClass: 'app-error-dialog',
            data: {
              title: 'error',
              content: error,
            },
          });

          return of(error);
        })
      )
      .subscribe();
  }

  manageAfterLoginRedirect() {
    if (sessionStorage.getItem('afterLogin')) {
      this.store.dispatch(RedirectAfterSignIn);
    }
  }

  openLogsDialog(entityPath: string) {
    // Stream all logs for entity
    this.store.dispatch(new StreamLogs(entityPath));

    // Open Dialog
    const dialogRef = this.dialog.open(LogsDialogComponent, {panelClass: ['logs-dialog']});

    // After Dialog closes, stop streaming
    dialogRef
      .afterClosed()
      .pipe(
        first(),
        tap(() => this.store.dispatch(new ResetLogs())),
        tap(() => {
          const params = {...this.route.snapshot.queryParams};
          delete params.logs;

          this.router.navigate([], {queryParams: params});
        })
      )
      .subscribe();
  }
}
