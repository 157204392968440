import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {BaseComponent} from '@shared/components/base/base.component';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {ConnectorsStateModel} from '@store/connectors/connectors.state';
import {OrganizationsState} from '@store/organizations/organizations.state';
import {OrganizationModel} from '@pma/shared/types/organization';
import {SheetModel} from '@pma/shared/types/sheets';
import {DeleteScheduledRefresh} from '@store/sheets/sheets.actions';

@Component({
  selector: 'app-sheets-table',
  templateUrl: './sheets-table.component.html',
  styleUrls: ['./sheets-table.component.scss'],
})
export class SheetsTableComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Select(OrganizationsState.selected) organization$: Observable<OrganizationModel>;
  @Select(state => state.connectors) connectors$: Observable<ConnectorsStateModel>;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<SheetModel>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  organizationId = '';

  @Input() platform: string;
  private sheetsVal: SheetModel[] = [];

  constructor(private store: Store) {
    super();
  }

  get sheets() {
    return this.sheetsVal;
  }

  @Input() set sheets(sheets: SheetModel[]) {
    this.sheetsVal = sheets;
    this.updateDataSource();
  }

  ngOnInit() {
    // Get current organization ID
    this.organization$
      .pipe(
        filter(Boolean),
        tap((organization: OrganizationModel) => {
          this.organizationId = organization.id;
        })
      )
      .subscribe();

    this.updateColumns();
  }

  ngAfterViewInit() {
    this.updateDataSource();
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.sheets);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.getSortingDataAccessor(this.sheets);
  }

  updateColumns() {
    // All columns: ['spreadsheetName', 'connectors', 'owner', 'createdAt', 'updatedAt', 'accessedAt','schedule', 'actions'];
    // Column 'schedule' is not supported for Excel

    switch (this.platform) {
      case 'sheets':
        this.displayedColumns = ['connectors', 'spreadsheetName', 'owner', 'schedule', 'accessedAt', 'actions'];
        break;

      case 'excel':
        this.displayedColumns = ['connectors', 'spreadsheetName', 'owner', 'accessedAt', 'actions'];
        break;
    }
  }

  openSheetGoogle(sheet: SheetModel) {
    window.open(`https://docs.google.com/spreadsheets/d/${sheet.spreadsheetId}/edit`);
  }

  openSheetExcel(sheet: SheetModel) {
    // Open online Excel document, extracting the userId from the spreadsheetId to build the URL

    const parts = sheet.spreadsheetId.split('-');

    if (parts.length === 2 && sheet.spreadsheetName !== '') {
      const userId = parts[1];
      window.open(`https://d.docs.live.net/${userId}/${sheet.spreadsheetName}`);
    }
  }

  isExcelDesktop(sheet: SheetModel): boolean {
    if (sheet.spreadsheetName && sheet.spreadsheetName.match(/^Desktop.*$/)) {
      return true;
    } else {
      return false;
    }
  }

  deleteScheduledRefresh(spreadsheetId: string) {
    this.store.dispatch(new DeleteScheduledRefresh(this.organizationId, spreadsheetId));
  }

  private getSortingDataAccessor(sheets: SheetModel[]) {
    return (data: SheetModel, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'spreadsheet':
          return data.spreadsheetId;
        case 'spreadsheetName':
          return data.spreadsheetName;
        case 'connectors':
          return data.connectors[0];
        case 'owner':
          return data.ownerDisplayName;
        case 'createdAt':
          return data.createdAt;
        case 'updatedAt':
          return data.updatedAt;
        case 'accessedAt':
          return data.accessedAt;
        case 'schedule':
          return data.refreshScheduleParsed;
        default:
          return data[sortHeaderId];
      }
    };
  }
}
