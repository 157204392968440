<div *transloco="let t; read: 'organizations.organization.usage.history.chart'">
  <ng-container *ngIf="data.length == 0; else chart">
    <p>{{ t('noBackfillData') }}</p>
  </ng-container>

  <ng-template #chart>
    <form [formGroup]="form">
      <!-- Type selector -->
      <mat-form-field appearance="outline" class="backfill-type">
        <mat-label>{{ t('backfillType') }}</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let tp of availableTypesReadable; index as i" [value]="availableTypes[i]">{{
            tp
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div id="chart">
        <div id="chart-top">
          <!-- TODO: The 'vertical' property no longer exists -->
          <mat-slider min="0.1" max="1" step="0.01" formControlName="yScale" id="y-scale"
            ><input matSliderThumb
          /></mat-slider>
          <div id="scrollable" dir="rtl">
            <div id="chart-body" [style]="chartSize">
              <div
                *ngFor="let datum of displayData"
                class="job"
                [style]="datum.style"
                dir="ltr"
                [matTooltip]="datum.tooltip"
                [matTooltipClass]="'explanation-tooltip-break-newline'"></div>
            </div>
          </div>
        </div>
        <mat-slider min="0.05" max=".95" step="0.1" formControlName="xScale" id="x-scale"
          ><input matSliderThumb
        /></mat-slider>
      </div>
    </form>
  </ng-template>
</div>
