import {Inject, Injectable, InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {NGXS_PLUGINS, NgxsPlugin} from '@ngxs/store';
import {pick} from 'lodash';

export const NGXS_INTERCEPTOR_PLUGIN_OPTIONS = new InjectionToken('NGXS_INTERCEPTOR_PLUGIN_OPTIONS');

@Injectable()
export class InterceptorPlugin implements NgxsPlugin {
  private ignoredActions = [
    '@@INIT',
    '@@UPDATE_STATE',
    '[Forms] Update Form Dirty',
    '[Forms] Update Form Errors',
    '[Forms] Update Form Status',
    '[Forms] Update Form Value',
    // '[Router] Navigate',
    '[Router] RouterCancel',
    // '[Router] RouterNavigation',
    '[Router] RouterDataResolved',
  ];

  constructor(
    @Inject(NGXS_INTERCEPTOR_PLUGIN_OPTIONS)
    private options: any,
    private analytics: AngularFireAnalytics
  ) {}

  handle(before, action, next) {
    const currentUid = before.auth && before.auth.isLoggedIn && before.auth.user ? before.auth.user.uid : undefined;

    const currentOrganizationId =
      before.organizations && before.organizations.selectedId ? before.organizations.selectedId : undefined;
    const currentOrganization =
      currentOrganizationId && before.organizations.entities
        ? before.organizations.entities[currentOrganizationId]
        : null;
    const currentSubscriptionId = currentOrganization ? currentOrganization.billing.subscriptionId : undefined;
    const trackers = currentOrganization ? currentOrganization.trackers : undefined;

    // Identifying variables for user and organization
    const extraParams = {
      hub_user_id: currentUid,
      hub_organization_id: currentOrganizationId,
      hub_subscription_id: currentSubscriptionId,
      hub_trackers: trackers && trackers.ga ? trackers.ga : undefined,
    };

    const type = action.type || action.constructor.type;
    const params = this.getEventParams(action, type);

    if (this.analytics && typeof this.analytics.logEvent === 'function' && this.ignoredActions.indexOf(type) === -1) {
      setTimeout(() => {
        // Log request might get higher priority than others. This forces it to happen after.
        this.analytics
          .logEvent(type, {...extraParams, ...params})
          // .then(() => console.debug('logEvent', {type, extraParams, params}))
          .catch(err => console.error(err));
      }, 0);
    }
    return next(before, action);
  }

  private getEventParams(action, type) {
    if (type.indexOf('[Router]') > -1) {
      return pick(action.event, ['id', 'url', 'urlAfterRedirects']);
    }

    if (action.constructor.eventParams) {
      return pick(action, action.constructor.eventParams);
    }

    return {};
  }
}

@NgModule()
export class NgxsInterceptorPluginModule {
  static forRoot(config?: any): ModuleWithProviders<NgxsInterceptorPluginModule> {
    return {
      ngModule: NgxsInterceptorPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: InterceptorPlugin,
          multi: true,
        },
        {
          provide: NGXS_INTERCEPTOR_PLUGIN_OPTIONS,
          useValue: config,
        },
      ],
    };
  }
}
