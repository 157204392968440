<div *transloco="let t; read: 'app.dialogs.scopes'">
  <h2 mat-dialog-title>{{ t('title') }}</h2>

  <mat-dialog-content>
    <!-- Scopes table -->
    <table id="scopes-table">
      <thead>
        <tr>
          <td>{{ t('columns.name') }}</td>
          <td>{{ t('columns.label') }}</td>
          <td>{{ t('columns.authorized') }}</td>
        </tr>
      </thead>
      <tr *ngFor="let item of scopesArray">
        <td>{{ item.name }}</td>
        <td>{{ item.label }}</td>
        <td><mat-checkbox [disabled]="true" [checked]="item.isAuthorized" class="scope-checkbox"></mat-checkbox></td>
      </tr>
    </table>

    <!-- Note about adding scopes -->
    <div id="scopes-note" *ngIf="isShowNote()" class="flex-row-start flex-row-align-center">
      <mat-icon fontSet="material-icons-outlined" class="color-info">info</mat-icon>
      <span>{{ t('note') }}</span>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="align-end">
    <ng-container>
      <button (click)="close()" mat-stroked-button>{{ t('actions.ok') }}</button>
    </ng-container>
  </mat-dialog-actions>
</div>
