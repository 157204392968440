import {Component, Input} from '@angular/core';
import {ConnectorProviderModel} from '@pma/shared/types/connector-provider';
import {ConnectorModel} from '@pma/shared/types/connector/connector.model';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.scss'],
})
export class ProviderCardComponent {
  @Input() provider: ConnectorProviderModel | ConnectorModel;
  @Input() counter: any = {};
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'beta-badge-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/beta-badge-icon.svg')
    );
  }
}
