<ng-container *transloco="let t; read: 'app.nav.auth.notifications'">
  <ng-container *ngIf="banner$ | async as banner">
    <div *ngIf="bannerNotification" class="banner">
      <!-- Notification message -->
      <div class="flex-row-start flex-row-align-center">
        <!-- Notification title -->
        <mat-icon fontSet="material-icons-outlined" class="banner-icon">warning</mat-icon>
        <div class="banner-separator-right">{{ t(bannerNotification.type + '.title') }}:</div>

        <!-- Notification content-->
        <div>{{ t(bannerNotification.type + '.contents', flatten(bannerNotification)) }}</div>

        <!-- Notification actions -->
        <div *ngIf="bannerNotification.actions" class="flex-row-between">
          <ng-container *ngFor="let action of bannerNotification.actions">
            <div class="banner-separator">-</div>
            <a class="banner-link" (click)="dispatch(bannerNotification, action.value)">
              <span *ngIf="action.value === 'view'">{{ t(bannerNotification.type + '.link') }}</span>
              <span *ngIf="action.value === 'viewAndDismiss'">{{ t('actions.viewAndDismiss') }}</span>
            </a>
          </ng-container>
        </div>
      </div>

      <!-- Navigation -->
      <div *ngIf="bannerNotifications.length > 1" class="banner-nav">
        <!-- Navigate left -->
        <div *ngIf="bannerNotificationIndex == 0" class="banner-nav-placeholder"></div>
        <mat-icon
          fontSet="material-icons-outlined"
          class="banner-separator"
          *ngIf="bannerNotificationIndex > 0"
          (click)="bannerNavigate(false)"
          >chevron_left
        </mat-icon>

        <!-- Navigation indecies -->
        <div>{{ bannerNotificationIndex + 1 }} of {{ bannerNotifications.length }} issues</div>

        <!-- Navigate right -->
        <div *ngIf="bannerNotificationIndex == bannerNotifications.length - 1" class="banner-nav-placeholder"></div>
        <mat-icon
          fontSet="material-icons-outlined"
          class="banner-separator"
          *ngIf="bannerNotificationIndex < bannerNotifications.length - 1"
          (click)="bannerNavigate(true)"
          >chevron_right
        </mat-icon>
      </div>
    </div>
  </ng-container>
</ng-container>
