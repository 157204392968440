<ng-container *transloco="let t; read: 'config.fields'" [formGroup]="form">
  <ng-container *ngFor="let field of fields" [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="'INFO_LIST'">
      <p>{{ t(field.name) || field.displayName }}</p>
      <ul>
        <ng-container *ngFor="let option of field.options">
          <li *ngIf="option.value">
            <a [href]="option.value" class="hyperlink" target="_blank">{{ option.label }}</a>
          </li>
          <li *ngIf="!option.value">{{ option.label }}</li>
        </ng-container>
      </ul>
    </ng-container>
    <div *ngSwitchCase="'INFO'" class="flex-row-start-nowrap flex-row-align-start config-info">
      <mat-icon class="color-gray">info</mat-icon>
      <div class="config-info-text">
        <span>{{ field.displayName }}</span>
      </div>
    </div>

    <ng-container *ngSwitchDefault>
      <mat-form-field
        *ngIf="isField(field)"
        [appearance]="isStyleFill ? 'fill' : 'outline'"
        [matTooltipClass]="'explanation-tooltip'"
        [matTooltipPosition]="'right'"
        [matTooltip]="t('helpText.' + field.name) || field.helpText"
        class="fix-width">
        <mat-label>{{ t(field.name) || field.displayName }}</mat-label>
        <ng-container [ngSwitch]="field.type">
          <input
            *ngSwitchCase="'TEXTINPUT'"
            [type]="field.name === 'password' ? 'password' : 'text'"
            [formControlName]="field.name"
            [placeholder]="field.helpText"
            matInput />

          <mat-select *ngSwitchCase="'SELECT_SINGLE'" [formControlName]="field.name">
            <!-- Place the search input inside the mat-select component -->
            <ng-container *ngTemplateOutlet="searchInput"></ng-container>
            <mat-option *ngFor="let option of filteredOptions" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>

          <ng-container *ngIf="integration != 'sql'">
            <mat-select *ngSwitchCase="'SELECT_MULTIPLE'" [formControlName]="field.name">
              <mat-option *ngFor="let option of field.options" [value]="option.value">{{ option.label }}</mat-option>
            </mat-select>
          </ng-container>

          <!-- SQL integration allows multiple selection of accounts -->
          <ng-container *ngIf="integration == 'sql'">
            <mat-select *ngSwitchCase="'SELECT_MULTIPLE'" [formControlName]="field.name" multiple>
              <!-- Improve trigger label for multiple selection -->
              <mat-select-trigger>
                {{ getSelectTriggerLabel(field.options) }}
                <span *ngIf="accounts.length > 1">
                  (+ {{ accounts.length - 1 }} {{ accounts.length === 2 ? 'option' : 'options' }} )
                </span>
              </mat-select-trigger>

              <!-- Option to select/unselect all (when there are many options) -->
              <mat-option
                *ngIf="field.options.length > 3 && accounts.length == 0"
                (click)="toggleSelectAll(field)"
                [value]="'toggle'"
                >-- SELECT ALL --
              </mat-option>
              <mat-option
                *ngIf="field.options.length > 3 && accounts.length > 0"
                (click)="toggleSelectAll(field)"
                [value]="'toggle'"
                >-- UNSELECT ALL --
              </mat-option>

              <!-- Options -->
              <mat-option *ngFor="let option of field.options" [value]="option.value">{{ option.label }}</mat-option>
            </mat-select>
          </ng-container>

          <mat-select *ngSwitchCase="'CHECKBOX'" [formControlName]="field.name">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>

          <ng-container *ngSwitchDefault> TODO... </ng-container>
        </ng-container>
        <mat-error *ngIf="form.get(field.name) && form.get(field.name).errors?.required">{{
          'forms.errors.required' | transloco
        }}</mat-error>
        <mat-error *ngIf="form.get(field.name) && form.get(field.name).errors?.whitespace">{{
          'forms.errors.whitespace' | transloco
        }}</mat-error>
      </mat-form-field>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #searchInput>
  <mat-form-field appearance="fill">
    <input matInput placeholder="Search" (input)="onSearch($event)" />
  </mat-form-field>
</ng-template>
