import {Component, Input, OnInit} from '@angular/core';
import {BackfillJobItem} from '@store/connectors/connectors.state';
import {FormBuilder} from '@angular/forms';
import {BaseComponent} from '@shared/components/base/base.component';
import {takeUntil, tap} from 'rxjs/operators';
import {dayTime, toShortISO} from '@pma/shared/utils/date/date';
import {readableName} from '@pma/shared/utils/snake-to-camel/readable-name';

@Component({
  selector: 'app-usage-history-chart',
  templateUrl: './usage-history-chart.component.html',
  styleUrls: ['./usage-history-chart.component.scss'],
})
export class UsageHistoryChartComponent extends BaseComponent implements OnInit {
  @Input() data: BackfillJobItem[];

  availableTypes: string[] = [];
  availableTypesReadable: string[] = [];

  form = this.formBuilder.group({
    type: '',
    xScale: 0.6,
    yScale: 0.6,
  });

  displayData: any[];

  chartSize = {
    height: '',
    width: '',
  };

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        tap(settings => this.updateData(settings)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();

    const types = new Set<string>();
    this.data.forEach(d => types.add(d.subType));
    this.availableTypes = Array.from(types).sort();
    this.availableTypesReadable = this.availableTypes.map(t => readableName(t));

    // Initial form update
    this.form.patchValue({type: this.availableTypes[0]});
  }

  updateData(settings) {
    const now = new Date();
    const h = (1 / (1 - settings.yScale)) * 5;
    const w = (1 / (1 - settings.xScale)) * 5;
    // console.log('Chart settings:', settings, w, h);
    let chartWidth = 0;

    this.displayData = this.data
      .filter(d => d.subType === settings.type)
      .map(d => {
        const daysAgoY = (Date.now() - new Date(d.date).getTime()) / dayTime;
        const daysAgoX = (Date.now() - new Date(d.end).getTime()) / dayTime;
        const daysLength = 1 + (new Date(d.end).getTime() - new Date(d.start).getTime()) / dayTime;

        const right = daysAgoX * w;
        const width = daysLength * w;
        chartWidth = Math.max(chartWidth, right + width);

        const dateCreated = new Date(d.date).toLocaleDateString() + ' ' + new Date(d.date).toLocaleTimeString();
        const tooltipString = 'Job created on ' + dateCreated + '\n' + toShortISO(d.start) + ' to ' + toShortISO(d.end);

        // Jobs not finished within 24 hours after they start are considered finished and failed
        const isFinished =
          d.finishDate || (d.finishDate === null && now.getTime() - new Date(d.date).getTime() > dayTime);
        const isFailed =
          (d.finishDate && d.status === 'failed') ||
          (d.finishDate === null && now.getTime() - new Date(d.date).getTime() > dayTime);

        let color = 'gray';
        if (isFinished) {
          if (isFailed) {
            color = 'red';
          } else {
            color = 'green';
          }
        }

        return {
          ...d,
          style: {
            backgroundColor: color,
            height: `${h}px`,
            top: `${daysAgoY * h}px`,
            width: `${width}px`,
            right: `${right}px`,
            zIndex: isFinished ? '10' : '1',
          },
          tooltip: tooltipString,
        };
      });

    this.chartSize = {
      height: this.displayData[this.displayData.length - 1].style.top,
      width: `${chartWidth}px`,
    };

    // console.log('Chart data:', this.chartSize, this.displayData.slice(0, 10));
  }
}
