import {Component, Input, OnInit} from '@angular/core';
import {OrganizationModelBilling} from '@pma/shared/types/organization';
import {BillingService} from '@core/services/billing/billing.service';

@Component({
  selector: 'app-billing-status',
  templateUrl: './billing-status.component.html',
  styleUrls: ['./billing-status.component.scss'],
})
export class BillingStatusComponent {
  @Input() organizationId: string;
  @Input() isBillingRoute: boolean;
  @Input() billing: OrganizationModelBilling;

  constructor(public billingService: BillingService) {}
}
