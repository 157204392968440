import {Component, Input, OnInit} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {BaseComponent} from '@shared/components/base/base.component';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';
import {OrganizationsState} from '@store/organizations/organizations.state';
import {OrganizationModel} from '@pma/shared/types/organization';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends BaseComponent implements OnInit {
  @Select(OrganizationsState.selected) organization$: Observable<OrganizationModel>;

  @Input() page: string;
  translationPathRoot = 'app.page.header.';

  organizationId;
  isPlanEnterprise = false;

  title: string;
  description: string;
  extras: any;
  image: string;
  imageDefault = 'super-girl.png';

  pageImages = {
    // Reports
    reportsLookerStudio: 'header-data-studio.svg',
    reportsGoogleSheets: 'header-google-sheets.svg',
    reportsExcel: 'header-excel.svg',
    // Sources
    sources: 'header-sources.svg',
    sourcesAdd: 'header-sources.svg',
    // Exports
    api: 'header-api.svg',
    googleAnalytics: 'header-google-analytics.svg',
    sql: 'header-sql-warehouse.svg',
    exportsFtp: 'header-ftp.svg',
    exportsBigQuery: 'header-big-query.svg',
    exportsMySQL: 'header-mysql.svg',
    // Tools
    dataExplorer: 'header-data-explorer.svg',
    schema: 'header-schema-explorer.svg',
    templates: 'header-templates.svg',
    downtime: 'header-downtime.svg',
  };

  pageExtras = {
    reportsGoogleSheets: {
      linkAddon: 'https://workspace.google.com/marketplace/app/data_connnector/606653648723',
      linkIntegration:
        'https://support.powermyanalytics.com/portal/en/kb/articles/google-sheets-data-integration-guide',
    },
    reportsExcel: {
      linkAddon: 'https://appsource.microsoft.com/en-us/product/office/WA200002101',
      linkIntegration:
        'https://support.powermyanalytics.com/portal/en/kb/articles/microsoft-excel-data-integration-guide',
    },
    api: {
      linkAPI: 'https://app.swaggerhub.com/apis/DEV_62/PowerMyAnalytics/1.0.1',
    },
  };

  constructor(private transloco: TranslocoService) {
    super();
  }

  ngOnInit() {
    // Get header image and text
    const translationPath = this.translationPathRoot + this.page;
    this.title = this.transloco.translate(translationPath + '.title');
    this.description = this.transloco.translate(translationPath + '.description');
    this.image = this.page in this.pageImages ? this.pageImages[this.page] : this.imageDefault;
    // console.log('Header:', this.image, this.title, this.description);

    // Get header extras
    if (this.page in this.pageExtras) {
      this.extras = this.pageExtras[this.page];
      // console.log('Header extras:', this.extras);
    }

    this.organization$
      .pipe(
        filter(Boolean),
        distinctUntilChanged((a: OrganizationModel, b: OrganizationModel) => a.id === b.id),
        tap((organization: OrganizationModel) => {
          this.organizationId = organization.id;
          this.isPlanEnterprise = organization.billing.planCode.indexOf('ENT') > -1;
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }
}
