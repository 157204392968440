<ng-container *transloco="let t; read: 'app.nav'">
  <ng-container *ngIf="notificationsState$ | async as notificationsState">
    <!-- [matMenuTriggerFor]="notificationsMenu" -->
    <button
      mat-icon-button
      class="notifications-icon"
      [routerLink]="['organizations', organizationId, 'notifications']"
      [matTooltip]="t('notifications')"
      [matTooltipPosition]="'below'"
      [matTooltipClass]="'nav-tooltip'">
      <mat-icon fontSet="material-icons" class="nav-button-offset">notifications</mat-icon>
      <span *ngIf="unreadCount$ | async as count" [hidden]="!count" class="badge">{{ count }}</span>
    </button>

    <mat-menu #notificationsMenu="matMenu" class="notifications-panel" xPosition="before">
      <ng-container *ngIf="notificationsState.isInitialized">
        <ng-container *transloco="let t; read: 'app.nav.auth.notifications'">
          <div (click)="$event.stopPropagation()" id="wrapper">
            <!-- Notifications title -->
            <div [class.new]="notificationsState.unreadCount" class="title">
              {{ t('title', {count: notificationsState.unreadCount}) }}
            </div>

            <!-- No Results -->
            <div *ngIf="!notificationsState.collection.length" class="padding-16">
              {{ t('noResults') }}
            </div>

            <!-- Notifications List -->
            <div id="notifications-list">
              <!-- Notification Item -->
              <div
                *ngFor="let notification of notificationsState.collection"
                [class.new]="!notification.hasRead"
                class="notification-item">
                <!-- Notification type -->
                <div class="type">{{ t(notification.type + '.title') }}</div>

                <!-- Notification timestamp -->
                <div class="timestamp">{{ notification.createdAt | date }}</div>

                <!-- Notification content-->
                <div class="content">{{ t(notification.type + '.contents', flatten(notification)) }}</div>

                <!-- Notification actions -->
                <div *ngIf="notification.actions" class="actions">
                  <!-- Notification status (when notification has already actioned) -->
                  <span *ngIf="notification.status !== undefined" class="final-state">
                    {{ t('states.' + notification.type + '.' + notification.status) }}
                  </span>

                  <ng-container *ngIf="!notification.status">
                    <ng-container *ngFor="let action of notification.actions">
                      <!-- Button value -->
                      <ng-template #buttonTemplate>{{ t('actions.' + action.value) }}</ng-template>

                      <!-- Primary action button -->
                      <button
                        mat-flat-button
                        (click)="dispatch(notification, action.value)"
                        *ngIf="action.primary"
                        class="button-standard">
                        <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
                      </button>

                      <!-- Secondary action button -->
                      <button mat-stroked-button (click)="dispatch(notification, action.value)" *ngIf="!action.primary">
                        <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
                      </button>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Loading indicator -->
        <div *ngIf="!notificationsState.isInitialized" class="padding-64 align-center">
          <mat-spinner diameter="32"></mat-spinner>
        </div>
      </ng-container>
    </mat-menu>
  </ng-container>
</ng-container>
