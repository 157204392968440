export class StreamGoogleAccounts {
  static readonly type = '[Google Analytics] Stream Google Accounts Data';
}

export class StreamGoogleAnalyticsImports {
  static readonly type = '[Google Analytics] Stream Google Analytics Imports';
}

export class InitializeGAImport {
  static readonly type = '[Google Analytics] Initialize Import';

  constructor(public importId: string) {}
}

export class ResetGAImportForm {
  static readonly type = '[Google Analytics] Reset Import Form';
}

export class InitializeGAImportConnectorConfig {
  static readonly type = '[Google Analytics] Initialize Import Connector';

  constructor(public accountId: string, public organizationId: string) {}
}

export class FetchGAAccounts {
  static readonly type = '[Google Analytics] Fetch Google Analytics Accounts';

  constructor(public accountId: string) {}
}

export class FetchGAProperties {
  static readonly type = '[Google Analytics] Fetch Google Analytics Account Web Properties';

  constructor(public gaAccountId: string) {}
}

export class FetchGADataSources {
  static readonly type = '[Google Analytics] Fetch Google Analytics Data Sources';

  constructor(public gaAccountId: string, public gaWebPropertyId: string) {}
}

export class FetchGAProfiles {
  static readonly type = '[Google Analytics] Fetch Google Analytics';

  constructor(public gaAccountId: string, public gaWebPropertyId: string) {}
}

export class FilterGAProfiles {
  static readonly type = '[Google Analytics] Filter Google Analytics Profiles by Data Source';

  constructor(public gaDataSourceId: string) {}
}

export class SetImportFilter {
  static readonly type = '[Google Analytics] Set Import Filter';

  constructor(public filters: any) {}
}

export class CreateGoogleAnalyticsImport {
  static readonly type = '[Google Analytics] Create new Import';

  constructor(public organizationId: string) {}
}

export class UpdateGoogleAnalyticsImport {
  static readonly type = '[Google Analytics] Update Import';

  constructor(public importId: string) {}
}

export class RemoveGoogleAnalyticsImport {
  static readonly type = '[Google Analytics] Delete Import';

  constructor(public importId: string) {}
}

export class BackfillGoogleAnalyticsImport {
  static readonly type = '[Google Analytics] Backfill Import';

  constructor(public importId: string, public start: Date, public end: Date) {}
}

export class ChangeGoogleAnalyticsImportField {
  static readonly type = '[Google Analytics] Change Google Analytics Import Field';
  static readonly eventParams = ['field', 'value'];

  constructor(public field: string, public value: any) {}
}
