export class FetchConnectors {
  static readonly type = '[Connectors] Sync Connectors Data';
}

export class SyncTemplateStatuses {
  static readonly type = '[Connectors] Sync Template Statuses Data';
}

export class StopSyncTemplateStatuses {
  static readonly type = '[Connectors] Stop Sync Template Statuses Data';
}

export class BackfillEntity {
  static readonly type = '[Connectors] Backfill Entity';

  constructor(
    public accountId: string,
    public connector: string,
    public usageKey: string,
    public start: Date,
    public end: Date,
    public reportTypes: string[]
  ) {}
}

export class GetBackfillHistory {
  static readonly type = '[Connectors] Get Backfill History';

  constructor(public accountId: string, public connector: string, public usageKey: string) {}
}

export class GetConnectorReportTypes {
  static readonly type = '[Connectors] Get Report Types';

  constructor(public connector: string) {}
}
