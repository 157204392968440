<div *transloco="let t; read: 'app.dialogs.prompt'">
  <h2 mat-dialog-title>{{ t('titles.' + title) }}</h2>

  <mat-dialog-content>
    <!-- Content without link -->
    <p *ngIf="content && !url">{{ content }}</p>

    <!-- Content with link -->
    <div *ngIf="content && url">
      <span>{{ contentBeforeLink }}</span>
      <a href="{{ url }}" class="hyperlink" target="_blank">{{ urlLabel }}</a>
      <span>{{ contentAfterLink }}</span>
    </div>

    <!-- Content list -->
    <ng-container *ngIf="contentList">
      <div *ngFor="let item of contentList">{{ item }}</div>
    </ng-container>

    <!-- Content dictionary -->
    <ng-container *ngIf="contentDictionary">
      <table id="table-dictionary">
        <thead>
          <tr>
            <td>Name</td>
            <td>Description</td>
          </tr>
        </thead>

        <tr *ngFor="let item of contentDictionary | keyvalue">
          <td>{{ item.key }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </table>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions class="align-end">
    <ng-container>
      <button mat-stroked-button (click)="close()">{{ t('actions.ok') }}</button>
    </ng-container>
  </mat-dialog-actions>
</div>
