import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {EntityLogModel} from '@pma/shared/types/logs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Select} from '@ngxs/store';
import {LogsStateModel} from '@store/logs/logs.state';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.scss'],
})
export class LogsTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['type', 'createdAt'];
  dataSource: MatTableDataSource<EntityLogModel>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @Select(state => state.logs) logs$: Observable<LogsStateModel>;
  @Input() logs: EntityLogModel[];

  logsFixed: EntityLogModel[] = [];

  ngOnInit() {
    this.transformLogs();
    this.dataSource = new MatTableDataSource(this.logsFixed);
    this.logs$.pipe(filter((state: LogsStateModel) => state?.isInitialized)).subscribe(logs => {
      this.dataSource.data = logs.collection;
    });
  }
  transformLogs() {
    // Modify logs to fix the HTML content that transloco is choking on
    const tagHtmlTitle = '<title>';
    const removeHtml = '<html';

    this.logsFixed = [];
    this.logs.forEach(l => {
      const log = Object.assign({}, l);

      if (l.params && 'error' in l.params) {
        // Handle HTML documents
        log.params = Object.assign({}, l.params);

        if (l.params.error.indexOf(tagHtmlTitle) > -1) {
          // Extract title from HTML as the error message
          const startIndex = log.params.error.indexOf(tagHtmlTitle) + tagHtmlTitle.length;
          log.params.error =
            ': ' + log.params.error.substr(startIndex, log.params.error.indexOf('</title>') - startIndex);
        } else if (l.params.error.indexOf(removeHtml) > -1) {
          // Replace HTML completely
          log.params.error = 'Error: HTML document';
        } else if (l.params?.error?.includes('403')) {
          console.log(l.params.error);
          log.params.error = 'Error: Data Connector Provider responded with 403 Forbidden';
        }
      }

      this.logsFixed.push(log);
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
