import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {CollectionReference} from '@angular/fire/compat/firestore/interfaces';
import {AuthService} from '@core/services/auth/auth.service';
import {NotificationPipe} from '@shared/pipes/notification/notification.pipe';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {WithId} from '@pma/shared/interfaces/with-id';
import {NotificationAction, NotificationDocument, NotificationModel} from '@pma/shared/types/notification';
import {UserModel} from '@pma/shared/types/user';
import {Select} from '@ngxs/store';
import {orderBy} from 'lodash';
import {serverTimestamp} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  static readonly MAX_LIMIT: number = 100;

  @Select(state => (state.organizations ? state.organizations.selectedId : null)) organizationId$: Observable<string>;

  // @ts-ignore
  notifications$: Observable<NotificationModel[]> = combineLatest([this.auth.user$, this.organizationId$]).pipe(
    filter<any>(([user, organizationId]) => Boolean(user)),
    switchMap<[UserModel, string], any>(([user, organizationId]) => this.getNotifications(user.uid, organizationId))
  );

  notificationsBanner$: Observable<NotificationModel[]>;

  constructor(private auth: AuthService, private afs: AngularFirestore, private notificationPipe: NotificationPipe) {
    // Stream banner notifications (with specfic types) into a seperate collection
    this.notificationsBanner$ = this.organizationId$.pipe(
      filter(organizationId => Boolean(organizationId)),
      switchMap(organizationId =>
        this.getNotificationsByTypeForOrganization(organizationId, ['accountTokenExpired', 'sqlConnectionInvalid'])
      )
    );
  }

  getNotifications(userId: string, organizationId: string) {
    // To a user OR organization, any type
    const refs = this.getRelevantRefs(userId, organizationId);

    return this.afs
      .collection<NotificationDocument>('notifications', (ref: CollectionReference) =>
        ref.where('to', 'array-contains-any', refs).orderBy('createdAt', 'desc').limit(NotificationsService.MAX_LIMIT)
      )
      .valueChanges({idField: 'id'})
      .pipe(
        map(collection => this.transformCollection(collection)),
        map(models => orderBy(models, ['updatedAt'], ['desc']))
      );
  }

  getNotificationsByType(userId: string, organizationId: string, type: string) {
    // To a user OR organization, singe type
    const refs = this.getRelevantRefs(userId, organizationId);

    return this.afs
      .collection<NotificationDocument>('notifications', (ref: CollectionReference) =>
        ref
          .where('to', 'array-contains-any', refs)
          .where('type', '==', type)
          .orderBy('createdAt', 'desc')
          .limit(NotificationsService.MAX_LIMIT)
      )
      .valueChanges({idField: 'id'})
      .pipe(
        map(collection => this.transformCollection(collection)),
        map(models => orderBy(models, ['updatedAt'], ['desc']))
      );
  }

  getNotificationsByTypeForUser(userId: string, types: string[]) {
    // To a user, multiple types
    const toRef = this.afs.doc(`users/${userId}`).ref;

    return this.afs
      .collection<NotificationDocument>('notifications', (ref: CollectionReference) =>
        ref
          .where('to', 'array-contains', toRef)
          .where('type', 'in', types)
          .orderBy('createdAt', 'desc')
          .limit(NotificationsService.MAX_LIMIT)
      )
      .valueChanges({idField: 'id'})
      .pipe(
        map(collection => this.transformCollection(collection)),
        map(models => orderBy(models, ['updatedAt'], ['desc']))
      );
  }

  getNotificationsByTypeForOrganization(organizationId: string, types: string[]) {
    // To an organization, multiple types
    const toRef = this.afs.doc(`organizations/${organizationId}`).ref;

    return this.afs
      .collection<NotificationDocument>('notifications', (ref: CollectionReference) =>
        ref
          .where('to', 'array-contains', toRef)
          .where('type', 'in', types)
          .orderBy('createdAt', 'desc')
          .limit(NotificationsService.MAX_LIMIT)
      )
      .valueChanges({idField: 'id'})
      .pipe(
        map(collection => this.transformCollection(collection)),
        map(models => orderBy(models, ['updatedAt'], ['desc']))
      );
  }
  /**
   * TODO docs
   * @param notification NotificationModel
   * @param action NotificationAction
   */
  patchStatus(notification: NotificationModel, action: NotificationAction) {
    return this.afs.collection('notifications').doc(notification.id).update({
      status: action,
      updatedAt: serverTimestamp(),
    });
  }

  /**
   * TODO docs
   */
  markAsRead(notifications: NotificationModel[]) {
    const promises = notifications
      .filter(notification => !notification.hasRead)
      .map(notification =>
        this.afs.collection<NotificationDocument>('notifications').doc(notification.id).update({hasRead: true})
      );

    return Promise.all(promises);
  }

  private getRelevantRefs(userId: string, organizationId: string) {
    const refs = [this.afs.doc(`users/${userId}`).ref];
    if (organizationId) {
      refs.push(this.afs.doc(`organizations/${organizationId}`).ref);
    }
    return refs;
  }

  /**
   * TODO docs
   * @param collection WithId<NotificationDocument>[]
   */
  private transformCollection(collection: WithId<NotificationDocument>[]) {
    return collection.map(doc => this.notificationPipe.transform(doc));
  }
}
