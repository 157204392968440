import {HttpClient} from '@angular/common/http';
import {
  Translation,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
} from '@ngneat/transloco';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class HttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(langPath: string) {
    return this.http.get<Translation>(`/assets/i18n/${langPath}.json`);
  }
}

export const translocoLoader = {provide: TRANSLOCO_LOADER, useClass: HttpLoader};

@Injectable()
export class CustomTranslocoMissingHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    console.error('Missing translation key', key);
    if (key.indexOf('config.fields') > -1) {
      return null;
    }
    return key;
  }
}

export const customTranslocoMissingHandler = {
  provide: TRANSLOCO_MISSING_HANDLER,
  useClass: CustomTranslocoMissingHandler,
};
