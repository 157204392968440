<footer *transloco="let t; read: 'app.footer'">
  <div class="footer-content">
    <!-- Web App links -->
    <div id="links">
      <a *ngFor="let link of links" [href]="link.href" class="text-secondary" [class.white-text]="isLoginRoute">{{
        t('links.' + link.text)
      }}</a>
    </div>

    <!-- Copyrights -->
    <span id="copyrights" class="text-secondary" [class.white-text]="isLoginRoute">{{
      t('copyrights', {year: this.year})
    }}</span>
  </div>
</footer>
