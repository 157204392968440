<ng-container *transloco="let t; read: 'app.backfill-menu'">
  <button (click)="backfillDays(1)" mat-menu-item>{{ t('1day') }}</button>
  <button (click)="backfillDays(7)" mat-menu-item>{{ t('7days') }}</button>
  <button (click)="backfillDays(31)" mat-menu-item>{{ t('31days') }}</button>
  <button (click)="backfillDays(92)" mat-menu-item>{{ t('3months') }}</button>
  <button (click)="backfillDays(183)" mat-menu-item>{{ t('6months') }}</button>
  <button (click)="backfillDays(365)" mat-menu-item>{{ t('1year') }}</button>
  <button [matMenuTriggerFor]="dateTimeMenu" mat-menu-item>{{ t('custom') }}</button>

  <mat-menu #dateTimeMenu class="date-range-menu" yPosition="above">
    <form (click)="preventClose($event)" [formGroup]="form">
      <mat-form-field>
        <mat-label>{{ t('custom.startDate') }}</mat-label>
        <input
          [matDatepicker]="from"
          [max]="maxDate"
          [min]="minDate"
          [disabled]="true"
          formControlName="start"
          matInput />
        <mat-datepicker-toggle [for]="from" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #from disabled="false"></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ t('custom.endDate') }}</mat-label>
        <input [matDatepicker]="to" [max]="maxDate" [min]="minDate" [disabled]="true" formControlName="end" matInput />
        <mat-datepicker-toggle [for]="to" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #to disabled="false"></mat-datepicker>
      </mat-form-field>

      <button mat-flat-button (click)="backfillForm($event)" [disabled]="!form.valid">{{ t('custom.submit') }}</button>
    </form>
  </mat-menu>
</ng-container>
