import {Component, Input, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {SignInWithGoogle, SignOut} from '@store/auth/auth.actions';
import {AuthStateModel} from '@store/auth/auth.state';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {BaseComponent} from '@shared/components/base/base.component';
import {Router} from '@angular/router';
import {OrganizationsState} from '@store/organizations/organizations.state';
import {OrganizationModel} from '@pma/shared/types/organization';
import {RemoveOrganizationDirect, SelectOrganization} from '@store/organizations/organizations.actions';
import {Navigate} from '@ngxs/router-plugin';
import {UserModel} from '@pma/shared/types/user';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProgressComponent} from '../../progress/progress.component';

interface NavItemAuth {
  titleKey: string;
  route: string;
  icon: string;
}

@Component({
  selector: 'app-auth-nav-items',
  templateUrl: './auth-nav-items.component.html',
  styleUrls: ['./auth-nav-items.component.scss'],
})
export class AuthNavItemsComponent extends BaseComponent implements OnInit {
  @Select(state => state.auth) authState$: Observable<AuthStateModel>;
  @Select(OrganizationsState.list) organizations$: Observable<OrganizationModel[]>;
  @Select(OrganizationsState.selected) organization$: Observable<OrganizationModel>;
  @Select(state => state.auth.user) user$: Observable<UserModel>;

  @Input() screenSize: string;

  loaderDialog: MatDialogRef<ProgressComponent>;
  isHubnavOpen = false;
  isAdmin = false;

  menuItems: NavItemAuth[] = [
    {
      titleKey: 'hub.settings',
      route: 'settings',
      icon: 'settings',
    },
    {
      titleKey: 'hub.members',
      route: 'members',
      icon: 'people_alt',
    },
    /*
    {
      titleKey: 'hub.usage',
      route: 'usage',
      icon: 'data_usage'
    },
    */
    {
      titleKey: 'hub.billing',
      route: 'billing',
      icon: 'payments',
    },
  ];

  constructor(private dialog: MatDialog, private store: Store, private router: Router) {
    super();
  }

  ngOnInit() {
    this.authState$
      .pipe(
        tap(authState => {
          if (authState.isLoggedIn === undefined && !this.loaderDialog) {
            this.loaderDialog = this.dialog.open(ProgressComponent, {
              panelClass: 'custom-loading-dialog',
            });
            setTimeout(() => {
              if (this.loaderDialog) {
                this.loaderDialog.close();
              }
            }, 5000);
          } else if (this.loaderDialog) {
            this.loaderDialog.close();
          }
        })
      )
      .subscribe();

    this.user$
      .pipe(
        filter(Boolean),
        map((user: UserModel) => Boolean(user.isAdmin)),
        tap(isAdmin => {
          this.isAdmin = isAdmin;

          if (isAdmin && !this.menuItems.find(m => m.titleKey === 'hub.admin')) {
            this.menuItems.push({
              titleKey: 'hub.admin',
              route: 'admin',
              icon: 'admin_panel_settings',
            });
          }
          if (!isAdmin && this.menuItems.find(m => m.titleKey === 'hub.admin')) {
            this.menuItems = this.menuItems.filter(m => m.titleKey !== 'hub.admin');
          }
        })
      )
      .subscribe();
  }

  signInWithGoogle() {
    this.store.dispatch(new SignInWithGoogle(true));
  }

  signOut() {
    this.store.dispatch(SignOut);
  }

  navMenuToggle(isOpen: boolean) {
    this.isHubnavOpen = isOpen;
  }

  switchOrganization(organization: OrganizationModel) {
    const redirectTo = 'organizations/' + organization.id;

    return this.store.dispatch([
      new RemoveOrganizationDirect(),
      new SelectOrganization(organization.id),
      new Navigate([redirectTo]),
    ]);
  }
}
