import {Injectable} from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {filter, map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {ProviderAccountClientDocument} from '@pma/shared/types/account';
import {combineLatest, of} from 'rxjs';
import {AccountPipe} from '@shared/pipes/account/account.pipe';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends BaseService {
  constructor(private afs: AngularFirestore, private accountPipe: AccountPipe) {
    super();
  }

  ref(accountId: string) {
    return this.afs.collection<ProviderAccountClientDocument>('accounts').doc(accountId);
  }

  list(accountIds: string[]) {
    if (accountIds.length === 0) {
      return of([]);
    }

    const accounts = accountIds.map(accountId =>
      this.ref(accountId)
        .valueChanges()
        .pipe(
          filter(Boolean),
          map((account: ProviderAccountClientDocument) => this.accountPipe.transform(account, accountId))
          // catchError(e => {
          //   console.error('missing', accountId);
          //   return e;
          // })
        )
    );
    return combineLatest(accounts);
  }

  async setTimezone(accountId: string, timezone: string): Promise<void> {
    return this.ref(accountId).update({timezone});
  }
}
