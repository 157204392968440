import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {WithId} from '@pma/shared/interfaces/with-id';
import {OrganizationDocument, OrganizationModel} from '@pma/shared/types/organization';

@Pipe({
  name: 'organization',
})
export class OrganizationPipe extends DocumentPipe<OrganizationDocument, OrganizationModel> implements PipeTransform {
  transform(doc: WithId<OrganizationDocument>, ...args: any[]): OrganizationModel {
    doc.connections = new Map(Object.entries(doc.connections));
    doc.connections.forEach((connection: any) => {
      connection.id = connection.ref?.id;
      delete connection.ref;
    });

    const newDoc: OrganizationModel = super.transform(doc) as OrganizationModel;
    newDoc.billing.isActive = ['live', 'trial'].indexOf(newDoc.billing.status) > -1;

    // Set addons
    newDoc.billing.addons = newDoc.billing.addons || {};
    newDoc.billing.addons.account = newDoc.billing.addons.account || 0;
    newDoc.billing.addons.member = newDoc.billing.addons.member || 0;
    newDoc.billing.addons.location = newDoc.billing.addons.location || 0;

    return newDoc;
  }
}
