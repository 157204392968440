import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {ReportDocument, ReportDefinitionModel} from '@pma/shared/types/reports';
import {PublicUserData} from '@pma/shared/types/user';

@Pipe({
  name: 'reports',
})
export class ReportPipe extends DocumentPipe<ReportDocument, ReportDefinitionModel> implements PipeTransform {
  transform(doc: ReportDocument, docId: string, userData: PublicUserData[]): ReportDefinitionModel {
    const newDoc = super.transform(doc);
    newDoc.id = docId;

    // Organization info
    if ('organization' in doc) {
      newDoc.organizationId = doc.organization.id;
      delete doc.organization;
    }

    // Group info, assigned later
    newDoc.groupName = '';

    // User info
    newDoc.ownerEmail = '';
    newDoc.ownerDisplayName = '';
    newDoc.ownerPhotoURL = '';

    const user: PublicUserData = userData.find(u => u.uid === newDoc.userId);
    if (user) {
      newDoc.ownerEmail = user.email;
      newDoc.ownerDisplayName = user.displayName;
      newDoc.ownerPhotoURL = user.photoURL;
    }

    return newDoc;
  }
}
