import {HttpClient} from '@angular/common/http';
import {Component, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html',
  styleUrls: ['./saml.component.scss'],
})
export class SamlComponent {
  @ViewChild('SAML') samlForm: NgForm;
  submitURL = '';
  SAMLResponse = '';
  RelayState = '';
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    const SAMLRequest = this.route.snapshot.queryParamMap.get('SAMLRequest');
    const RelayState = this.route.snapshot.queryParamMap.get('RelayState');
    if (!SAMLRequest) {
      return this.router.navigate(['/']);
    }
    type Res = {AcsUrl: string; SAMLResponse: string; RelayState: string};

    this.http.post(environment.apiEndpoint + '/http-saml/samlp', {SAMLRequest, RelayState}).subscribe({
      next: async (v: Res) => {
        this.SAMLResponse = v.SAMLResponse;
        this.RelayState = v.RelayState;
        this.submitURL = v.AcsUrl;
        window.setTimeout(function () {
          document.forms[0].submit();
        }, 0);
      },
      error: e => {
        return console.error(e);
      },
      complete: () => {},
    });
  }

  onSubmit(form: any, e: any): void {
    e.target.submit();
  }
}
