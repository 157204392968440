import {ConnectorModel} from '@pma/shared/types/connector/connector.model';
import {Dictionary} from '../../../../../../shared/interfaces/dictionary.interface';

export class FetchProviders {
  static readonly type = '[Providers] Fetch Providers Data';
}

export class OpenOAuthWindow {
  static readonly type = '[Providers] Open OAuth Window';

  constructor(public providerType: string, public organizationId: string, public extra?: Dictionary) {}
}

export class AddProviderCredentialsAccount {
  static readonly type = '[Providers] Add Account to Provider by Client Credentials';

  constructor(public providerType: string, public organizationId: string, public formValue: any) {}
}

export class RemoveProviderAccount {
  static readonly type = '[Providers] Remove Account from Provider';

  constructor(public accountId: string, public type: string, public name: string) {}
}

export class FetchProviderCredentialsFields {
  static readonly type = '[Providers] Fetch Provider Credentials Fields';

  constructor(public providerType: string) {}
}

export class RemoveAccountUsage {
  static readonly type = '[Providers] Remove Account Usage';

  constructor(
    public organizationId: string,
    public accountId: string,
    public connector: string,
    public usageKey: string,
    public usageName: string
  ) {}
}

export class UpdateAccountUsageActive {
  static readonly type = '[Providers] Update Account Usage Active';

  constructor(
    public organizationId: string,
    public accountId: string,
    public connector: string,
    public usageKey: string,
    public usageName: string,
    public isActive: boolean
  ) {}
}

export class AddAccountUsage {
  static readonly type = '[Providers] Add Account Usage';

  constructor(
    public organizationId: string,
    public accountId: string,
    public connector: string,
    public configType: string,
    public usageKeys: string[],
    public usageNames: string[]
  ) {}
}

export class OpenConnectorInLookerStudio {
  static readonly type = '[Providers] Open Connector In Looker Studio';
  static readonly eventParams = ['connectorType'];

  connectorType: string = this.connector.type;

  constructor(public connector: ConnectorModel) {}
}

export class GetAccountScopes {
  static readonly type = '[Providers] Get Account Scopes';

  constructor(public organizationId: string, public accountId: string) {}
}
