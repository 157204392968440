<div *transloco="let t; read: 'organizations.organization.integrations.google-sheets'" class="table-wrapper">
  <table [dataSource]="dataSource" mat-table matSort>
    <!-- Connectors Column -->
    <ng-container matColumnDef="connectors">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('connectors') }}</th>
      <td *matCellDef="let imp" mat-cell>
        <ng-container *ngIf="connectors$ | async as connectors">
          <div class="flex-row">
            <app-picture
              *ngFor="let sheetConnector of imp.connectors.slice(0, 5)"
              [alt]="connectors.entities[sheetConnector]?.title"
              [formats]="['svg', 'png']"
              [matTooltip]="connectors.entities[sheetConnector]?.title"
              [matTooltipClass]="'explanation-tooltip'"
              [srcset]="connectors.entities[sheetConnector]?.logoPath"></app-picture>
          </div>

          <div *ngIf="imp.connectors.length > 5" class="connectors-more additional_info">
            + {{ imp.connectors.length - 5 }} connectors
          </div>
        </ng-container>
      </td>
    </ng-container>

    <!-- Spreadhsheet ID Column, test only -->
    <ng-container matColumnDef="spreadsheet">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('spreadsheet') }}</th>
      <td *matCellDef="let imp" mat-cell>{{ imp.spreadsheetId }}</td>
    </ng-container>

    <!-- Spreadhsheet NameColumn -->
    <ng-container matColumnDef="spreadsheetName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('spreadsheet') }}</th>
      <td *matCellDef="let imp" class="th-left" mat-cell>{{ imp.spreadsheetName }}</td>
    </ng-container>

    <!-- Owner Column -->
    <ng-container matColumnDef="owner">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('owner') }}</th>
      <td *matCellDef="let imp" mat-cell>
        <img
          *ngIf="imp.ownerPhotoURL"
          [src]="imp.ownerPhotoURL"
          alt="avatar"
          class="auth-avatar"
          [matTooltip]="imp.ownerDisplayName"
          [matTooltipClass]="'explanation-tooltip'" />
        <span *ngIf="!imp.ownerPhotoURL && imp.ownerDisplayName != ''">{{ imp.ownerDisplayName }}</span>
        <span *ngIf="!imp.ownerPhotoURL && imp.ownerDisplayName == ''">{{
          'organizations.organization.integrations.google-sheets.userOutsideOrganization' | transloco
        }}</span>
      </td>
    </ng-container>

    <!-- Schedule Column -->
    <ng-container matColumnDef="schedule">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('schedule') }}</th>
      <td *matCellDef="let imp" mat-cell>
        <span>{{ imp.refreshScheduleParsed }}</span>
        <div *ngIf="imp.refreshScheduleParsed != ''" class="additional_info">({{ imp.refreshTimezone }})</div>
      </td>
    </ng-container>

    <!-- Created Date Column -->
    <ng-container matColumnDef="createdAt">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('createdAt') }}</th>
      <td *matCellDef="let imp" mat-cell>{{ imp.createdAt | date }}</td>
    </ng-container>

    <!-- Updated Date Column -->
    <ng-container matColumnDef="updatedAt">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('updatedAt') }}</th>
      <td *matCellDef="let imp" mat-cell>{{ imp.updatedAt | date }}</td>
    </ng-container>

    <!-- Accessed Date Column -->
    <ng-container matColumnDef="accessedAt">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('accessedAt') }}</th>
      <td *matCellDef="let imp" mat-cell>{{ imp.accessedAt | date }}</td>
    </ng-container>

    <!-- Actions column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>{{ t('actions') }}</th>
      <td *matCellDef="let imp" mat-cell>
        <div class="flex-row">
          <!-- View Sheet Button (Google Sheets) -->
          <img
            (click)="openSheetGoogle(imp)"
            *ngIf="imp.platform == 'sheets'"
            [matTooltip]="t('actions.openSheetGoogle')"
            [matTooltipClass]="'explanation-tooltip'"
            class="icon-sheets"
            src="../../../../assets/icons/google-sheets.png" />

          <!-- View Sheet Button (Excel, online and desktop) -->
          <img
            (click)="openSheetExcel(imp)"
            *ngIf="imp.platform == 'excel' && !isExcelDesktop(imp)"
            [matTooltip]="t('actions.openSheetExcel')"
            [matTooltipClass]="'explanation-tooltip'"
            class="icon-sheets"
            src="../../../../assets/icons/excel.png" />

          <img
            *ngIf="imp.platform == 'excel' && isExcelDesktop(imp)"
            [matTooltip]="t('actions.openSheetExcelDesktop')"
            [matTooltipClass]="'explanation-tooltip'"
            class="icon-sheets"
            src="../../../../assets/icons/excel-desktop.png" />

          <!-- View Logs Button -->
          <button
            mat-icon-button
            [matTooltip]="'actions.viewLogs' | transloco"
            [matTooltipClass]="'explanation-tooltip'"
            [queryParamsHandling]="'merge'"
            [queryParams]="{logs: 'sheets/' + imp.id}"
            [routerLink]="[]">
            <mat-icon fontSet="material-icons-outlined">description</mat-icon>
          </button>

          <!-- Delete scheduled refresh -->
          <button
            mat-icon-button
            *ngIf="imp.platform == 'sheets' && imp.refreshScheduleParsed != ''"
            (click)="deleteScheduledRefresh(imp.spreadsheetId)"
            [matTooltip]="t('actions.deleteScheduledRefresh')"
            [matTooltipClass]="'explanation-tooltip'">
            <mat-icon fontSet="material-icons-outlined" class="color-warn">watch_later</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" id="table-columns" mat-header-row></tr>
    <tr *matRowDef="let imp; columns: displayedColumns" mat-row></tr>
  </table>

  <!--  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>-->
</div>
