import {Component, Input, OnInit} from '@angular/core';
import {BackfillJobItem} from '@store/connectors/connectors.state';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BaseComponent} from '@shared/components/base/base.component';
import {takeUntil, tap} from 'rxjs/operators';
import {dayTime, toShortISO} from '@pma/shared/utils/date/date';
import {readableName} from '@pma/shared/utils/snake-to-camel/readable-name';

@Component({
  selector: 'app-usage-history-gaps',
  templateUrl: './usage-history-gaps.component.html',
  styleUrls: ['./usage-history-gaps.component.scss'],
})
export class UsageHistoryGapsComponent extends BaseComponent implements OnInit {
  @Input() data: BackfillJobItem[];

  form: FormGroup;
  xScaleInitial = 0.6;

  availableTypes: string[] = [];
  availableTypesReadable: string[] = [];
  displayData: any[];

  chartSize = {
    height: '',
    width: '',
  };

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    // Get backfill table types
    const types = new Set<string>();
    this.data.forEach(d => types.add(d.subType));
    this.availableTypes = Array.from(types).sort();
    this.availableTypesReadable = this.availableTypes.map(t => readableName(t));

    // Form with horizontal scaling slider
    this.form = this.formBuilder.group({
      xScale: this.xScaleInitial,
    });

    this.form.valueChanges
      .pipe(
        tap(settings => this.updateData(settings)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();

    // Initial form update
    this.form.patchValue({xScale: this.xScaleInitial});
  }

  updateData(settings) {
    const h = 24;
    const hMargin = 16;
    const w = (1 / (1 - settings.xScale)) * 5;
    let chartWidth = 0;

    // Jobs not finished within 24 hours after they start are considered finished and failed
    const now = new Date();

    this.displayData = this.data
      .filter(d => d.finishDate && d.status !== 'failed')
      .map(d => {
        const typeIndex = this.availableTypes.indexOf(d.subType);
        const daysAgoX = (Date.now() - new Date(d.end).getTime()) / dayTime;
        const daysLength = 1 + (new Date(d.end).getTime() - new Date(d.start).getTime()) / dayTime;

        const right = daysAgoX * w;
        const width = daysLength * w;
        chartWidth = Math.max(chartWidth, right + width);

        const dateCreated = new Date(d.date).toLocaleDateString() + ' ' + new Date(d.date).toLocaleTimeString();
        const tooltipString = 'Job created on ' + dateCreated + '\n' + toShortISO(d.start) + ' to ' + toShortISO(d.end);

        return {
          ...d,
          style: {
            backgroundColor: 'green',
            height: `${h}px`,
            top: `${typeIndex * (h + hMargin)}px`,
            width: `${width}px`,
            right: `${right}px`,
          },
          tooltip: tooltipString,
        };
      });

    this.chartSize = {
      height: `${h}px`,
      width: `${chartWidth}px`,
    };

    // console.log('Chart data:', this.chartSize, this.displayData.slice(0, 10));
  }
}
