<div *transloco="let t; read: 'app.logsDialog.logsTable'" id="logs-table">
  <table [dataSource]="dataSource" mat-table matSort>
    <!-- ID Column -->
    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell>{{ t('type') }}</th>
      <td *matCellDef="let log" mat-cell>
        <ng-container *ngIf="log.type == 'account.usageUpdated'">
          <ng-container *ngIf="log.params && log.params.usageNames && log.params.usageNames.length > 0">{{
            t(log.type, {usageName: log.params.usageNames[0] || 'the account'})
          }}</ng-container>
        </ng-container>
        <ng-container *ngIf="log.type !== 'account.usageUpdated'">
          {{ t(log.type, log.params) }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="createdAt">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ t('createdAt') }}</th>
      <td *matCellDef="let log" mat-cell>{{ log.createdAt | date: 'long' }}</td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" id="table-columns" mat-header-row></tr>
    <tr *matRowDef="let log; columns: displayedColumns" mat-row></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
</div>
