import {Component, Inject} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Select, Store} from '@ngxs/store';
import {BaseComponent} from '@shared/components/base/base.component';
import {AddProviderCredentialsAccount} from '@store/providers/providers.actions';
import {Observable} from 'rxjs';
import {ConfigItem} from '@pma/shared/types/connector';
import {takeUntil, tap} from 'rxjs/operators';
import {ProviderType} from '@pma/shared/types/connector-provider';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface ProviderDialogData {
  type: ProviderType;
  callback?: CallableFunction;
}

@Component({
  selector: 'app-provider-credentials-dialog',
  templateUrl: './provider-credentials-dialog.component.html',
  styleUrls: ['./provider-credentials-dialog.component.scss'],
})
export class ProviderCredentialsDialogComponent extends BaseComponent {
  @Select(state => state.connectorsProviders.credentialsFields) fields$: Observable<ConfigItem[]>;

  form: FormGroup = new FormGroup({}); // Initialize empty form

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProviderDialogData,
    private dialogRef: MatDialogRef<ProviderCredentialsDialogComponent>,
    private store: Store,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  async connect(isAutoPopulated = false) {
    if (isAutoPopulated) {
      // Add query params to form for callback
      Object.keys(this.route.snapshot.queryParams)
        .filter(f => ['orgRoute', 'provider'].indexOf(f) === -1)
        .forEach(f => (this.form.value[f] = this.route.snapshot.queryParams[f]));
      // console.log('Callback form values', this.form.value);
    }

    const organizationId = this.store.snapshot().organizations.selectedId;
    if (this.data.callback) {
      // If it has explicit callback, for example, 2-step-auth
      this.data.callback(this.form.value);
      return this.dialogRef.close();
    } else {
      this.store
        .dispatch(new AddProviderCredentialsAccount(this.data.type, organizationId, this.form.value))
        .pipe(
          tap(() => {
            this.dialogRef.close();
            this.openSnackBar('Connection successful!', 'Close');
          }),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 7000,
    });
  }
}
