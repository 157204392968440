import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {SheetDocument, SheetModel} from '@pma/shared/types/sheets';
import {PublicUserData} from '@pma/shared/types/user';

@Pipe({
  name: 'sheets',
})
export class SheetPipe extends DocumentPipe<SheetDocument, SheetModel> implements PipeTransform {
  transform(doc: SheetDocument, docId: string, userData: PublicUserData[]): SheetModel {
    const newDoc = super.transform(doc);
    newDoc.id = docId;

    // Set friendly schedule

    newDoc.refreshScheduleParsed = '';
    if (doc.refreshSchedule) {
      newDoc.refreshScheduleParsed = this.parseSchedule(doc.refreshSchedule, doc.refreshTimezone);
    }

    // Set owner
    // When the platform is 'sheets' (and the spreadsheetOwner email exists), then find the user by email
    // Otherwise assume the owner is the first user in 'users' array and find the user by ID

    newDoc.ownerDisplayName = '';
    newDoc.ownerPhotoURL = '';

    const userIdFirst = newDoc.users.length > 0 ? newDoc.users[0] : null;
    let user: PublicUserData;

    if (newDoc.platform === 'sheets' && newDoc.spreadsheetOwner !== undefined && newDoc.spreadsheetOwner !== '') {
      user = userData.find(u => u.email === newDoc.spreadsheetOwner); // Match on owner email for Sheets
    } else if (userIdFirst !== null) {
      user = userData.find(u => u.uid === userIdFirst); // Match on user ID
    }

    if (user) {
      newDoc.ownerDisplayName = user.displayName;
      newDoc.ownerPhotoURL = user.photoURL;
    } else {
      // console.log('Sheets user unknown:', doc.spreadsheetId, userData, userIdFirst, newDoc.spreadsheetOwner);
    }

    return newDoc;
  }

  parseSchedule(refreshSchedule: string, refreshTimzone: string): string {
    // Parse refresh schedule: [none,month=1,week=0,day,hour]:13

    const refreshPeriods = [
      {name: 'None', id: 'none', disabled: false},
      {name: 'Monthly', id: 'month', disabled: false},
      {name: 'Weekly', id: 'week', disabled: false},
      {name: 'Daily', id: 'day', disabled: false},
      {name: 'Hourly', id: 'hour', disabled: true},
    ];

    const refreshWeekDays = [
      {name: 'Sunday', id: '0'},
      {name: 'Monday', id: '1'},
      {name: 'Tuesday', id: '2'},
      {name: 'Wednesday', id: '3'},
      {name: 'Thursday', id: '4'},
      {name: 'Friday', id: '5'},
      {name: 'Saturday', id: '6'},
    ];

    const refreshMonthDays = [
      {name: '1st', id: '1'},
      {name: '2nd', id: '2'},
      {name: '3rd', id: '3'},
      {name: '4th', id: '4'},
      {name: '5th', id: '5'},
      {name: '6th', id: '6'},
      {name: '7th', id: '7'},
      {name: '8th', id: '8'},
      {name: '9th', id: '9'},
      {name: '10th', id: '10'},
      {name: '11th', id: '11'},
      {name: '12th', id: '12'},
      {name: '13th', id: '13'},
      {name: '14th', id: '14'},
      {name: '15th', id: '15'},
      {name: '16th', id: '16'},
      {name: '17th', id: '17'},
      {name: '18th', id: '18'},
      {name: '19th', id: '19'},
      {name: '20th', id: '20'},
      {name: '21st', id: '21'},
      {name: '22nd', id: '22'},
      {name: '23rd', id: '23'},
      {name: '24th', id: '24'},
      {name: '25th', id: '25'},
      {name: '26th', id: '26'},
      {name: '27th', id: '27'},
      {name: '28th', id: '28'},
      {name: '29th', id: '29'},
      {name: '30th', id: '30'},
      {name: '31st', id: '31'},
    ];

    const refreshHours = [
      {name: '0:00 (midnight)', id: '0'},
      {name: '1:00 AM', id: '1'},
      {name: '2:00 AM', id: '2'},
      {name: '3:00 AM', id: '3'},
      {name: '4:00 AM', id: '4'},
      {name: '5:00 AM', id: '5'},
      {name: '6:00 AM', id: '6'},
      {name: '7:00 AM', id: '7'},
      {name: '8:00 AM', id: '8'},
      {name: '9:00 AM', id: '9'},
      {name: '10:00 AM', id: '10'},
      {name: '11:00 AM', id: '11'},
      {name: '12:00 PM', id: '12'},
      {name: '13:00 (1 PM)', id: '13'},
      {name: '14:00 (2 PM)', id: '14'},
      {name: '15:00 (3 PM)', id: '15'},
      {name: '16:00 (4 PM)', id: '16'},
      {name: '17:00 (5 PM)', id: '17'},
      {name: '18:00 (6 PM)', id: '18'},
      {name: '19:00 (7 PM)', id: '19'},
      {name: '20:00 (8 PM)', id: '20'},
      {name: '21:00 (9 PM)', id: '21'},
      {name: '22:00 (10 PM)', id: '22'},
      {name: '23:00 (11 PM)', id: '23'},
    ];

    // Parse schedule

    let refreshPeriod = 'week';
    let refreshPeriodValue = '1';
    let refreshHour = '7';

    const scheduleParts = refreshSchedule.split(':');
    const recurrance = scheduleParts[0];

    if (scheduleParts.length > 1) {
      refreshHour = scheduleParts[1];
    }

    const recurranceParts = recurrance.split('=');
    refreshPeriod = recurranceParts[0];

    if (recurranceParts.length > 1) {
      refreshPeriodValue = recurranceParts[1];
    }

    // console.log('Refresh schedule parsed:', recurrance, refreshPeriod, refreshPeriodValue, refreshHour);

    // Build friendly schedule string

    let scheduleParsed = refreshPeriods.find(r => r.id === refreshPeriod).name;

    switch (refreshPeriod) {
      case 'none': // No scheduled refresh
      case 'hour': // Hourly
        break;

      case 'month': // Monthly on the 2nd at 5pm
        scheduleParsed = scheduleParsed + ' on the ' + refreshMonthDays.find(r => r.id === refreshPeriodValue).name;
        scheduleParsed = scheduleParsed + ' at ' + refreshHours.find(r => r.id === refreshHour).name;
        break;

      case 'week': // Weekly every Monday at 5pm
        scheduleParsed = scheduleParsed + ' every ' + refreshWeekDays.find(r => r.id === refreshPeriodValue).name;
        scheduleParsed = scheduleParsed + ' at ' + refreshHours.find(r => r.id === refreshHour).name;
        break;

      case 'day': // Daily at 5pm
        scheduleParsed = scheduleParsed + ' at ' + refreshHours.find(r => r.id === refreshHour).name;
        break;
    }

    return scheduleParsed;
  }
}
