import {Injectable} from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {OrganizationModel, OrganizationModelBilling} from '@pma/shared/types/organization';
import {UserModel} from '@pma/shared/types/user';
import {toShortISO} from '@pma/shared/utils/date/date';

@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseService {
  constructor() {
    super();
  }

  isBillingActive(billing: OrganizationModelBilling) {
    return ['live', 'trial'].indexOf(billing.status) > -1;
  }

  isBillingCancelled(billing: OrganizationModelBilling) {
    return ['cancelled', 'cancelled_from_dunning'].indexOf(billing.status) > -1;
  }

  isBillingExpired(billing: OrganizationModelBilling) {
    return ['expired', 'trial_expired'].indexOf(billing.status) > -1;
  }

  isBillingUnpaid(billing: OrganizationModelBilling) {
    return ['dunning', 'unpaid'].indexOf(billing.status) > -1;
  }

  isBillingPaused(billing: OrganizationModelBilling) {
    return ['paused'].indexOf(billing.status) > -1;
  }

  isBillingPending(billing: OrganizationModelBilling) {
    return ['dunning', 'unpaid', 'paused', 'trial_expired'].indexOf(billing.status) > -1;
  }

  isOwner(organization: OrganizationModel, user: UserModel): boolean {
    // Is the user a hub owner?
    if (organization.users[user.uid] && organization.users[user.uid] === 'owner') {
      return true;
    }

    return false;
  }

  isBillingContact(organization: OrganizationModel, user: UserModel): boolean {
    // Is the user a billing contact?
    if (organization.billing.users && organization.billing.users.indexOf(user.uid) > -1) {
      return true;
    }

    return false;
  }

  canChangeSubscriptionStatus(organization: OrganizationModel, user: UserModel): boolean {
    // Is the user allowed to update the subscription (cancelling, re-activating, pausing/unapusing)?
    if (organization?.settings?.security?.subscriptionAndBilling?.subscriptionOnlyOwner) {
      return this.isOwner(organization, user);
    }

    return true;
  }

  canChangeBilling(organization: OrganizationModel, user: UserModel): boolean {
    // Is the user allowed to update the billing information (update payment method, remove payment, pay now)?
    if (organization?.settings?.security?.subscriptionAndBilling?.billingOnlyOwnerAndContacts) {
      if (!this.isOwner(organization, user) && !this.isBillingContact(organization, user)) {
        return false;
      }
    }

    return true;
  }
  // enable button to be visible if there is a payment method. reduced conditions according to Morgan request
  canRemovePayment(organization: OrganizationModel, user: UserModel): boolean {
    if (organization.billing.paymentMethod) {
      return true;
    }
    return false;
  }

  canPayNow(organization: OrganizationModel, user: UserModel): boolean {
    // Allow immediate payment when a payment method exists and subscription is in an upaid status
    if (organization.billing.paymentMethod && this.isBillingUnpaid(organization.billing)) {
      return true;
    }

    return false;
  }

  canCancel(organization: OrganizationModel, user: UserModel): boolean {
    // A subscription cannot be cancelled from statuses: 'cancelled', 'cancelled_from_dunning', 'trial_expired'
    if (
      organization.billing.status !== 'cancelled' &&
      organization.billing.status !== 'cancelled_from_dunning' &&
      organization.billing.status !== 'trial_expired'
    ) {
      return true;
    }

    return false;
  }

  canReactivate(organization: OrganizationModel, user: UserModel): boolean {
    if (!this.isBillingCancelled(organization.billing)) {
      return false;
    }

    // A cancelled subscription can be reactivated when it was cancelled while 'live' and a payment method exists
    if (organization.billing.paymentMethod) {
      return true;
    }

    // A cancelled subscription can be reactivated when it was cancelled during 'trial'
    if (
      organization.billing.lastBilling.date &&
      toShortISO(organization.billing.lastBilling.date) === toShortISO(organization.createdAt)
    ) {
      return true;
    }

    return false;
  }

  canPause(organization: OrganizationModel, user: UserModel): boolean {
    if (organization.billing.status === 'live') {
      return true;
    }

    return false;
  }

  canUnpause(organization: OrganizationModel, user: UserModel): boolean {
    // Require a payment method to exist
    if (organization.billing.status === 'paused' && organization.billing.paymentMethod) {
      return true;
    }

    return false;
  }
}
