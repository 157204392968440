import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Dictionary} from '@pma/shared/interfaces/dictionary.interface';

interface PromptDialogData {
  title: string;
  content: string;
  contentList: string[];
  contentDictionary: Dictionary<string>;
}

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
})
export class PromptDialogComponent {
  title: string;
  content: string;
  contentList: string[];
  contentDictionary: Dictionary<string>;

  // Extract link
  contentBeforeLink: string;
  contentAfterLink: string;
  url: string;
  urlLabel: string;

  constructor(
    private dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PromptDialogData
  ) {
    this.title = data.title;
    this.content = data.content;
    this.contentList = data.contentList;
    this.contentDictionary = data.contentDictionary;

    if (this.content && this.content.indexOf('https://') > -1) {
      this.extractLink();
    }
  }

  extractLink() {
    // Extract link from prompt content
    const indexStart = this.content.indexOf('https://');
    let indexEnd = this.content.indexOf(' ', indexStart);
    if (indexEnd === -1) {
      indexEnd = this.content.length;
    }

    // The link label will be 'Click here for more information' (for support links) or 'here' (for other links)
    this.urlLabel = 'Click here for more information';
    let urlLeadUp = '';
    if (this.content.indexOf(' at https://') > -1) {
      this.urlLabel = 'here';
      urlLeadUp = ' at ';
    } else if (this.content.indexOf(' to https://') > -1) {
      this.urlLabel = 'here';
      urlLeadUp = ' to ';
    }

    this.url = this.content.substring(indexStart, indexEnd);
    this.contentBeforeLink = this.content.substring(0, indexStart).replace(' | ', ' ');
    this.contentAfterLink = this.content.substring(indexEnd);

    if (urlLeadUp !== '') {
      this.contentBeforeLink = this.contentBeforeLink.replace(urlLeadUp, ' ');
    }

    // console.log('Content orginal:', this.content);
    // console.log('Content, link extracted:', this.contentBeforeLink, this.contentAfterLink, this.url, this.urlLabel, urlLeadUp);
  }

  close() {
    this.dialogRef.close();
  }
}
