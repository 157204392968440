<div
  class="app-container mat-app-background"
  *ngIf="{isScreenSmall: isScreenSmall$ | async} as context"
  [class.is-small-screen]="isSmallScreen">
  <!-- Header -->
  <app-nav
    *ngIf="(isIframe$ | async) === false"
    [isSidenavOpen]="isSidenavOpen"
    [isLoginRoute]="isLoginRoute"
    [isDarkMode]="isDarkMode"
    [screenSize]="screenSize"
    (toggleSidenav)="toggleSidenav(sidenav)"
    (toggleTheme)="toggleTheme()"></app-nav>

  <mat-sidenav-container class="sidenav-container" [class.login-route]="isLoginRoute" autosize>
    <!-- Sidenav -->
    <mat-sidenav
      #sidenav
      [opened]="!isLoginRoute && !isSmallScreen"
      [mode]="isSmallScreen ? 'over' : 'side'"
      [fixedInViewport]="isSmallScreen"
      [fixedTopGap]="64"
      (openedChange)="sidenavOpenChanged($event)">
      <app-nav-side [params]="params"></app-nav-side>
    </mat-sidenav>

    <!-- Route -->
    <mat-sidenav-content class="sidenav-content sidenav-background" [class.background-login]="isLoginRoute">
      <!-- Background image, only shown on login page -->
      <div class="background-login-bars" [class.hidden]="!isLoginRoute"></div>

      <!-- Banner -->
      <app-banner *ngIf="(isIframe$ | async) === false"></app-banner>

      <!-- Page content -->
      <div id="wrap-router">
        <router-outlet></router-outlet>
      </div>

      <!-- Footer, not shown on login page -->
      <app-footer
        *ngIf="(isIframe$ | async) === false"
        [class.hidden]="isLoginRoute"
        [isLoginRoute]="isLoginRoute"></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
