import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {BaseComponent} from '@shared/components/base/base.component';
import {
  OrganizationBillingStatus,
  OrganizationModel,
  OrganizationModelIntegration,
} from '@pma/shared/types/organization';
import {OrganizationsState} from '@store/organizations/organizations.state';
import {SetIntegrationStatus} from '@store/organizations/organizations.actions';
import {ConfirmDialogComponent} from '@shared/components/confirm-dialog/confirm-dialog.component';
import {TranslocoService} from '@ngneat/transloco';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
})
export class IntegrationComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Select(OrganizationsState.selected) organization$: Observable<OrganizationModel>;

  @Input() page: string;
  @Input() type: string;
  @Input() layout = ''; // Special positioning
  @ViewChild(MatSlideToggle, {static: false}) slideToggle: MatSlideToggle;

  translationPath = 'organizations.organization.integrations.status';
  organizationId;

  integration: OrganizationModelIntegration;
  integrationEnabled = false;
  integrationStatus = 'none';
  integrationMessage = '';
  integrationMessagePricing = false;
  canToggleIntegration = true;

  trialEndDate = new Date();
  trialDays = 14;
  trialDaysRemaining = 0;
  isTrialPeriod = false;

  linkAddonSheets = 'https://workspace.google.com/marketplace/app/data_connnector/606653648723';
  linkAddonExcel = 'https://appsource.microsoft.com/en-us/product/office/WA200002101';

  constructor(
    private store: Store,
    private transloco: TranslocoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.organization$
      .pipe(
        filter(Boolean),
        tap((organization: OrganizationModel) => {
          this.organizationId = organization.id;

          this.integrationStatus = 'none';
          this.integrationEnabled = false;
          this.integrationMessage = 'Create a report to get started';
          this.integrationMessagePricing = false;

          if (organization.integrations && organization.integrations[this.type]) {
            // Integration exists
            this.integration = organization.integrations[this.type];
            this.integrationStatus = this.integration.status;
            this.integrationEnabled = this.integration.status === 'trial' || this.integration.status === 'live';

            // Set trial period info
            this.trialEndDate = new Date(this.integration.usedFirst.getTime());
            this.trialEndDate.setDate(this.trialEndDate.getDate() + this.trialDays);
            this.trialDaysRemaining = Math.ceil(
              (this.trialEndDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
            );
            this.isTrialPeriod = new Date().getTime() < this.trialEndDate.getTime();
          } else {
            // Integration does not exist
            this.integrationMessage = this.transloco.translate(this.translationPath + '.trialStart', {
              days: this.trialDays,
            });
            this.integrationMessagePricing = true;
            this.isTrialPeriod = true;
          }

          // Set slide toggle state
          if (this.slideToggle) {
            this.slideToggle.checked = this.integrationEnabled;
          }
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (this.slideToggle) {
      this.slideToggle.checked = this.integrationEnabled;
    }
  }

  integrationSetStatusSlider(event: MatSlideToggleChange) {
    const isChecked = event.checked;
    const isCheckedPrevious = !isChecked;

    // Set confirmation messages and new status (if confirmed)
    let integrationTypeLabel = '';
    switch (this.type) {
      case 'sheets':
        integrationTypeLabel = 'Spreadsheets';
        break;
      case 'sql':
        integrationTypeLabel = 'SQL';
        break;
    }

    let statusNew;
    let messageConfirm;
    let messageNotify;
    let messageBilling;
    let isStartIntegration = false;

    if (this.integrationStatus === 'trial' || this.integrationStatus === 'live') {
      // Disabling integration
      statusNew = 'paused';

      messageConfirm = this.transloco.translate(this.translationPath + '.actionPauseConfirm', {
        integrationType: integrationTypeLabel,
      });
      messageBilling = this.transloco.translate(this.translationPath + '.actionPauseBilling', {
        integrationType: integrationTypeLabel,
      });
      messageNotify = this.transloco.translate(this.translationPath + '.actionPaused', {
        integrationType: integrationTypeLabel,
      });
    } else {
      // Enabling integration. If the trial period is not over yet, set the status back to 'trial' instead of 'live'
      statusNew = 'live';
      if (this.isTrialPeriod) {
        statusNew = 'trial';
      }

      if (this.integrationStatus === 'none') {
        isStartIntegration = true;
        messageConfirm = this.transloco.translate(this.translationPath + '.actionStartConfirm', {
          integrationType: integrationTypeLabel,
        });
        messageBilling = this.transloco.translate(this.translationPath + '.actionStartBilling', {
          integrationType: integrationTypeLabel,
        });
        messageNotify = this.transloco.translate(this.translationPath + '.actionStarted', {
          integrationType: integrationTypeLabel,
        });
      } else {
        messageConfirm = this.transloco.translate(this.translationPath + '.actionResumeConfirm', {
          integrationType: integrationTypeLabel,
        });
        messageBilling = this.transloco.translate(this.translationPath + '.actionResumeBilling', {
          integrationType: integrationTypeLabel,
        });
        messageNotify = this.transloco.translate(this.translationPath + '.actionResumed', {
          integrationType: integrationTypeLabel,
        });
      }
    }

    // console.log('Trial end date, status new:', this.trialEndDate, statusNew);

    // Update integration if changed is confirmed
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: messageConfirm,
        content: messageBilling,
      },
    });
    dialogRef.componentInstance.action
      .pipe(
        tap(result => {
          if (!result) {
            // If not confirmed, set slider status to previous value
            this.slideToggle.checked = isCheckedPrevious;
          }
        }),
        filter(Boolean),
        tap(() => (dialogRef.componentInstance.isLoading = true)),
        switchMap(() =>
          this.store.dispatch(
            new SetIntegrationStatus(this.type as OrganizationBillingStatus, statusNew, isStartIntegration)
          )
        ),
        tap(() => {
          this.integrationEnabled = statusNew === 'trial' || statusNew === 'live';

          dialogRef.close();
          this.snackBar.open(messageNotify, null, {
            duration: 3000,
          });
        }),
        catchError(this.handleError.bind(this)),
        takeUntil(combineLatest([this.ngUnsubscribe, dialogRef.afterClosed()]))
      )
      .subscribe();
  }

  private handleError(e: Error) {
    this.snackBar.open(e.message, null, {
      panelClass: 'color-background-warn',
      duration: 5000,
    });

    return of(e);
  }
}
