import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {TemplateDocument, TemplateModel} from '@pma/shared/types/template';

@Pipe({
  name: 'templates',
})
export class TemplatePipe extends DocumentPipe<TemplateDocument, TemplateModel> implements PipeTransform {
  transform(doc: TemplateDocument, docId: string): TemplateModel {
    const newDoc = super.transform(doc);
    newDoc.id = docId;
    newDoc.images = [];

    return newDoc;
  }
}
