<ng-container *ngIf="fields$ | async as fields; else loading">
  <form *ngIf="fields.length > 0; else loading" [formGroup]="form">
    <h2 mat-dialog-title>Add Account</h2>

    <mat-dialog-content>
      <app-config-form [fields]="fields" [form]="form" (submitForm)="connect(true)"></app-config-form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button (click)="connect()" [disabled]="!form.valid">Connect</button>
    </mat-dialog-actions>
  </form>
</ng-container>

<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
