<div *transloco="let t; read: 'organizations.organization.usage.history.table'">
  <ng-container *ngIf="data.length == 0; else table">
    <p>{{ t('noBackfillData') }}</p>
  </ng-container>

  <ng-template #table>
    <div class="table-wrapper">
      <table [dataSource]="dataSource" mat-table matSort>
        <ng-container matColumnDef="date">
          <th *matHeaderCellDef mat-header-cell>{{ t('date') }}</th>
          <td *matCellDef="let datum" mat-cell>
            <!-- {{ datum.date | date: 'medium' }} -->
            {{ datum.date | date: 'medium':datum.accountTimezoneAbbreviation }} ({{ accountTimezone }})
          </td>
        </ng-container>
        <ng-container matColumnDef="subType">
          <th *matHeaderCellDef mat-header-cell>{{ t('subType') }}</th>
          <td *matCellDef="let datum" mat-cell>{{ datum.subType }}</td>
        </ng-container>
        <ng-container matColumnDef="start">
          <th *matHeaderCellDef mat-header-cell>{{ t('start') }}</th>
          <td *matCellDef="let datum" mat-cell>{{ datum.start | date }}</td>
        </ng-container>
        <ng-container matColumnDef="end">
          <th *matHeaderCellDef mat-header-cell>{{ t('end') }}</th>
          <td *matCellDef="let datum" mat-cell>{{ datum.end | date }}</td>
        </ng-container>
        <ng-container matColumnDef="finishDate">
          <th *matHeaderCellDef mat-header-cell>{{ t('finishDate') }}</th>
          <td *matCellDef="let datum" mat-cell>
            <!-- {{ datum.finishDate | date: 'medium' }} -->
            {{ datum.finishDate | date: 'medium':datum.accountTimezoneAbbreviation }} ({{ accountTimezone }})
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell>{{ t('status') }}</th>
          <td *matCellDef="let datum" mat-cell>
            <span *ngIf="datum.status === 'notFinished'">{{ t('statusNotFinished') }}</span>
            <span *ngIf="datum.status === 'finished'">{{ t('statusFinished') }}</span>
            <div *ngIf="datum.status === 'finishedWithErrors'" class="flex-row-start-nowrap flex-row-align-center">
              <span>{{ t('statusFinishedWithErrors') }}</span>

              <!-- Count and list of jobs with error status (admins only) -->
              <mat-icon
                fontSet="material-icons-outlined"
                class="color-warn job-failed"
                *ngIf="isAdmin"
                [matTooltip]="datum.jobTypesFailed"
                [matTooltipClass]="'explanation-tooltip'"
                >warning</mat-icon
              >
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="progress">
          <th *matHeaderCellDef mat-header-cell>{{ t('progress') }}</th>
          <td *matCellDef="let datum" mat-cell>
            <div class="flex-row-start-nowrap flex-row-align-center">
              <mat-icon
                *ngIf="datum.jobsCountFinished == datum.jobsCount"
                fontSet="material-icons-outlined"
                class="color-green"
                >check_circle</mat-icon
              >
              <mat-progress-spinner
                class="color-accent"
                *ngIf="datum.jobsCountFinished < datum.jobsCount"
                [mode]="'determinate'"
                [value]="(datum.jobsCountFinished * 100) / datum.jobsCount"
                [diameter]="24">
              </mat-progress-spinner>
              <div class="progress-margin">
                <span
                  class="progress-count"
                  [matTooltip]="datum.jobTypesFinished"
                  [matTooltipClass]="'explanation-tooltip'"
                  >{{ datum.jobsCountFinished }}</span
                >
                <span>&nbsp;/&nbsp;</span>
                <span
                  class="progress-count"
                  [matTooltip]="datum.jobTypesNotFinished"
                  [matTooltipClass]="'explanation-tooltip'"
                  >{{ datum.jobsCount }}</span
                >
                <span>&nbsp;{{ t('jobs') }}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" id="table-columns" mat-header-row></tr>
        <tr *matRowDef="let datum; columns: displayedColumns" mat-row></tr>
      </table>
    </div>

    <mat-paginator [pageSizeOptions]="[12, 25, 50]"></mat-paginator>
  </ng-template>
</div>
