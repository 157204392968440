import {Injectable} from '@angular/core';
import {AuthService} from '@core/services/auth/auth.service';
import {Navigate} from '@ngxs/router-plugin';
import {Action, NgxsOnInit, State, StateContext, Store} from '@ngxs/store';
import {
  PatchStreamedAuth,
  RedirectAfterSignIn,
  RedirectThirdParty,
  SignInWithGoogle,
  SignOut,
  StreamAuth,
  ToggleUserMode,
  UserSignUp,
} from '@store/auth/auth.actions';
import {filter, first, map, tap} from 'rxjs/operators';
import {UserModel} from '@pma/shared/types/user';
import {DisplayError, StartLoading, StopLoading} from '@store/app/app.actions';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {OrganizationModel} from '@pma/shared/types/organization';
import {ChangeOrganizationBillingCode, CreatedOrganization} from '@store/organizations/organizations.actions';
import {OrganizationsState, OrganizationsStateModel} from '@store/organizations/organizations.state';
import {getAuth} from '@angular/fire/auth';
import {AppStateModel} from '@store/app/app.state';

export interface AuthStateModel {
  isInitialized: boolean;
  isLoggedIn: boolean;
  user: UserModel | null;
}

const initialState: AuthStateModel = {
  isInitialized: false,
  isLoggedIn: false,
  user: null,
};

@Injectable()
@State<AuthStateModel>({
  name: 'auth',
  defaults: initialState,
})
export class AuthState implements NgxsOnInit {
  constructor(private afAuth: AngularFireAuth, private auth: AuthService, private store: Store) {}

  /**
   * On initializing AuthState
   * @param dispatch new action
   */
  ngxsOnInit({dispatch}: StateContext<AuthStateModel>) {
    dispatch(StreamAuth);
    this.checkRedirectResults(dispatch).then();
  }

  async checkRedirectResults(dispatch: StateContext<AuthStateModel>['dispatch']) {
    try {
      const credentials = await this.afAuth.getRedirectResult();
      if (credentials.additionalUserInfo && credentials.additionalUserInfo.isNewUser) {
        dispatch(UserSignUp);
      }
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * Listen to authentication state and updates AuthState accordingly.
   * @param dispatch new action
   */
  @Action(StreamAuth)
  stream({dispatch}: StateContext<AuthStateModel>) {
    return this.auth.user$.pipe(
      tap((user: UserModel | null) => {
        dispatch(new PatchStreamedAuth({isInitialized: true, isLoggedIn: Boolean(user), user}));
      })
    );
  }

  /**
   * Open a popup to sign in with Google Provider.
   */
  @Action(SignInWithGoogle)
  async signInWithGoogle({dispatch, patchState}: StateContext<AuthStateModel>, {isPopup}: SignInWithGoogle) {
    try {
      dispatch(StartLoading);
      await this.auth.signInWithGoogle(isPopup);

      // Check if this is a sign up event
      const firebaseUser = getAuth().currentUser;
      if (firebaseUser && firebaseUser.metadata.creationTime === firebaseUser.metadata.lastSignInTime) {
        dispatch(UserSignUp);
      }

      dispatch(RedirectAfterSignIn);
    } catch (e) {
      if (e.code !== 'auth/popup-closed-by-user') {
        dispatch(new DisplayError(e));
      }
    } finally {
      dispatch(StopLoading);
    }
  }

  /**
   * Redirect the user after they sign in
   */
  @Action(RedirectAfterSignIn)
  async redirectUser({dispatch}: StateContext<AuthStateModel>) {
    this.auth
      .isAuthenticated()
      .pipe(
        filter(Boolean),
        first(),
        tap(() => {
          // Get redirect url if exists after login
          const afterLogin = sessionStorage.getItem('afterLogin');
          const navigate = afterLogin ? JSON.parse(afterLogin) : {path: '/organizations'};
          dispatch(new Navigate([navigate.path], navigate.params));

          if (afterLogin) {
            sessionStorage.removeItem('afterLogin');
          }
        })
      )
      .subscribe();
  }

  /**
   * Listen to user sign up action
   */
  @Action(UserSignUp)
  signUp({dispatch}: StateContext<OrganizationsStateModel>) {
    this.store
      .select(state => state)
      .pipe(
        map(state => state.organizations && OrganizationsState.selected(state.organizations)),
        filter<OrganizationModel>(Boolean),
        first(),
        tap((organization: OrganizationModel) => {
          if (Object.keys(organization.users).length === 1) {
            dispatch(CreatedOrganization);

            // Change to requested plan for new user
            // TODO - make all the plans available using the referral link
            const signUpPlan23 = () => {
              const signUpPlan = (sessionStorage.getItem('signUpPlan') || '').toUpperCase();
              const isYearly = signUpPlan.includes('YRLY');
              if (signUpPlan.includes('BIZ')) {
                return 'BIZ23' + (isYearly ? '-YRLY' : '');
              } else if (signUpPlan.includes('PRO')) {
                return 'PRO23' + (isYearly ? '-YRLY' : '');
              } else {
                return 'BIZ23' + (isYearly ? '-YRLY' : '');
              }
            };
            sessionStorage.removeItem('signUpPlan');

            // console.log('Sign up plan:', signUpPlan23());
            this.store.dispatch(new ChangeOrganizationBillingCode(signUpPlan23()));
          }
        })
      )
      .subscribe();
  }

  /**
   * Sign out the current authenticated user.
   */
  @Action(SignOut)
  async signOut() {
    await this.auth.signOut();

    // Properly refresh the page to avoid issues between users
    return ((window as any).location = '/');
  }

  /**
   * Patch the current state.
   * Note - Since StreamAuth never completes, then Redux DevTools won't be able to report when it changes the state.
   * Therefore, instead of directly changing its state, it will call this action.
   * @param patchState of the current state
   * @param payload of the current state
   */
  @Action(PatchStreamedAuth)
  patch({patchState}: StateContext<AuthStateModel>, {payload}: PatchStreamedAuth) {
    return patchState(payload);
  }

  /**
   * Redirect user to third party they came from for token exchange
   */
  @Action(RedirectThirdParty)
  async redirectThirdParty({dispatch}: StateContext<AuthStateModel>, {redirectUri, state}: RedirectThirdParty) {
    const currentUser = await this.afAuth.currentUser;

    const organizationId = this.store.selectSnapshot(store => store.organizations.selectedId);
    dispatch(new StartLoading());
    const redirect = new URL(redirectUri);
    redirect.searchParams.append('code', String(currentUser.uid));
    redirect.searchParams.append('state', state);
    redirect.searchParams.append('organizationId', organizationId);
    location.href = redirect.href;
  }
  /**
   * Toggle User mode
   */
  @Action(ToggleUserMode)
  ToggleUserMode({dispatch}: StateContext<AppStateModel>) {
    return this.auth.user$.pipe(
      tap((user: UserModel | null) => {
        if (user.isAdmin === true) {
          user.isAdmin = false;
          dispatch(new PatchStreamedAuth({isInitialized: true, isLoggedIn: Boolean(user), user}));
        }
      })
    );
  }
}
