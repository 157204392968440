import {DateRange} from '../../interfaces/date-range';

const moment = require('moment-timezone');

export const hourMinutes = 60;
export const dayMinutes = 24 * hourMinutes;
export const hourTime = 1000 * 60 * hourMinutes;
export const dayTime = 1000 * 60 * dayMinutes;
export const weekTime = 7 * dayTime;
export const monthTime = 30 * dayTime;
export const yearTime = 365 * dayTime;

export const timeZoneOffset = new Date().getTimezoneOffset();

// Returns a date in ISO 8601 format YYYY-mm-dd
type ShortISOFormat = 'day' | 'month';

export function toShortISO(date: Date | string | any, format: ShortISOFormat = 'day'): string {
  if (date === undefined) {
    console.warn('Warning! toShortISO: date is undefined (allowed for testing purposes)');
    return '';
  }
  let dateString = date;

  if (typeof date !== 'string') {
    if (date instanceof Date) {
      dateString = date.toISOString();
    } else if (date._isAMomentObject) {
      dateString = date.format();
    } else {
      throw new Error('toShortISO date is not a Date object');
    }
  }

  const limit = format === 'day' ? 10 : 7;
  return (dateString as string).substr(0, limit);
}

export function toYearMonth(date: Date) {
  return toShortISO(date, 'month').replace('-', '');
}

export function mysqlDate(date: Date) {
  return date.toISOString().substr(0, 19).replace('T', ' ');
}

export function dateRangeToStringRange(dates: DateRange<any>): DateRange<string> {
  return {
    exclusive: dates.exclusive,
    start: typeof dates.start === 'string' ? dates.start : toShortISO(dates.start),
    end: typeof dates.end === 'string' ? dates.end : toShortISO(dates.end),
  };
}

export function hourLabel(hour: number) {
  const h = hour % 12 || 12;
  const a = (hour / 12) % 2 >= 1 ? 'pm' : 'am';

  return h + ':00 ' + a;
}

export function offsetLabel(offset: number) {
  let absolute = (offset - timeZoneOffset) % dayTime;
  if (absolute < 0) {
    absolute += dayTime;
  }
  const hour = Math.floor(absolute / hourMinutes);

  return hourLabel(hour);
}

export function dayOffset(hours: number) {
  return hours * 60;
}

export function timezoneDate(timeZone: string) {
  return new Date(new Date().toLocaleString('en-US', {timeZone}));
}

export function fromShortISO(date: string) {
  const year = date.substr(0, 4);
  const month = date.substr(5, 2);
  const day = date.substr(8, 2);

  return new Date(`${year}-${month}-${day}T00:00:00`);
}

export function toShortISOFromUnhyphenated(date: string) {
  // Convert '20220428' to '2022-04-28T00:00:00'
  const year = date.substr(0, 4);
  const month = date.substr(4, 2);
  const day = date.substr(6, 2);

  return `${year}-${month}-${day}T00:00:00`;
}

export function toShortString(date: Date) {
  let day = String(date.getDate());
  day = day.length === 1 ? '0' + day : day;

  let month = String(date.getMonth() + 1);
  month = month.length === 1 ? '0' + month : month;

  return [date.getFullYear(), month, day].join('-');
}

export function getDateRange(predefinedRangeName: string, startCustom: Date, isIncludeToday: boolean): any {
  // Get date range for predefined range name
  let start = new Date();
  let end = new Date();

  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);

  const prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));

  if (predefinedRangeName.indexOf(':') > -1) {
    // Dynamic date ranges, e.g. 'Last X days:45' or 'X months ago:2'
    const parts = predefinedRangeName.split(':');
    const rangeName = parts[0];
    const periods = Number(parts[1]);

    switch (rangeName) {
      case 'Last X days':
        start.setDate(start.getDate() - periods + 1);
        break;

      case 'Last X weeks (Sun-Sat)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - (8 + 7 * (periods - 1)));
        end.setDate(prevMonday.getDate() - 2);
        break;

      case 'Last X weeks (Mon-Sun)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - (7 + 7 * (periods - 1)));
        end.setDate(prevMonday.getDate() - 1);
        break;

      case 'Last X weeks, excluding current (Sun-Sat)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - (8 + 7 * (periods - 1)));
        end.setDate(prevMonday.getDate() - (8 + 7 * (1 - 2)) - 1);
        break;

      case 'Last X weeks, excluding current (Mon-Sun)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - (7 + 7 * (periods - 1)));
        end.setDate(prevMonday.getDate() - (7 + 7 * (1 - 2)) - 1);
        break;

      case 'Last X months':
        start.setMonth(start.getMonth() - periods + 1, 1);
        break;

      case 'Last X months, excluding current':
        start.setMonth(start.getMonth() - periods + 1, 1);
        end.setDate(0);
        break;

      case 'X months ago':
        start.setMonth(start.getMonth() - periods, 1);
        end.setMonth(end.getMonth() - periods + 1, 1);
        end.setDate(0);
        break;

      case 'X weeks ago (Sun-Sat)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - (8 + 7 * (periods - 1)));
        end.setDate(prevMonday.getDate() - (8 + 7 * (periods - 2)) - 1);
        break;

      case 'X weeks ago (Mon-Sun)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - (7 + 7 * (periods - 1)));
        end.setDate(prevMonday.getDate() - (7 + 7 * (periods - 2)) - 1);
        break;

      case 'This month to yesterday (X years ago)':
        start.setDate(1);
        end = yesterday;
        start.setFullYear(start.getFullYear() - periods);
        end.setFullYear(end.getFullYear() - periods);
        break;

      case 'This month to date (X years ago)':
        start.setDate(1);
        start.setFullYear(start.getFullYear() - periods);
        end.setFullYear(end.getFullYear() - periods);
        break;
    }
  } else {
    switch (predefinedRangeName) {
      case 'Custom start to date':
        start = startCustom;
        break;

      case 'Custom start to yesterday':
        start = startCustom;
        end = yesterday;
        break;

      case 'Today':
        break;

      case 'Yesterday':
        start = yesterday;
        end = yesterday;
        break;

      case 'Last 7 days':
        start.setDate(start.getDate() - 6);
        break;

      case 'Last 28 days':
        start.setDate(start.getDate() - 27);
        break;

      case 'Last 30 days':
        start.setDate(start.getDate() - 29);
        break;

      case 'Last 13 months':
        start.setMonth(start.getMonth() - 13, 1);
        break;

      case 'This week (Sun-Sat)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - 1);
        end.setDate(prevMonday.getDate() + 5);
        break;

      case 'This week (Mon-Sun)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - 0);
        end.setDate(prevMonday.getDate() + 6);
        break;

      case 'Last week (Sun-Sat)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - 8);
        end.setDate(prevMonday.getDate() - 2);
        break;

      case 'Last week (Mon-Sun)':
        start = new Date(prevMonday.getTime());
        end = new Date(prevMonday.getTime());
        start.setDate(prevMonday.getDate() - 7);
        end.setDate(prevMonday.getDate() - 1);
        break;

      case 'Last month':
        start.setMonth(start.getMonth() - 1, 1);
        end.setDate(0);
        break;

      case 'This month to date':
        start.setDate(1);
        break;

      case 'This month to yesterday':
        start.setDate(1);
        end = yesterday;
        break;

      case 'This year to date':
        start.setDate(1);
        start.setMonth(0);
        break;

      default: // Default to 'Last 30 days'
        start.setDate(start.getDate() - 29);
        break;
    }
  }

  // Handle option 'Include Today'
  let isIncludeTodayRelevant = true;

  if (
    ['Custom', 'Today', 'Last month'].indexOf(predefinedRangeName) !== -1 ||
    predefinedRangeName.toLowerCase().indexOf('month to date') !== -1 ||
    predefinedRangeName.toLowerCase().indexOf('yesterday') !== -1 ||
    predefinedRangeName.toLowerCase().indexOf('this week') !== -1 || // Weeks is complicated
    predefinedRangeName.toLowerCase().indexOf('last week') !== -1 || // Weeks is complicated
    predefinedRangeName.toLowerCase().indexOf('last x weeks') !== -1 || // Weeks is complicated
    predefinedRangeName.toLowerCase().indexOf('ago') !== -1 ||
    predefinedRangeName.toLowerCase().indexOf('excluding') !== -1
  ) {
    isIncludeTodayRelevant = false;
  }

  if (isIncludeTodayRelevant && !isIncludeToday) {
    // Adjust end date
    end.setDate(end.getDate() - 1);

    // Also adjust start date unless the start date should be fixed
    if (
      predefinedRangeName !== 'Custom start to date' &&
      predefinedRangeName !== 'This year to date' &&
      predefinedRangeName !== 'Last 13 months' &&
      predefinedRangeName.indexOf('Last X months') === -1
    ) {
      start.setDate(start.getDate() - 1);
    }
  }

  return {
    start,
    end,
  };
}

export function getTimezoneNames() {
  return moment.tz.names();
}

export function getTimezoneAbbreviation(timezoneName: string) {
  return moment.tz(timezoneName).zoneAbbr();
}

export function getDateConvertTimezone(dateString: string, timezoneStart: string, timezoneEnd: string): string {
  // Convert date string from start timezone to end timezone
  const activityDateMomentStart = moment.tz(dateString, timezoneStart);
  const activityDateMomentEnd = activityDateMomentStart.clone().tz(timezoneEnd);
  return activityDateMomentEnd.format();
}
