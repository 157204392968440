import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {EntityLogDocument, EntityLogModel} from '@pma/shared/types/logs';

@Pipe({
  name: 'log',
})
export class LogPipe extends DocumentPipe<EntityLogDocument, EntityLogModel> implements PipeTransform {
  transform(doc: EntityLogDocument, ...args: any[]): EntityLogModel {
    return super.transform(doc);
  }
}
