import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {LicenseManager} from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'CompanyName=PowerMyAnalytics,LicensedApplication=Power My Analytics,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-026115,ExpiryDate=7_March_2023_[v2]_MTY3ODE0NzIwMDAwMA==237a0213c0bfdec5a0318b60ca1cc7c7'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
