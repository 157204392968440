export const SAVED_WORDS = ['id', 'url', 'os', 'cpc', 'cpm', 'ctr', 'ptr', 'vs', 'roas'];

export function readableName(s: string): string {
  if (s.length === 0) {
    return s;
  }
  return s
    .replace(/(_\w)/g, m => ' ' + m[1].toUpperCase()) // fix snake case ("_a" to " A")
    .replace(/[a-z][A-Z]/g, m => m[0] + ' ' + m[1]) // fix joint camel case ("aA" to "a A")
    .toLowerCase() // Now that words are separate, make uppercase what is needed
    .replace(/(\b\w)/g, m => m.toUpperCase()) // fix snake case ("_a" to " A")
    .replace(new RegExp('\\b(' + SAVED_WORDS.join('|') + ')\\b', 'ig'), m => m.toUpperCase()) // fix saved words
    .trim(); // Trim if contains spaces around
}

export function validDBName(s: string): string {
  if (s.length === 0) {
    return s;
  }
  return s.replace(/[^a-zA-Z0-9-_]/g, '_'); // only A-Z a-z 0-9 and underscore are allowed
}
