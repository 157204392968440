import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference, QueryFn} from '@angular/fire/compat/firestore';
import {AuthService} from '@core/services/auth/auth.service';
import {BaseService} from '@core/services/base.service';
import {environment} from '../../../../environments/environment';
import {OrganizationPipe} from '@shared/pipes/organization/organization.pipe';
import {deleteField, serverTimestamp} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {
  OrganizationBillingDetails,
  OrganizationDocument,
  OrganizationLookup,
  OrganizationModel,
  OrganizationSQLDatabase,
  OrganizationTokenDocument,
  OrganizationTokenModel,
} from '@pma/shared/types/organization';
import {UserRole} from '@pma/shared/types/user';
import {
  ZohoCustomerTransaction,
  ZohoInvoice,
  ZohoSubscriptionActivity,
} from '@pma/shared/interfaces/zoho-subscriptions.interface';
import {omit} from 'lodash';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {LogsService} from '@core/services/logs/logs.service';
import {toShortISO} from '@pma/shared/utils/date/date';
import {User} from '@angular/fire/auth';
import {DocumentChangeAction} from '@angular/fire/compat/firestore/interfaces';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseService {
  readonly endpoint = environment.apiEndpoint + '/http-organizations';

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private organizationPipe: OrganizationPipe,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private logs: LogsService
  ) {
    super();
  }

  /**
   * Listen to a stream of user's organizations
   */
  get organizations$(): Observable<OrganizationModel[]> {
    return this.auth.user$.pipe(filter<any>(Boolean), switchMap(this.getByUser.bind(this)));
  }

  /**
   * Listen to a stream of an organization requested explicitly
   */
  getOrganizationDirect(id: string): Observable<OrganizationModel> {
    return this.getById(id);
  }

  ref(organizationId: string) {
    return this.afs.collection<OrganizationDocument>('organizations').doc(organizationId);
  }

  update(organizationId: string, data: any) {
    return this.ref(organizationId).update(data);
  }

  /**
   * Set the name of the current selected organization.
   * @param organizationId the id of the organization
   * @param name the desired name of the organization
   */
  setName(organizationId: string, name: string): Promise<void> {
    return this.ref(organizationId).update({name});
  }

  /**
   * Invite a new member to an existing organization
   * @param organizationId the id of the organization
   * @param email the email of the invitee
   * @param role the desired role of the invitee
   */
  inviteMember(organizationId: string, email: string, role: UserRole) {
    return this.http
      .post(`${this.endpoint}/${organizationId}/invite`, {
        email,
        role,
      })
      .pipe(first())
      .toPromise() as Promise<unknown>;
  }

  /**
   * Get 3rd party billing URL
   * @param organizationId the id of the organization
   */
  getBillingUrl(organizationId: string): Promise<{url: string}> {
    return this.http.get<{url: string}>(`${this.endpoint}/${organizationId}/billing`).pipe(first()).toPromise();
  }

  /**
   * Get invoices from subscription provider
   * @param organizationId the id of the organization
   */
  getInvoices(organizationId: string): Promise<ZohoInvoice[]> {
    return this.http.get<ZohoInvoice[]>(`${this.endpoint}/${organizationId}/invoices`).pipe(first()).toPromise();
  }

  /**
   * Get transactions from customer
   * @param organizationId the id of the organization
   */
  getCustomerTransactions(organizationId: string): Promise<ZohoCustomerTransaction[]> {
    const url = `${this.endpoint}/${organizationId}/transactions`;
    return this.http.get<ZohoCustomerTransaction[]>(url).pipe(first()).toPromise();
  }

  /**
   * Get invoice url from subscription provider
   * @param organizationId the id of the organization
   * @param transactionId the id of the transaction
   * @param transactionType the id of the transaction
   */
  async getTransactionUrl(organizationId: string, transactionId: string, transactionType: string): Promise<string> {
    const url = `${this.endpoint}/${organizationId}/transactions/${transactionId}/${transactionType}`;
    const {path} = await this.http.get<{path: string}>(url).pipe(first()).toPromise();
    return this.storage.storage.ref(path).getDownloadURL();
  }

  /**
   * Get invoice url from subscription provider
   * @param organizationId the id of the organization
   * @param invoiceId the id of the invoice
   */
  async getInvoiceUrl(organizationId: string, invoiceId: string): Promise<string> {
    const {path} = await this.http
      .get<{path: string}>(`${this.endpoint}/${organizationId}/invoices/${invoiceId}`)
      .pipe(first())
      .toPromise();

    return this.storage.storage.ref(path).getDownloadURL();
  }

  /**
   * Get Zoho activities for the subscription
   * @param organizationId the id of the organization
   */
  getSubscriptionActivities(organizationId: string): Promise<ZohoSubscriptionActivity[]> {
    const url = `${this.endpoint}/${organizationId}/zohoActivities`;
    return this.http.get<ZohoSubscriptionActivity[]>(url).pipe(first()).toPromise();
  }

  /**
   * Get Zoho requests for the subscription
   * @param organizationId the id of the organization
   */
  getSubscriptionZohoRequests(organizationId: string): Promise<ZohoSubscriptionActivity[]> {
    const url = `${this.endpoint}/${organizationId}/zohoRequests`;
    return this.http.get<any[]>(url).pipe(first()).toPromise();
  }

  /**
   * Remove a member from an organization
   * @param organizationId string
   * @param uid string
   */
  async removeMember(organizationId: string, uid: string) {
    return this.ref(organizationId).update({
      [`users.${uid}`]: deleteField(),
    });
  }

  /**
   * Add a member from to an organization
   * @param organizationId string
   * @param uid string
   * @param role string
   */
  async addMember(organizationId: string, uid: string, role: UserRole) {
    return this.ref(organizationId).update({
      [`users.${uid}`]: role,
    });
  }

  /**
   * Update organization connections
   */
  async removeConnection(organizationId: string, connectionId: string, type: string, name: string) {
    await this.ref(organizationId).update({
      [`connections.${connectionId}`]: deleteField(),
    });

    const ref = this.logs.getLogRef('organizations', organizationId);
    const params = {
      accountId: connectionId,
      type,
      name,
    };
    await this.logs.log(ref, 'organization.connectionRemoved', params);
    // console.log('organization.connectionRemoved', params);
  }

  /**
   * Change member role
   * @param organizationId string
   * @param uid string
   * @param role UserRole
   */
  changeRole(organizationId: string, uid: string, role: UserRole) {
    return this.ref(organizationId).update({
      [`users.${uid}`]: role,
    });
  }

  /**
   * Change billing type
   * @param organizationId string
   * @param billingCode string
   */
  changeBillingCode(organizationId: string, billingCode: string) {
    return this.ref(organizationId).update({'billing.planCode': billingCode} as any);
  }

  /**
   * Change plan and extend trial period
   * @param organizationId string
   * @param planCode string
   */
  changePlanAndExtendTrial(organizationId: string, planCode: string) {
    const url =
      environment.apiEndpoint + `/subscriptions-extendTrial?organizationId=${organizationId}&planCode=${planCode}`;
    return this.http.get<any>(url).pipe(first()).toPromise();
  }

  /**
   * Update Billing Information
   * @param organizationId string
   * @param details OrganizationBillingDetails
   */
  updateBillingDetails(organizationId: string, details: OrganizationBillingDetails) {
    return this.ref(organizationId).update({details});
  }

  /**
   * Makes a request to cancel the subscription
   */
  cancelPlan(organizationId: string) {
    const url = environment.apiEndpoint + `/subscriptions-cancel?organizationId=` + organizationId;
    return this.http.get<any>(url).pipe(first()).toPromise();
  }

  /**
   * Makes a request to toggle the paused state of the subscription
   */
  pauseTogglePlan(organizationId: string) {
    const url = environment.apiEndpoint + '/subscriptions-pauseToggle?organizationId=' + organizationId;
    return this.http.get<any>(url).pipe(first()).toPromise();
  }

  /**
   * Makes a request to delete exiting payment method
   */
  removePaymentMethod(organizationId: string) {
    const url = environment.apiEndpoint + '/subscriptions-removePaymentMethod?organizationId=' + organizationId;
    return this.http.get<any>(url).pipe(first()).toPromise();
  }

  /**
   * Collect invoices
   */
  collectInvoices(organizationId: string): Promise<void> {
    const url = environment.apiEndpoint + '/subscriptions-collectInvoices?organizationId=' + organizationId;
    return this.http.get<any>(url).pipe(first()).toPromise();
  }

  /**
   * Makes a request to re-activate a cancelled subscription
   */
  reactivateSubscription(organizationId: string) {
    const url = environment.apiEndpoint + '/subscriptions-reactivate?organizationId=' + organizationId;
    return this.http.get<any>(url).pipe(first()).toPromise();
  }

  /**
   * Modify an addon's quantity
   */
  modifyAddon(organizationId: string, addon: string, quantity: number) {
    return this.ref(organizationId).update({['billing.addons.' + addon]: quantity});
  }

  /**
   * Sets a tracker value
   */
  setTracker(organizationId: string, type: string, tracker: string, value: string) {
    return this.ref(organizationId).update({[`trackers.${type}.${tracker}`]: value});
  }

  /**
   * Sets the billing users
   */
  updateBillingUsers(organizationId: string, usersBillingIds: string[]) {
    return this.ref(organizationId).update({'billing.users': usersBillingIds} as any);
  }

  /**
   * Update SQL Database Details to Organization
   */
  updateSQLDatabase(organizationId: string, dbDetails: OrganizationSQLDatabase) {
    const url = this.endpoint + `/${organizationId}/update-sql-details`;
    return this.http.post<any>(url, dbDetails).pipe(first()).toPromise();
  }

  /**
   * Remove SQL Database from Organization
   */
  removeSQLDatabase(organizationId: string) {
    return this.ref(organizationId).update({sqlDatabase: null});
  }

  /**
   * Update usage for organization
   */
  updateUsage(organizationId: string, usage: any) {
    const url = this.endpoint + `/${organizationId}/update-usage`;
    return this.http.post<any>(url, usage).pipe(first()).toPromise();
  }

  /**
   * Remove usage for organization
   */
  removeUsage(organizationId: string, usage: any) {
    const url = this.endpoint + `/${organizationId}/remove-usage`;
    return this.http.post<any>(url, {usage}).pipe(first()).toPromise();
  }

  /**
   * Sets the status of an integration
   */
  async setIntegrationStatus(
    organizationId: string,
    integrationType: string,
    integrationStatus: string,
    isStartIntegration: boolean
  ) {
    if (isStartIntegration) {
      return this.ref(organizationId).update({
        ['integrations.' + integrationType]: {
          status: integrationStatus,
          usedFirst: new Date(),
          usedLast: new Date(),
        },
      });
    } else {
      return this.ref(organizationId).update({['integrations.' + integrationType + '.status']: integrationStatus});
    }
  }

  /**
   * Get a stream of documents of user's organizations
   * @param user the user to be queried for
   */
  private getByUser(user: User) {
    return this.afs
      .collection<OrganizationDocument>('organizations', this.queryByUser(user))
      .valueChanges({idField: OrganizationsService.primaryKey})
      .pipe(
        map(this.transformAll.bind(this)),
        map((organizations: OrganizationModel[]) =>
          organizations.sort((org1, org2) =>
            org1.billing.isActive > org2.billing.isActive && org1.name > org2.name ? -1 : 1
          )
        )
      );
  }

  /**
   * Get a stream of an organization requested explicitly
   */
  private getById(id: string) {
    return this.afs
      .collection<OrganizationDocument>('organizations')
      .doc(id)
      .valueChanges({idField: OrganizationsService.primaryKey})
      .pipe(
        filter(organization => Object.keys(organization).length > 1),
        map((organization: OrganizationDocument) => {
          console.log('getIdOrganization', organization);
          return this.organizationPipe.transform(organization as any);
        })
      );
  }

  /**
   * Get organization list for lookup
   */
  getListLookup(group: string, isCached: boolean): Promise<OrganizationLookup[]> {
    const nocache = isCached ? '' : '?nocache=1';
    const url = `${this.endpoint}/lookup/${group}` + nocache;
    return this.http.get<OrganizationLookup[]>(url).pipe(first()).toPromise();
  }

  /**
   * Transform an array of organizations documents to models
   * @param organizations a collection of organizations documents
   */
  private transformAll(organizations: OrganizationDocument[]): OrganizationModel[] {
    return organizations.map(org => this.organizationPipe.transform(org as any));
  }

  /**
   * Build a query to get only the organizations that the user is participated in
   * @param user the user to be queried for
   */
  private queryByUser(user): QueryFn {
    return ref => ref.where(`users.${user.uid}`, '>', '');
  }

  async getInvitationNotifications(userId: string, organizationId: string): Promise<any[]> {
    // Get invite notifications to the user for an organization
    const userRef = this.afs.collection('users').doc(userId).ref;
    const organizationRef = this.ref(organizationId).ref;

    return this.afs
      .collection('notifications', ref => {
        let q: any = ref;
        q = q.where('type', '==', 'invitation');
        q = q.where('user', '==', userRef);
        q = q.where('organization', '==', organizationRef);
        return q;
      })
      .valueChanges({idField: 'id'})
      .pipe(first())
      .toPromise();
  }

  async isGoogleEmail(organizationId: string, email: string): Promise<any> {
    const emailEncoded = encodeURIComponent(email);
    const url = this.endpoint + `/${organizationId}/inviteIsGoogle?email=${emailEncoded}`;
    return this.http.get(url).pipe(first()).toPromise() as Promise<any>;
  }

  /**
   * Get organization API tokens
   */
  getApiTokens(organizationId: string): Observable<OrganizationTokenModel[]> {
    const organizationRef = this.afs.collection('organizations').doc(organizationId).ref;

    return this.afs
      .collection('tokens', ref => {
        let q: any = ref;
        q = q.where('organization', '==', organizationRef);
        return q;
      })
      .snapshotChanges()
      .pipe(
        map((documents: DocumentChangeAction<OrganizationTokenDocument>[]) =>
          documents.map(s => {
            const data = s.payload.doc.data();
            const id = s.payload.doc.id;
            const createdAt = data.createdAt ? data.createdAt.toDate() : new Date();
            const token = {...omit(data, ['organization', 'createdAt']), createdAt, id} as unknown;
            return token as OrganizationTokenModel;
          })
        )
      );
  }

  createApiToken(organizationId: string, apiToken: string): Promise<DocumentReference> {
    return this.afs.collection('tokens').add({
      apiToken,
      organization: this.ref(organizationId).ref,
      createdAt: serverTimestamp(),
    });
  }

  deleteApiToken(id: string): Promise<void> {
    return this.afs.collection('tokens').doc(id).delete();
  }

  /**
   * Get stats for organization overview
   */
  getOverviewStats(organizationId: string): Promise<any> {
    return this.http.get<any>(`${this.endpoint}/${organizationId}/stats`).pipe(first()).toPromise();
  }

  /**
   * Get organization usage on given date from logs
   */
  getHistoricUsage(organizationId: string, date: Date): Promise<any[]> {
    const dateString = toShortISO(date);
    return this.http
      .get<any>(`${this.endpoint}/${organizationId}/historic-usage/${dateString}`)
      .pipe(first())
      .toPromise();
  }
}
