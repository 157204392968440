import {Component, Input} from '@angular/core';
import {dayTime, yearTime} from '@pma/shared/utils/date/date';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {BackfillEntity} from '@store/connectors/connectors.actions';
import {BackfillGoogleAnalyticsImport} from '@store/google-analytics/google-analytics.actions';
import {takeUntil, tap} from 'rxjs/operators';
import {BaseComponent} from '@shared/components/base/base.component';
import {TranslocoService} from '@ngneat/transloco';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-backfill-menu',
  templateUrl: './backfill-menu.component.html',
  styleUrls: ['./backfill-menu.component.scss'],
})
export class BackfillMenuComponent extends BaseComponent {
  minDate: Date = new Date(Date.now() - yearTime * 2);
  maxDate: Date = new Date();

  form: FormGroup = new FormGroup({
    start: new FormControl(null, {validators: [Validators.required]}),
    end: new FormControl(new Date(), {validators: [Validators.required]}),
  });

  @Input() accountId: string;
  @Input() connector: string;
  @Input() usageKey?: string;
  @Input() importId?: string;
  @Input() reportTypes?: string[];

  @Input() set isAdmin(value: boolean) {
    // Allow admins to request backfill up to 10 years
    if (value) {
      this.minDate = new Date(Date.now() - yearTime * 10);
    }
  }

  constructor(private store: Store, private transloco: TranslocoService, private snackBar: MatSnackBar) {
    super();
  }

  backfill(start: Date, end: Date) {
    if (this.importId) {
      // GA import backfill
      return this.store
        .dispatch(new BackfillGoogleAnalyticsImport(this.importId, start, end))
        .pipe(tap(this.handleBackfillSuccess.bind(this)), takeUntil(this.ngUnsubscribe))
        .subscribe();
    } else {
      // Connector backfill
      // console.log('Backfill:', this.connector, this.accountId, this.usageKey, start, end, this.reportTypes);
      return this.store
        .dispatch(new BackfillEntity(this.accountId, this.connector, this.usageKey, start, end, this.reportTypes))
        .pipe(tap(this.handleBackfillSuccess.bind(this)), takeUntil(this.ngUnsubscribe))
        .subscribe();
    }
  }

  backfillDays(days: number) {
    const start = new Date(Date.now() - dayTime * days);
    const end = new Date();

    return this.backfill(start, end);
  }

  backfillForm(e: Event) {
    // @ts-ignore
    e.propagate = true;

    const values = this.form.value;
    return this.backfill(values.start, values.end);
  }

  preventClose(e: Event) {
    if (!('propagate' in e)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  private handleBackfillSuccess() {
    const translationPath = 'app.backfill-menu.alertSuccess';
    const message = this.transloco.translate(translationPath);

    this.snackBar.open(message, null, {duration: 5000});
  }
}
