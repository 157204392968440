import {Pipe, PipeTransform} from '@angular/core';
import {UserDocument, UserModel} from '@pma/shared/types/user';
import {User} from '@angular/fire/auth';

@Pipe({
  name: 'user',
})
export class UserPipe implements PipeTransform {
  transform(value: {firebaseUser: User; doc: UserDocument}, ...args: any[]): UserModel {
    const {firebaseUser, doc} = value;

    return {
      uid: firebaseUser.uid,
      displayName: firebaseUser.displayName,
      photoURL: firebaseUser.photoURL,
      email: firebaseUser.email,
      isAdmin: Boolean(doc && doc.isAdmin),
      apiToken: doc ? doc.apiToken : null,
      createdAt: doc ? doc.createdAt.toDate() : new Date(),
      updatedAt: doc ? doc.updatedAt.toDate() : new Date(),
      settings: doc.settings,
    };
  }
}
