import {Injectable} from '@angular/core';
import {NotificationsService} from '@core/services/notifications/notifications.service';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {NgxsOnInit} from '@ngxs/store/src/symbols';
import {
  GetNotificationsAll,
  GetNotificationsByType,
  GetNotificationsByTypeForOrganization,
  GetNotificationsByTypeForUser,
  StreamBannerNotifications,
  MarkNotificationsAsRead,
  PatchNotificationStatus,
  PatchStreamedBannerNotifications,
  PatchStreamedNotifications,
  StreamNotifications,
} from '@store/notifications/notifications.actions';
import {takeUntil, tap} from 'rxjs/operators';
import {NotificationModel} from '@pma/shared/types/notification';
import {DisplayError} from '@store/app/app.actions';
import {Subject} from 'rxjs';

export interface NotificationsStateModel {
  isInitialized: boolean;
  isInitializedBanner: boolean;
  collection?: NotificationModel[];
  collectionBanner?: NotificationModel[];
  unreadCount: number;
}

const initialState: NotificationsStateModel = {
  isInitialized: false,
  isInitializedBanner: false,
  collection: undefined,
  collectionBanner: undefined,
  unreadCount: 0,
};

@Injectable()
@State({
  name: 'notifications',
  defaults: initialState,
})
export class NotificationsState implements NgxsOnInit {
  static listCriteriaChanged = new Subject<void>();

  constructor(private service: NotificationsService) {}

  @Selector()
  static count(state: NotificationsStateModel): number | undefined {
    return state.collection ? state.collection.length : undefined;
  }

  /**
   * Count the total unread messages
   */
  @Selector()
  static countUnread(state: NotificationsStateModel): number {
    return state.unreadCount;
  }

  ngxsOnInit({dispatch}: StateContext<NotificationsStateModel>): void {
    dispatch([StreamNotifications, StreamBannerNotifications]);
  }

  /**
   * Stream notifications for current organization and user for the notifications list (and count in toolbar)
   */
  @Action(StreamNotifications)
  stream({dispatch}: StateContext<NotificationsStateModel>) {
    return this.service.notifications$.pipe(
      tap(notifications => dispatch(new PatchStreamedNotifications(notifications)))
    );
  }

  /**
   * Stream notifications for current organization (with specific types) for the banner, into a separate collection
   */
  @Action(StreamBannerNotifications)
  getNotificationsForBanner({dispatch}: StateContext<NotificationsStateModel>) {
    return this.service.notificationsBanner$.pipe(
      tap(notifications => dispatch(new PatchStreamedBannerNotifications(notifications)))
    );
  }

  /**
   * Stream notifications for the notifications list, handling different list criteria
   */
  @Action(GetNotificationsAll)
  getNotificationsAll(
    {dispatch}: StateContext<NotificationsStateModel>,
    {userId, organizationId}: GetNotificationsAll
  ) {
    NotificationsState.listCriteriaChanged.next();

    return this.service.getNotifications(userId, organizationId).pipe(
      tap(notifications => dispatch(new PatchStreamedNotifications(notifications))),
      takeUntil(NotificationsState.listCriteriaChanged)
    );
  }

  @Action(GetNotificationsByType)
  getNotificationsByType(
    {dispatch}: StateContext<NotificationsStateModel>,
    {userId, organizationId, type}: GetNotificationsByType
  ) {
    NotificationsState.listCriteriaChanged.next();

    return this.service.getNotificationsByType(userId, organizationId, type).pipe(
      tap(notifications => dispatch(new PatchStreamedNotifications(notifications))),
      takeUntil(NotificationsState.listCriteriaChanged)
    );
  }

  @Action(GetNotificationsByTypeForUser)
  getNotificationsByTypeForUser(
    {dispatch}: StateContext<NotificationsStateModel>,
    {userId, types}: GetNotificationsByTypeForUser
  ) {
    NotificationsState.listCriteriaChanged.next();

    return this.service.getNotificationsByTypeForUser(userId, types).pipe(
      tap(notifications => dispatch(new PatchStreamedNotifications(notifications))),
      takeUntil(NotificationsState.listCriteriaChanged)
    );
  }

  @Action(GetNotificationsByTypeForOrganization)
  getNotificationsByTypeForOrganization(
    {dispatch}: StateContext<NotificationsStateModel>,
    {organizationId, types}: GetNotificationsByTypeForOrganization
  ) {
    NotificationsState.listCriteriaChanged.next();

    return this.service.getNotificationsByTypeForOrganization(organizationId, types).pipe(
      tap(notifications => dispatch(new PatchStreamedNotifications(notifications))),
      takeUntil(NotificationsState.listCriteriaChanged)
    );
  }

  /**
   * Mark all notifications in the notifications list collection as read
   */
  @Action(MarkNotificationsAsRead)
  async markAllAsRead({getState, dispatch}: StateContext<NotificationsStateModel>) {
    try {
      return await this.service.markAsRead(getState().collection as NotificationModel[]);
    } catch (e) {
      return dispatch(new DisplayError(e));
    }
  }

  /**
   * Update the status of a specific notification
   */
  @Action(PatchNotificationStatus)
  async patchStatus(
    {dispatch}: StateContext<NotificationsStateModel>,
    {notification, action}: PatchNotificationStatus
  ) {
    try {
      return await this.service.patchStatus(notification, action);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * Patch the collection for the notifications list
   */
  @Action(PatchStreamedNotifications)
  patchStreamed({patchState}: StateContext<NotificationsStateModel>, {notifications}: PatchStreamedNotifications) {
    patchState({
      isInitialized: true,
      collection: notifications,
      unreadCount: notifications.filter(notification => !notification.hasRead).length,
    });
  }

  /**
   * Patch the collection for the banner
   */
  @Action(PatchStreamedBannerNotifications)
  patchStreamedBannerNotifications(
    {patchState}: StateContext<NotificationsStateModel>,
    {notifications}: PatchStreamedBannerNotifications
  ) {
    patchState({
      isInitializedBanner: true,
      collectionBanner: notifications,
    });
  }
}
