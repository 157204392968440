import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Dictionary} from '@pma/shared/interfaces/dictionary.interface';

interface ScopesDialogData {
  title: string;
  scopesDictionary: Dictionary<{label: string; isAuthorized: boolean}>;
}

@Component({
  selector: 'app-scopes-dialog',
  templateUrl: './scopes-dialog.component.html',
  styleUrls: ['./scopes-dialog.component.scss'],
})
export class ScopesDialogComponent {
  title: string;
  scopesDictionary: Dictionary<{label: string; isAuthorized: boolean}>;
  scopesArray: any[];

  constructor(
    private dialogRef: MatDialogRef<ScopesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ScopesDialogData
  ) {
    this.title = data.title;
    this.scopesDictionary = data.scopesDictionary;

    this.scopesArray = [];
    Object.entries(this.scopesDictionary).forEach(([key, value]) => {
      this.scopesArray.push({
        name: key,
        label: value.label,
        isAuthorized: value.isAuthorized,
      });
    });
  }

  isShowNote(): boolean {
    if (this.scopesArray.length > 1) {
      return true;
    }
    return false;
  }

  close() {
    this.dialogRef.close();
  }
}
