<ng-container *ngIf="backfillHistory$ | async as history; else loadingLogs">
  <mat-tab-group>
    <mat-tab *ngIf="isAdmin">
      <ng-template mat-tab-label>
        <mat-icon fontSet="material-icons-outlined" class="color-accent">admin_panel_settings</mat-icon>
        &nbsp;Chart
      </ng-template>

      <app-usage-history-chart [data]="history"></app-usage-history-chart>
    </mat-tab>
    <mat-tab label="Jobs">
      <app-usage-history-table
        [data]="history"
        [accountTimezone]="accountTimezone"
        [accountTimezoneAbbreviation]="accountTimezoneAbbreviation"
        [isAdmin]="isAdmin">
      </app-usage-history-table>
    </mat-tab>
    <mat-tab *ngIf="isAdmin">
      <ng-template mat-tab-label>
        <mat-icon fontSet="material-icons-outlined" class="color-accent">admin_panel_settings</mat-icon>
        &nbsp;Data Gaps
      </ng-template>

      <app-usage-history-gaps [data]="history"></app-usage-history-gaps>
    </mat-tab>
  </mat-tab-group>
</ng-container>

<ng-template #loadingLogs>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
