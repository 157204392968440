import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-sign-in-with-google',
  templateUrl: './sign-in-with-google.component.html',
  styleUrls: ['./sign-in-with-google.component.scss'],
})
export class SignInWithGoogleComponent {
  @Input() isSmall = true;
}
