import {Component, Input} from '@angular/core';
import {PublicUserData} from '@pma/shared/types/user';

@Component({
  selector: 'app-table-user-cell',
  templateUrl: './table-user-cell.component.html',
  styleUrls: ['./table-user-cell.component.scss'],
})
export class TableUserCellComponent {
  @Input() userData: PublicUserData;

  get img(): string {
    if (this.userData && this.userData.photoURL) {
      return this.userData.photoURL.replace('http://', 'https://');
    }
    return 'assets/images/default-avatar.png';
  }
}
