<ng-container *transloco="let t; read: 'organizations.organization.providers.accounts'">
  <div>
    <div [style.backgroundColor]="provider.color.base + '30'" class="provider {{ provider.type }}">
      <div [matRippleColor]="provider.color.base + '8a'" class="provider-container" matRipple>
        <div class="logo-wrapper">
          <div
            *ngIf="!provider.isError && !provider.isUnavailable"
            [hidden]="!counter[provider.type]"
            [style.backgroundColor]="provider.color.base"
            [style.color]="provider.color.contrast"
            class="badge">
            {{ counter[provider.type] }}
          </div>

          <div *ngIf="provider.isError" class="badge badge-error">
            <mat-icon
              fontSet="material-icons-outlined"
              class="badge-error-icon"
              [matTooltip]="t('error')"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'explanation-tooltip'"
              >warning
            </mat-icon>
          </div>

          <div *ngIf="provider.color.beta" class="beta-badge">
            <mat-icon
              fontSet="material-icons-outlined"
              [matTooltip]="t('beta')"
              [matTooltipPosition]="'left'"
              [matTooltipClass]="'explanation-tooltip'"
              [svgIcon]="'beta-badge-icon'"
              >beta
            </mat-icon>
          </div>

          <div *ngIf="provider.isUnavailable" class="badge badge-error">
            <mat-icon
              fontSet="material-icons-outlined"
              class="badge-error-icon"
              [matTooltip]="t('unavailable')"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'explanation-tooltip'"
              >warning
            </mat-icon>
          </div>

          <app-picture [encapsulate]="'height'" [srcset]="provider.logoPath"></app-picture>
        </div>

        <!-- Provider title, prevent black text
        <strong *ngIf="provider.color.base !== '#111111'" class="provider-name" [style.color]="provider.color.text || provider.color.base">{{ provider.title }}</strong>
        <strong *ngIf="provider.color.base == '#111111'" class="provider-name" [style.color]="'#696969'">{{ provider.title }}</strong>-->

        <strong class="provider-name">{{ provider.title }}</strong>
      </div>
    </div>
  </div>
</ng-container>
