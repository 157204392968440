import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {
  DisplayError,
  FetchPricingInfo,
  ResetError,
  SetIframe,
  StartLoading,
  StopLoading,
  StartProcessing,
  StopProcessing,
} from '@store/app/app.actions';
import {SubscriptionPlan} from '@pma/shared/types/organization';
import {PricingService} from '@core/services/pricing/pricing.service';

export interface AppStatePricingModel {
  plans: SubscriptionPlan[];
  addons: SubscriptionPlan[];
}

export interface AppStateModel {
  isLoading: boolean;
  isIframe: boolean;
  error: string;
  pricing?: AppStatePricingModel;
  isProcessing?: boolean;
}

const initialState: AppStateModel = {
  isLoading: false,
  isIframe: false,
  error: null,
  isProcessing: false,
};

@Injectable()
@State<AppStateModel>({
  name: 'app',
  defaults: initialState,
})
export class AppState {
  constructor(private pricingService: PricingService) {}

  @Action(StartLoading)
  async startLoading({patchState}: StateContext<AppStateModel>) {
    return patchState({isLoading: true});
  }

  @Action(StopLoading)
  async stopLoading({patchState}: StateContext<AppStateModel>) {
    return patchState({isLoading: false});
  }

  @Action(StartProcessing)
  async startProcessing({patchState}: StateContext<AppStateModel>) {
    return patchState({isProcessing: true});
  }

  @Action(StopProcessing)
  async stopProcessing({patchState}: StateContext<AppStateModel>) {
    return patchState({isProcessing: false});
  }

  @Action(SetIframe)
  async setIframe({patchState}: StateContext<AppStateModel>) {
    return patchState({isIframe: true});
  }

  @Action(DisplayError)
  async displayError({patchState}: StateContext<AppStateModel>, {message}: DisplayError) {
    return patchState({error: message});
  }

  @Action(ResetError)
  async resetError({patchState}: StateContext<AppStateModel>) {
    return patchState({error: null});
  }

  @Action(FetchPricingInfo)
  async fetchPricingInfo({getState, patchState, dispatch}: StateContext<AppStateModel>) {
    let {pricing} = getState();
    if (!pricing) {
      try {
        pricing = (await this.pricingService.getPricingInfo()) as AppStatePricingModel;
      } catch (e) {
        dispatch(new DisplayError(e));
      }

      return patchState({pricing});
    }
    return patchState({error: null});
  }
}
