import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Select, Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {ConnectorsStateModel} from '@store/connectors/connectors.state';
import {BaseComponent} from '@shared/components/base/base.component';
import {MatPaginator} from '@angular/material/paginator';
import {ConfirmDialogComponent} from '@shared/components/confirm-dialog/confirm-dialog.component';
import {catchError, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {RemoveAccountUsage} from '@store/providers/providers.actions';
import {ConnectorUsage} from '@pma/shared/types/organization';

@Component({
  selector: 'app-usage-table',
  templateUrl: './usage-table.component.html',
  styleUrls: ['./usage-table.component.scss'],
})
export class UsageTableComponent extends BaseComponent implements AfterViewInit {
  @Select(state => state.connectors) connectors$: Observable<ConnectorsStateModel>;

  displayedColumns: string[] = ['connector', 'account', 'usageName', 'lastUsed', 'actions'];
  dataSource: MatTableDataSource<ConnectorUsage>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @Input() organizationId: string;
  private usageValue: ConnectorUsage[] = [];

  usageSelected: any[] = [];

  constructor(
    private store: Store,
    private snackBar: MatSnackBar,
    private transloco: TranslocoService,
    private dialog: MatDialog
  ) {
    super();
  }

  get usage() {
    return this.usageValue;
  }

  @Input()
  set usage(usage: ConnectorUsage[]) {
    this.usageValue = usage;
    this.updateDataSource();
  }

  @Input() isBackfillAllowed = false;
  @Input() isSelectionAllowed = false;
  @Output() selectUsage: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit() {
    this.updateDataSource();
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.usage);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.getSortingDataAccessor();
  }

  async removeUsage(usage: ConnectorUsage) {
    const title = this.transloco.translate('organizations.organization.usage.table.actions.removeConfirm');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: {title}});

    const sub = dialogRef.componentInstance.action
      .pipe(
        filter(Boolean),
        tap(() => (dialogRef.componentInstance.isLoading = true)),
        switchMap(() =>
          this.store.dispatch(
            new RemoveAccountUsage(
              this.organizationId,
              usage.accountId,
              usage.connector,
              usage.usageKey,
              usage.usageName
            )
          )
        ),
        tap(() => this.notifyUsageRemoved(usage)),
        tap(() => dialogRef.close()),
        catchError(this.handleUsageRemovalError.bind(this)),
        tap(() => dialogRef.close()),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();

    dialogRef
      .afterClosed()
      .pipe(
        tap(() => sub.unsubscribe()),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  private getSortingDataAccessor() {
    return (data: ConnectorUsage, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'account':
          return data.account.displayName;
        default:
          return data[sortHeaderId];
      }
    };
  }

  private notifyUsageRemoved(usage: ConnectorUsage) {
    const translationPath = 'organizations.organization.usage.successfullyRemoved';
    const translation = this.transloco.translate(translationPath, usage);

    return this.snackBar.open(translation, null, {duration: 3000});
  }

  selectUsageItem(isChecked: boolean, accountId: string, type: string, usageKey: string, usageName: string) {
    if (isChecked) {
      this.usageSelected.push({accountId, type, usageKey, usageName});
    } else {
      this.usageSelected = this.usageSelected.filter(
        u => !(u.accountId === accountId && u.type === type && u.usageKey === usageKey)
      );
    }

    this.selectUsage.emit(this.usageSelected);
  }

  private handleUsageRemovalError(e: Error) {
    this.snackBar.open(e.message, null, {
      panelClass: 'color-background-warn',
      duration: 5000,
    });

    return of(e);
  }
}
