import {Injectable} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {UserPipe} from '@shared/pipes/auth/user.pipe';
import {Observable, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {UserDocument, UserModel} from '@pma/shared/types/user';
import {GoogleAuthProvider} from 'firebase/auth';
import {Auth, authState, signInWithPopup, signInWithRedirect, User} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private auth: Auth,
    private userPipe: UserPipe,
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {}

  // get uid(): string | undefined {
  //   return this.afAuth.currentUser;//getAuth().currentUser?.uid;
  // }

  /**
   * Get the current logged in authenticated user
   */
  get user$(): Observable<UserModel | null> {
    return authState(this.auth).pipe(
      switchMap((firebaseUser: User | null) => {
        return !firebaseUser
          ? of(null)
          : this.getDoc$(firebaseUser).pipe(
              filter((doc: any) => Boolean(doc)),
              map<UserDocument, UserModel>((doc: UserDocument) => this.mapToModel(doc, firebaseUser)),
              tap(user => this.trackUserAnalytics(user))
            );
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return authState(this.auth).pipe(map(firebaseUser => Boolean(firebaseUser)));
  }

  /**
   * Signs in the user via Google Provider
   */
  signInWithGoogle(isPopup: boolean): Promise<any> {
    console.log('signInWithGoogle');
    const googleAuth = new GoogleAuthProvider();

    if (isPopup) {
      return signInWithPopup(this.auth, googleAuth);
    }

    return signInWithRedirect(this.auth, googleAuth);
  }

  /**
   * Logs the user out
   */
  signOut(): Promise<void> {
    return this.auth.signOut();
  }

  /**
   * Get the document of the current active user
   */
  private getDoc$(firebaseUser: User): Observable<UserDocument | undefined> {
    return this.afs.doc<UserDocument>(`users/${firebaseUser.uid}`).valueChanges();
  }

  /**
   * Map `UserDocument` and `User` to `UserModel`
   */
  private mapToModel(doc: UserDocument, firebaseUser: User): UserModel {
    return this.userPipe.transform({firebaseUser, doc});
  }

  /**
   * Set UserId for analytics
   */
  private trackUserAnalytics(user: UserModel): Promise<void> {
    if (!this.analytics || !('setUserId' in this.analytics)) {
      return Promise.resolve(); // For IE11
    }
    return this.analytics.setUserId(user.uid);
  }
}
