import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() isLoginRoute: boolean;
  year = new Date().getFullYear();

  links: Array<{text: string; href: string}> = [
    {text: 'privacy', href: 'https://www.powermyanalytics.com/privacy/'},
    {text: 'terms', href: 'https://www.powermyanalytics.com/terms/'},
  ];

  constructor() {}
}
